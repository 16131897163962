import { createSlice } from '@reduxjs/toolkit';


/**
 * 
 */
export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    breadcrumb : [
      { title : 'home', active : true }
    ]
  },

  reducers:
  {
    setBreadcrumb : (state, action ) => { state.setBreadcrumb = action.payload },
  },
});

export const { setBreadcrumb  } = menuSlice.actions;

export const selectBreadcrumb = (state) => state.menu.breadcrumb;


export default menuSlice.reducer;