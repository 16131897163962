import { createSlice } from '@reduxjs/toolkit';
import { fetchGET, fetchDELETE } from "../toolbox/requestor.slice";



/**
 * 
 */
export const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    projects: {},
    filter: {},
    sorting: {},
    listStyle: {},
    active: {
      domain: null,
      projects: null,
      filter: {},
      sorting: {},
      listStyle: null
    },
  },

  reducers: {
    resetProjects: (state) => {
      state.projects = {}
      state.filter = {}
      state.sorting = {}
      state.active = {
        domain: null,
        projects: null,
        filter: {},
        sorting: {}
      }
    },
    setProjects: (state, action) => {
      if (typeof action.payload.domain !== 'undefined') {
        state.projects[action.payload.domain] = action.payload.projects;
        state.active.projects = action.payload.projects;
      }
    },
    setFilter: (state, action) => {
      if (typeof action.payload.domain !== 'undefined') {
        if (typeof action.payload.key !== 'undefined') {
          if (typeof state.filter[action.payload.domain] === 'undefined') {
            state.filter[action.payload.domain] = {};
          }
          state.filter[action.payload.domain][action.payload.key] = action.payload.value;
          
          //
          if (state.active.filter === null) {
            state.active.filter = {}
          }
          state.active.filter[action.payload.key] = action.payload.value;
                  
          // 
          if (typeof action.payload.quick !== 'undefined' && action.payload.quick) {
            state.active.projects = null;
          }
        }
      }
    },
    setSorting: (state, action) => {
      if (typeof action.payload.domain !== 'undefined') {
        if (typeof action.payload.key !== 'undefined') {
          if (typeof state.sorting[action.payload.domain] === 'undefined') {
            state.sorting[action.payload.domain] = { key: 'title', direction: 'ASC' };
          }
          if (state.sorting[action.payload.domain].key !== action.payload.key) {
            state.sorting[action.payload.domain].direction = 'ASC'
          } else {
            state.sorting[action.payload.domain].direction = state.sorting[action.payload.domain].direction === 'ASC' ? 'DESC' : 'ASC';
          }
          state.sorting[action.payload.domain].key = action.payload.key;
          state.active.sorting = {
            key: action.payload.key,
            direction: state.sorting[action.payload.domain].direction
          }

          state.active.projects = null;
        }
      }
    },
    setListStyle: (state, action) => {
      if (typeof action.payload.domain !== 'undefined') {
        if (typeof action.payload.style !== 'undefined') {
          state.listStyle[action.payload.domain] = action.payload.style;
          state.active.listStyle = state.listStyle[action.payload.domain];
        }
      }
    },
    setActive: (state, action) => {
      if (typeof action.payload !== 'undefined') {
        const domain = action.payload;
        state.active.domain = domain;
        state.active.projects = null;
        state.active.filter = null;
        state.active.sorting = null;
        if (state.projects && typeof state.projects[domain.id] !== 'undefined') {
          state.active.projects = state.projects[domain.id];
        }
        if (state.filter && typeof state.filter[domain.id] !== 'undefined') {
          state.active.filter = state.fiter[domain.id];
        }
        if (state.sorting && typeof state.sorting[domain.id] !== 'undefined') {
          state.active.sorting = state.sorting[domain.id];
        }
      }
    }
  },
});

export const { setListStyle, setSorting, setFilter, setProjects, setActive, resetProjects } = projectsSlice.actions;

export const getActiveDomain = (state) => state.projects.active;

export default projectsSlice.reducer;



/**
 * 
 * @param {string} domain
 * @param {integer} id 
 * @param {Boolean} archivePrivate
 */
export function archiveProject(domain, id, archivePrivate) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      const request = archivePrivate ? backendUrl + '/api/latest/'+domain+'/project/' + id+'/parchive' : backendUrl + '/api/latest/'+domain+'/project/' + id+'/archive'

      dispatch(fetchGET(request)).then(
        () => dispatch(setProjects({domain: domain, projects: null}))
      );
    } catch (error) {
      console.error(error);
    }
  }
}



/**
 * 
 * @param {string} domain
 * @param {integer} id 
 */
export function reactivateProject(domain, id) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      dispatch(fetchGET(backendUrl + '/api/latest/'+domain+'/project/' + id + '/activate')).then(
        () => dispatch(setProjects({domain: domain, projects: null}))
      );
    } catch (error) {
      console.error(error);
    }
  }
}



/**
 * 
 * @param {string} domain
 * @param {integer} id 
 */
 export function deleteProject(domain, id) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      

      dispatch(fetchDELETE(backendUrl + '/api/latest/'+domain+'/project/' + id)).then(
        response => {
          //dispatch(saveProject(load(All)Projects));
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}