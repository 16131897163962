import { createSlice } from '@reduxjs/toolkit';
import { fetchPUT } from "../toolbox/requestor.slice";
import { changeData } from './corrector.slice';
import { addMessage } from '../snackbar/snackbar.slice'



/**
 * 
 */
export const correctionDateSlice = createSlice({
  name: 'correctionDate',
  initialState: {
    date: null,
    visible: false
  },

  reducers: {
    changeCorrectionDate: (state, action) => {
      switch (action.payload.key) {
        case 'date': state.date = action.payload.value; break;
        case 'visible': state.visible = action.payload.value; break;
        default: break;
      }
    },
  },
});

export const { changeCorrectionDate } = correctionDateSlice.actions;

export const isVisible = (state) => state.correctionDate.visible;
export const date = (state) => state.correctionDate.date;

export default correctionDateSlice.reducer;



/**
 * 
 * @returns 
 */
 export function persistCorrectionLevelDeadline(domain, msg) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      
      // get CorrctionLevel data and push it into store
      dispatch(fetchPUT(backendUrl + '/api/latest/' + domain + '/correctionlevel/' + state.corrector.data.correctionlevel.id + '/deadline', state.correctionDate.date)).then(
        correctionlevel => {
          dispatch(changeData({ key: 'correctionlevel', value: correctionlevel}));
          dispatch(changeCorrectionDate({ key: 'visible', value: false}));
          dispatch(addMessage({type: 'NOTICE', text: msg}));
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}