// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Input, Label, Grid, Checkbox, Icon, Image, Flag } from 'semantic-ui-react';

// Redux
import { selectUsername, selectPassword, selectRPassword, selectEmail, selectUsernameCheck, selectPasswordCheck, selectEmailCheck, selectLanguage, setLanguage } from './user.slice';
import { setUsername, setEmail, setPassword, setRPassword, validateUsername, validatePassword, validateEmail, isActive } from './user.slice';
import { selectUser } from '../login/login.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// Styles
import './user.css';





/**
 * @param {Boolean} noReadOnly
 */
const WECUser_Basics = ({noReadOnly}) => {
    // global state
    const dispatch  = useDispatch();

    // translation
    const { t } = useTranslation('user', 'common');
    
    // User
    const username          = useSelector(selectUsername);
    const password          = useSelector(selectPassword);
    const rpassword         = useSelector(selectRPassword);
    const email             = useSelector(selectEmail);
    const usernameCheck     = useSelector(selectUsernameCheck);
    const passwordCheck     = useSelector(selectPasswordCheck);
    const emailCheck        = useSelector(selectEmailCheck);
    const active            = useSelector(isActive);
    const language          = useSelector(selectLanguage);

    // Login
    const userLoggedIn      = useSelector(selectUser);

    // Permissions
    let permissionHandler = new WECPermissionHandler();
    const permission_PERM_SYS_03 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_03') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
    
    // Error check Username
    let error_username = '';
    let error_username_label = <></>;
    if (!usernameCheck.available) {
        error_username = 'error';
        error_username_label = <Label basic color='red' pointing>{t('user:BASICS.MESSAGES.Username not available')}</Label>
    }
    if (!usernameCheck.length) {
        error_username = 'error';
        error_username_label = <Label basic color='red' pointing>{t('user:BASICS.MESSAGES.Username must consinst of at least three characters')}</Label>
    }
    if (!usernameCheck.character) {
        error_username = 'error';
        error_username_label = <Label basic color='red' pointing>{t('user:BASICS.MESSAGES.Username can only consists of letters (en), numbers and underscore')}</Label>
    }
    if (!username) {
        error_username = 'error';
        error_username_label = <Label basic color='red' pointing>{t('user:BASICS.MESSAGES.Username is empty')}</Label>
    }

    // Error check email
    const error_email = emailCheck ? '' : 'error';
    let error_email_label = <></>;
    error_email_label = !email.length ? <Label basic color='red' pointing>{t('user:BASICS.MESSAGES.Email is empty')}</Label> : emailCheck ? <></> : <Label basic color='red' pointing>{t('user:BASICS.MESSAGES.Invalid email address')}</Label>;
    
    // Error check password
    let key_color;
    switch (passwordCheck.strength) {
        case 4: key_color = 'green'; break;
        case 3: key_color = 'olive'; break;
        case 2: key_color = 'yellow'; break;
        case 1: key_color = 'orange'; break;
        default: key_color = 'red';
    }
    if (typeof password !== 'undefined' && password && !password.length) {
        key_color = 'red';
    }
    let error_pw    = passwordCheck.length && passwordCheck.identically ? '' : 'error';
    let error_pw_label  = <></>;
    if (!passwordCheck.length)      error_pw_label = (<Label basic color='red' pointing>{t('user:BASICS.MESSAGES.Password must consist of at least 8 characters')}</Label>);
    if (!passwordCheck.identically) error_pw_label = (<Label basic color='red' pointing>{t('user:BASICS.MESSAGES.Passwords do not match')}</Label>);
    /*if (!passwordCheck.special)     error_pw_label = (<Label basic color='red' pointing>{t('user:BASICS.MESSAGES.Password must contain a special character')}</Label>);
    if (!passwordCheck.case)        error_pw_label = (<Label basic color='red' pointing>{t('user:BASICS.MESSAGES.Password must contain uppercase and lowercase characters')}</Label>);
    if (!passwordCheck.numbers)     error_pw_label = (<Label basic color='red' pointing>{t('user:BASICS.MESSAGES.Password must contain a number')}</Label>);*/

    // language
    let lang_flag;
    let lang_flag_title;
    let lang_opacity = {de: 0.3, en: 0.3};
    switch (language) {
        case 'de':
            lang_flag = 'de';
            lang_flag_title = 'Deutsch';
            lang_opacity.de = 1;
            break;
        case 'en':
            lang_flag = 'uk'
            lang_flag_title = 'Englisch';
            lang_opacity.en = 1;
            break;
        default:
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={4}><label>{t('user:BASICS.LABELS.Username')}</label></Grid.Column>
                    <Grid.Column width={12}>
                        {(typeof noReadOnly === 'undefined' || !noReadOnly || !permission_PERM_SYS_03) && (<Input className = {error_username} size="mini" fluid icon='user' iconPosition='left' value={username} readonly />)}
                        {(typeof noReadOnly !== 'undefined' && noReadOnly) && (<Input className = {error_username} size="mini" fluid icon='user' iconPosition='left' value={username} placeholder={t('user:BASICS.LABELS.Username')} onChange={(event)=>{
                            dispatch(setUsername({ username : event.target.value }))
                            dispatch(validateUsername(event.target.value));
                        }} />)}
                        {error_username_label}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={4}><label>{t('user:BASICS.LABELS.E-Mail')}</label></Grid.Column>
                    <Grid.Column width={12}>
                        {permission_PERM_SYS_03 && (
                            <Input 
                                fluid 
                                size = "mini"
                                icon = 'at'
                                iconPosition = 'left'
                                placeholder = {t('user:BASICS.LABELS.E-Mail')}
                                className = {error_email}
                                value = {email}
                                onChange = {(event)=>dispatch(setEmail({ email : event.target.value }))}
                                onBlur = {(event)=>dispatch(validateEmail({email : event.target.value }))}
                            />
                        )}
                        {!permission_PERM_SYS_03 && (
                            <Input 
                                fluid 
                                size = "mini"
                                icon = 'at'
                                iconPosition = 'left'
                                placeholder = {t('user:BASICS.LABELS.E-Mail')}
                                className = {error_email}
                                value = {email}
                                readonly
                            />
                        )}
                        {error_email_label}
                    </Grid.Column>
                </Grid.Row>
                {permission_PERM_SYS_03 && (
                    <>
                    <Grid.Row style={{padding: '3px'}}>
                        <Grid.Column width={4}><label>{t('user:BASICS.LABELS.Password')}</label></Grid.Column>
                        <Grid.Column width={12}>
                            <Input
                                fluid 
                                type = 'password' 
                                size = 'mini'
                                icon = {<Icon name='key' color={key_color} />} 
                                iconPosition = 'left' 
                                placeholder = {t('user:BASICS.LABELS.Password')}
                                className = {error_pw}
                                value = {password} 
                                onChange = {(event) => dispatch(setPassword({ password : event.target.value }))} 
                                onBlur = {(event) => dispatch(validatePassword({ password : event.target.value, rpassword : rpassword }))}
                            />
                            {error_pw_label}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{padding: '3px'}}>
                        <Grid.Column width={4}><label>{t('user:BASICS.LABELS.Password')}</label></Grid.Column>
                        <Grid.Column width={12}>
                            <Input 
                                fluid 
                                type = 'password'
                                size = "mini"
                                icon = 'key'
                                iconPosition = 'left'
                                placeholder = {t('user:BASICS.LABELS.Repeat Password')}
                                className = {error_pw}
                                value = {rpassword}
                                onChange ={(event)=>dispatch(setRPassword({ rpassword : event.target.value }))} 
                                onBlur = {(event) => dispatch(validatePassword({ rpassword : event.target.value, password : password }))}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    </>
                )}
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={4}><label>{t('user:BASICS.LABELS.Active')}</label></Grid.Column>
                    <Grid.Column width={12}>
                        {permission_PERM_SYS_03 && (
                            <Checkbox toggle floated="left" checked={active} />
                        )}
                        {!permission_PERM_SYS_03 && active && (
                            <Image src='media/icons/WE_correct_icon_bestaetigen.svg' size='mini' />
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={4}><label>{t('user:BASICS.LABELS.Language')}</label></Grid.Column>
                    <Grid.Column width={12}>
                        {permission_PERM_SYS_03 && (
                            <>
                            <Image as='a' src='media/icons/flag_de.svg' title='Deutsch' onClick ={()=>dispatch(setLanguage({ language : 'de' }))} style={{cursor: 'pointer', border: '2px solid rgba(34,36,38,.15)', opacity: lang_opacity.de, marginRight: '20px'}} />
                            <Image as='a' src='media/icons/flag_uk.svg' title='Englisch' onClick ={()=>dispatch(setLanguage({ language : 'en' }))} style={{cursor: 'pointer', border: '2px solid rgba(34,36,38,.15)', opacity: lang_opacity.en, marginRight: '20px'}} />
                            </>
                        )}
                        {!permission_PERM_SYS_03 && active && (
                            <Image  src={'media/icons/flag_'+lang_flag+'.svg'} title={lang_flag_title} />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
}

export default WECUser_Basics;