// react base
import React from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { differenceInDays, differenceInHours, format } from 'date-fns/esm'

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { selectBaseProtocol, selectBackendUrl, setActiveModule, selectClStageTypes } from '../../../WECorrect.slice';
import { loadProject } from '../corrector.slice';

// semantic ui
import { Label, Card, Image, Popup } from 'semantic-ui-react'

// Styles
import '../corrector.css';



/**
 * 
 * @param {Project} project
 * @param {boolean} archive
 * @returns 
 */
const WECProjectCard = ({project, archive}) => {
  // Redux
  const dispatch = useDispatch();
  const c_selectBaseProtocol = useSelector(selectBaseProtocol);
  const s_selectBackendUrl = useSelector(selectBackendUrl);
  const stage_types = useSelector(selectClStageTypes);

  // translation
  const { t } = useTranslation('corrector');
  
  //
  let imgURL   = '/media/icons/WE_correct_icon_schliessen.svg';
  let imgStyle = {backgroundColor: '#909090', opacity: '0.2'};
  let noImage  = 'no_image';
  if (typeof project.correctionLevel !== 'undefined' && typeof project.correctionLevel.id !== 'undefined') {
    imgURL = c_selectBaseProtocol + '//' + s_selectBackendUrl + '/media/projects/' + project.domain + '/' + project.id + '/' + project.correctionLevel.id + '/thumbsL/1.jpg';
    imgStyle = {};
    noImage  = '';
  }

  // stage
  const stage_txt = project.archived ? 'archiviert' : project.correctionLevel && project.correctionLevel.stage ? stage_types[project.correctionLevel.stage].title : stage_types[1].title;

  // format date
  const fdateCreated  = typeof project.log !== 'undefined' && typeof project.log.CREATE !== 'undefined' && project.log.CREATE ? format(new Date(project.log.CREATE), "dd.MM.yyyy, HH:mm") : <></>
  const fdateChanged  = typeof project.log !== 'undefined' && typeof project.log.UPDATE !== 'undefined' && project.log.UPDATE ? format(new Date(project.log.UPDATE), "dd.MM.yyyy, HH:mm") : <></>
  const fdateArchived = typeof project.log !== 'undefined' && typeof project.log.ARCHIVED !== 'undefined' && project.log.ARCHIVED ? format(new Date(project.log.ARCHIVED), "dd.MM.yyyy, HH:mm") : <></>

  // tags list
  const tagsList = typeof project.tags === 'undefined' ? <></> : project.tags.map((tag, ii) => {
    return (
      <Label key={ii} className="hashtag" size='mini' icon='hashtag' content={tag.name} />
    );
  })

  // deadline
  let diffWarnIcon = null;
  let diffWarnTxt  = '';
  let diffWarnCol  = '#000'
  //let dateCLDf = null;
  if (typeof project.correctionLevel !== 'undefined' && typeof project.correctionLevel.deadline !== 'undefined' && project.correctionLevel.deadline && typeof project.correctionLevel.deadline.date !== 'undefined') {
    const dateCLD    = new Date(project.correctionLevel.deadline.date);
    const dateNow    = new Date();
    const diffDays   = differenceInDays(new Date(dateCLD), new Date(dateNow))
    const diffHours  = differenceInHours(new Date(dateCLD), new Date(dateNow))
    const diffTxt2   = t('corrector:CARD.WARNING1');
    const diffTxt1   = t('corrector:CARD.WARNING2');
    
    //dateCLDf   = format(dateCLD, "dd.MM.yyyy HH:mm");

    if (diffDays <= 7) {
      diffWarnTxt = diffTxt1.replace('[X]', diffDays);
      diffWarnIcon = '/media/icons/WE_correct_icon_achtung.svg';
      diffWarnCol  = '#FFCC0F';
    }
    if (diffDays <= 3) {
      diffWarnTxt = diffHours <= 36 ? diffTxt2.replace('[X]', diffHours) : diffWarnTxt = diffTxt1.replace('[X]', diffDays);
      diffWarnIcon = '/media/icons/WE_correct_icon_achtung-orange.svg';
      diffWarnCol  = '#FF7F15';
    }
    if (diffDays <= 1) {
      diffWarnTxt = diffTxt2.replace('[X]', diffHours);
      diffWarnIcon = '/media/icons/WE_correct_icon_achtung-rot.svg';
      diffWarnCol  = '#FF3333';
    }
  }
  const deadlinePopupStyle = {
    borderRadius: 5,
    opacity: 0.95,
    padding: '1em',
    color: diffWarnCol,
    minWidth: '300px'
  }

  return (
    <Card
      onClick = {()=> {
        dispatch(loadProject(project.domain, project.id, archive));
        dispatch(setActiveModule('corrector'));
      }}
    >
      <Image className={noImage} src={imgURL} wrapped ui={false} style={imgStyle} />
      <Card.Content>
        <Card.Header>{project.title}</Card.Header>
        <Card.Meta>
          <table>
            <tr>
              <td>{t('corrector:CARD.Phase')}</td>
              <td><span className='date'>{stage_txt}</span></td>
              <td rowSpan='3'>
                {diffWarnIcon && (
                  <Popup
                    trigger ={<Image size='tiny' src={diffWarnIcon} style={{width: '40px', height: '40px'}} />}
                    content = {diffWarnTxt}
                    style = {deadlinePopupStyle}
                    inverted
                  />
                )}
              </td>
              <td rowSpan='3'>
                <div className='stufe'>
                  <span className='stufe_head'>{t('corrector:CARD.Stufe')}</span>
                  <span className='stufe_value'>{project.correctionLevels.length}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>{t('corrector:CARD.Erstellt')}</td>
              <td><span className='date'>{fdateCreated}</span></td>
            </tr>
            {!archive && (
              <tr>
                <td>{t('corrector:CARD.Geändert')}</td>
                <td><span className='date'>{fdateChanged}</span></td>
              </tr>)}
            {archive && (
              <tr>
                <td>{t('corrector:CARD.Archiviert')}</td>
                <td><span className='date'>{fdateArchived}</span></td>
              </tr>
            )}
          </table>
        </Card.Meta>
        
      </Card.Content>
      <Card.Content extra>
        <div class='tags'>
          {tagsList}
        </div>
      </Card.Content>
    </Card>
  )
}

export default WECProjectCard;