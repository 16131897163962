// react base
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { selectBaseProtocol, selectBackendUrl, selectConfig } from '../../WECorrect.slice';
import { selectToken } from '../login/login.slice';
import { asyncFetchGET } from "../toolbox/requestor.slice";
import { addUserToProject } from "../user/user.slice";
import { selectUser } from '../login/login.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// App
import WECProjectRow from "../corrector/project/row";

// semantic ui
import { Container, Header, Table, Button, Form, Dropdown } from 'semantic-ui-react';

// Styles
import '../corrector/corrector.css';
import './user.css';



/**
 * 
 * @param {Integer} userId 
 * @returns 
 */
const WECUser_Projects = ({userId}) => {
    // local state
    const [projects, setProjects] = useState(null);
    const [mode, setMode] = useState('LIST');
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);

    // global state
    const dispatch  = useDispatch();
    const config = useSelector(selectConfig);
    const baseProtocol = useSelector(selectBaseProtocol);
    const backendUrl = useSelector(selectBackendUrl);
    const token = useSelector(selectToken);
    const sys_roles = Object.assign([], config[config.domain].user.roles);

    // Login
    const userLoggedIn  = useSelector(selectUser);

    // translations
    const { t } = useTranslation('user', 'common');
    
    // load projects
    useEffect(() => {
        if (projects === null) {
            let options = {
                domain: config.domain, 
                filter: { active: true },
                sorting: { key: 'title', direction: 'ASC' }
            }
            if (mode === 'LIST') {
                options.filter.user_id = userId;
            }
            const url = baseProtocol + '//' + backendUrl + '/api/latest/'+config.domain.toLowerCase()+'/projects/?options=' + btoa( unescape(encodeURIComponent(JSON.stringify(options)))).replace(/=/g, '_');
            asyncFetchGET(url, token).then(
                (response) => {
                    setProjects(response)
                }
            );
        }
    });

    // Permissions
    let permissionHandler = new WECPermissionHandler();
    const permission_PERM_SYS_03 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_03') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
    
    // build projects rows
    const WECProjectRows = !projects || projects.error ? <></> : projects.map((project, i) => {
        switch (mode) {
            case 'LIST':
                return (<WECProjectRow key={i} project={project} archive={false} small={true} />)
            case 'ADD':
                const checked = selectedRow === project.id ? true : false
                let addProject = true;
                if (typeof project.attendees !== 'undefined') {
                    project.attendees.forEach(attendee => {
                        if (attendee.userid === userId) {
                            addProject = false;
                            return <></>;
                        }
                    });
                }
                if (addProject) {
                    return (<WECProjectRow key={i} project={project} archive={false} small={true} checked={checked} onRowClick={()=>{
                        setSelectedRow(project.id);
                    }}/>)
                }
                break;
            default:
                return <></>
        }
        return <></>
    });

    //
    switch (mode) {
        case 'LIST':
            return (
                <Container className='wec_corrector_projects'>
                    <Header as='h4'>{t('user:PROJECTS.LABELS.Eigene Projekte')}</Header>
                    <Table fixed>
                        <Table.Body>
                        {WECProjectRows}
                        </Table.Body>
                    </Table>
                    {permission_PERM_SYS_03 && (
                        <Button onClick={() => { setProjects(null); setSelectedRow(null); setSelectedRole(null); setMode('ADD'); }}>{t('user:PROJECTS.BUTTONS.zu Projekt hinzufügen')}</Button>
                    )}
                </Container>
            )
        case 'ADD':
            return (
                <Container className='wec_corrector_projects'>
                    <Header as='h4'>{t('user:PROJECTS.LABELS.Benutzer zu vorhandenen Projekte hinzufügen')}</Header>
                    <Table fixed selectedRow={selectedRow}>
                        <Table.Body>
                        {WECProjectRows}
                        </Table.Body>
                    </Table>
                        <Form>
                            <Form.Group>
                                <Form.Field inline>
                                    <Button size='mini' onClick={() => { setProjects(null); setSelectedRow(null); setSelectedRole(null); setMode('LIST'); }}>{t('user:PROJECTS.BUTTONS.abbrechen')}</Button>
                                </Form.Field>
                                {selectedRow && (
                                    <React.Fragment>
                                        <Form.Field inline>
                                            <label>Als</label>
                                            <Dropdown 
                                                selection
                                                search
                                                upward
                                                className = 'mini' 
                                                placeholder = {t('user:PROJECTS.LABELS.Rolle wählen')}
                                                options = {sys_roles} 
                                                value = {selectedRole}
                                                onChange = {(e, {value}) => setSelectedRole(value)} />
                                        </Form.Field>
                                        <Form.Field inline>
                                            <Button 
                                                size = 'mini' 
                                                onClick = {() => {
                                                    dispatch(addUserToProject(config.domain, selectedRow, userId, selectedRole, ()=>{
                                                        setSelectedRow(null);
                                                        setSelectedRole(null);
                                                        setMode('LIST');
                                                        setProjects(null);
                                                    }))
                                                }}
                                            >{t('user:PROJECTS.BUTTONS.hinzufügen')}</Button>
                                        </Form.Field>
                                    </React.Fragment>
                                )}
                            </Form.Group>
                        </Form>
                </Container>
            )
        default:
    }
    
    return <></>
}

export default WECUser_Projects;