// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { isVisible, getPosition, getCorrection } from './contextMenu.slice';
import { showCorrectionDialog } from '../editor/dialogCorrection.slice';
import { deleteCorrection } from '../editor/editor.slice';
import { showConfirm } from '../../confirm/confirm.slice';

// Semantic-UI
import { Container, Icon, Item } from 'semantic-ui-react';

// Styles
import './contextMenu.css';






/**
 *
 */
const WECContextMenu = () => {
  // global store
  const dispatch = useDispatch();
  const visible = useSelector(isVisible);
  const position = useSelector(getPosition);
  const correction = useSelector(getCorrection);

  // translation
  const { t } = useTranslation('corrector');

  // 
  if (!visible) {
    return <></>
  }

  return (
    <Container
      className = "menu ContextMenu"
      style = {{
        position: 'absolute',
        top: (position.y - 10),
        left: position.x,
      }}
    >
      <Item.Group>
        <Item as='a' onClick={()=>dispatch(showCorrectionDialog({domain: correction.domain, correction: correction}))}>
          <Item.Content verticalAlign='middle'>
            <Item.Header>
              <Icon name='edit' />
              {t('corrector:EDITOR_CONTEXTMENU.Bearbeiten')}
            </Item.Header>
          </Item.Content>
        </Item>
        <Item as='a' onClick={()=>{
          dispatch(showConfirm({
            cancelButton: t('corrector:EDITOR_CONTEXTMENU.Abbrechen'),
            confirmButton: t('corrector:EDITOR_CONTEXTMENU.Korrektur löschen'),
            message: t('corrector:EDITOR_CONTEXTMENU.Korrektur wirklich löschen'),
            onConfirm: ()=>dispatch(deleteCorrection(correction))
          }))
        }}>
          <Item.Content verticalAlign='middle'>
            <Item.Header>
              <Icon name='trash' />
              {t('corrector:EDITOR_CONTEXTMENU.Löschen')}
            </Item.Header>
          </Item.Content>
        </Item>
      </Item.Group>
    </Container>
  );
}

export default WECContextMenu;