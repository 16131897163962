import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';



export const requestorSlice = createSlice({
    name: 'requestor',
    initialState: {
    },
    reducers: {
    },
});

//export const {  } = requestorSlice.actions;
export default requestorSlice.reducer;




/**
 * 
 * @param {String} url 
 * @returns {JSON}
 */
export function fileUpload(uploadUrl, file, token, progressCallbackFunc) {
    const formData = new FormData()
    formData.append('file', file);

    const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': token
    };
    console.log('[headers]', headers)

    return new Promise((resolve, reject) => {
        axios.post(uploadUrl, formData, {
            headers: headers,
            onUploadProgress: ProgressEvent => {
                progressCallbackFunc({loaded: (ProgressEvent.loaded / ProgressEvent.total*100)});
            }
        }).then(response => {
        if (response.status === 200) {
            resolve(response.data.filename);
        } else {
            reject(response.statusText);
        }
        });
    });
}




/**
 * 
 * @param {String} url 
 * @returns {JSON}
 */
export function fetchGET(url) {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.login.token;

        // request params
        let request = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        };

        // add token if present
        if (typeof token !== 'undefined' && token) {
            request.headers.Authorization = token;
        }

        // do request
        const response = await fetch(url, request);
        
        // wait for response
        const json = await response.json();

        return json;
    }
}



/**
 * 
 * @param {String} url 
 * @returns {JSON}
 */
 export function fetchPDF(url) {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.login.token;

        // request params
        let request = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/pdf'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        };

        // add token if present
        if (typeof token !== 'undefined' && token) {
            request.headers.Authorization = token;
        }

        // do request
        const response = await fetch(url, request);
        
        // wait for response
        const blob = await response.blob();

        return blob;
    }
}



/**
 * 
 * @param {String} url 
 * @returns {JSON}
 */
 export async function asyncFetchGET(url, token) {
    // request params
    let request = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };

    // add token if present
    if (typeof token !== 'undefined' && token) {
        request.headers.Authorization = token;
    }

    // do request
    const response = await fetch(url, request);
    
    // wait for response
    const json = await response.json();

    return json;
}



/**
 * 
 * @param {String} url
 * @param {Object} data
 * @param {String} mode
 * @returns {JSON}
 */
export function fetchPOST(url = '', data = {}, mode = 'cors') {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.login.token;
        
        // request params
        let request = {
            method: 'POST',
            mode: mode,
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        };

        // add token if present
        if (typeof token !== 'undefined' && token) {
            request.headers.Authorization = token;
        }

        
        try {
            // do request    
            const response = await fetch(url, request);

            // request error handling
            if (!response.ok) {
                const message = `An error has occured: ${response.statusText} (${response.status})`;
                throw new Error(message);
            }

            // get response value
            const json = await response.json();
            
            return json;
        } catch (error) {    
            throw new Error(error);
        }
    }
}




/**
 * 
 * @param {String} url 
 * @param {Object} data 
 * @returns {JSON}
 */
export function fetchPUT(url = '', data = {}) {  
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.login.token;

        // request params
        let request = {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        };

        // add token if present
        if (typeof token !== 'undefined' && token) {
            request.headers.Authorization = token;
        }

        // do request
        const response = await fetch(url, request);
        
        // wait for response
        const json = await response.json();

        return json;
    }
}



/**
 * 
 * @param {String} url 
 * @returns {JSON}
 */
export function fetchDELETE(url = '') {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.login.token;

        // request params
        let request = {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        };

        // add token if present
        if (typeof token !== 'undefined' && token) {
            request.headers.Authorization = token;
        }

        // do request
        const response = await fetch(url, request);
        
        // wait for response
        const json = await response.json();

        return json;
    }
 }