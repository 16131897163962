import { createSlice } from '@reduxjs/toolkit';
import { addMessage } from '../snackbar/snackbar.slice'
import { fetchPOST, fetchGET } from '../toolbox/requestor.slice';




export const messageSlice = createSlice({
  name: 'message',
  initialState: {
    visibleSingle: false,
    visibleMultiple: false,
    content: null,
    user: null,
    users: [],
    reciepent: {
      type: null,
      ids: []
    }
  },

  reducers: {
    showMessageSingle: (state, action) => { 
      state.visibleSingle = true;
      state.user = action.payload;
    },
    showMessageMultiple: (state, action) => { 
      state.visibleMultiple = true;
      if (typeof action.payload.type !== 'undefined') {
        state.reciepent.type = action.payload.type;
      }
      if (typeof action.payload.ids !== 'undefined') {
        state.reciepent.ids = action.payload.ids;
      }
    },
    hideMessageSingle: (state) => { 
      state.visibleSingle = false;
      state.content = null;
      state.user = null;
    },
    hideMessageMultiple: (state) => { 
      state.visibleMultiple = false;
      state.content = null;
      state.reciepent = { type: null, ids: [] };
    },
    setMessageContent: (state, action) => { state.content = action.payload; }
  }
});
export const { showMessageSingle, hideMessageSingle, showMessageMultiple, hideMessageMultiple, setMessageContent } = messageSlice.actions;
export const isVisibleSingle = (state) => state.message.visibleSingle;
export const isVisibleMultiple = (state) => state.message.visibleMultiple;
export const messageUser = (state) => state.message.user;
export const messageReciepent = (state) => state.message.reciepent;
export const messageContent = (state) => state.message.content;

export default messageSlice.reducer;




/**
 * 
 * @param {Integer} messageId
 * @param {Function} callback
 * @returns 
 */
 export function readMessage(messageId, callback) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const authUrl = state.wec.baseProtocol + '//' + state.wec.authUrl;

      dispatch(fetchGET(authUrl + '/api/message/' + messageId + '/read')).then(
        message => callback(message)
      ).catch((error)=>{
        console.error(error);
        dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Setzen des Mitteilungsstatus'}));
      });
    } catch (error) {
      dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Setzen des Mitteilungsstatus'}));
    }
  }
}



/**
 * 
 * @param {Integer} messageId
 * @returns 
 */
 export function unreadMessage(messageId) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const authUrl = state.wec.baseProtocol + '//' + state.wec.authUrl;

      dispatch(fetchGET(authUrl + '/api/message/' + messageId + '/unread')).then(
        response => {
          if (typeof response.status !== 'undefined' && response.status === 'OK') {
            dispatch(addMessage({type: 'NOTICE', text: 'Mitteilung als gelesen markiert'}));
          } else {
            console.error(response);
            dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Setzen des Mitteilungsstatus'}));
          }
        }
      ).catch((error)=>{
        console.error(error);
        dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Setzen des Mitteilungsstatus'}));
      });
    } catch (error) {
      dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Setzen des Mitteilungsstatus'}));
    }
  }
}





/**
 * 
 * @param {Integer} userId
 * @param {String} message 
 * @returns 
 */
export function sendSingleMessage(userId, message) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const authUrl = state.wec.baseProtocol + '//' + state.wec.authUrl;

      dispatch(fetchPOST(authUrl + '/api/message/user/' + userId, message)).then(
        response => {
          if (typeof response.status !== 'undefined' && response.status === 'OK') {
            dispatch(addMessage({type: 'NOTICE', text: 'Mitteilung verschickt'}));
            dispatch(hideMessageSingle());
          } else {
            console.error(response);
            dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Versenden der Mitteilung'}));
          }
        }
      ).catch((error)=>{
        console.error(error);
        dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Versenden der Mitteilung'}));
      });
    } catch (error) {
      dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Versenden der Mitteilung'}));
    }
  }
}



/**
 * 
 * @param {Array} userIds
 * @param {String} message 
 * @returns 
 */
 export function sendMultipleMessage(userIds, message) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const authUrl = state.wec.baseProtocol + '//' + state.wec.authUrl;

      dispatch(fetchPOST(authUrl + '/api/message/users/' + userIds.join(','), message)).then(
        response => {
          if (typeof response.status !== 'undefined' && response.status === 'OK') {
            dispatch(addMessage({type: 'NOTICE', text: 'Mitteilung verschickt'}));
            dispatch(hideMessageMultiple());
          } else {
            console.error(response);
            dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Versenden der Mitteilung'}));
          }
        }
      ).catch((error)=>{
        console.error(error);
        dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Versenden der Mitteilung'}));
      });
    } catch (error) {
      dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Versenden der Mitteilung'}));
    }
  }
}