// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Redux
import { dataProject, selectProject } from './corrector.slice';
import { setActiveModule } from '../../WECorrect.slice';
import { resetProjects } from './projects.slice';

// semantic ui
import { Header, Grid, Segment, Container, Image } from 'semantic-ui-react'

// App
import WECProjectCorrectionLevel from "./project/correctionlevel";
import WECProjectCorrectionLevels from "./project/correctionlevels";
import WECProjectAttendees from "./project/attendees";

// Styles
import './corrector.css';
import './project/project.css';




/**
 * 
 */
const WECCorrectorDetails = () => {
  const dispatch  = useDispatch();
  const c_project = useSelector(dataProject);

  return (
    <Segment className='WECProject wec_corrector_details'>
      <Container className='WECProject wec_corrector_details_content'>
        <Header as='h2'>{c_project.title}</Header>
        <Image 
          src = '/media/icons/WE_correct_icon_schliessen.svg' 
          style = {{width: '32px', position: 'absolute', top: '60px', right: '70px', cursor: 'pointer'}}
          onClick = {()=>{
            var newurl = window.location.protocol + "//" + window.location.host;
            window.history.pushState({path:newurl}, '', newurl);
            dispatch(setActiveModule('dashboard'))
            dispatch(selectProject(null));
            dispatch(resetProjects());
          }}
        />
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column className="WECProjectCorrectionlevel" width={8}><WECProjectCorrectionLevel /></Grid.Column>
            <Grid.Column className="WECProjectCorrectionLevels" width={5}><WECProjectCorrectionLevels /></Grid.Column>
            <Grid.Column className="WECProjectAttendees" width={3}><WECProjectAttendees readOnly={true} large={true} /></Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
}

export default WECCorrectorDetails;