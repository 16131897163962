// react base
import React from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { differenceInDays, differenceInHours, format } from 'date-fns/esm'

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { selectBaseProtocol, selectBackendUrl, setActiveModule, selectClStageTypes } from '../../../WECorrect.slice';
import { archiveProject, reactivateProject } from '../projects.slice';
import { loadProject } from '../corrector.slice';
import { showConfirm } from '../../confirm/confirm.slice';

// semantic ui
import { Label, Button, Icon, Table, Item, Form, Checkbox, Popup, Image } from 'semantic-ui-react'

// Styles
import '../corrector.css';



/**
 * 
 * @param {Project} project
 * @param {boolean} archive
 * @param {boolean} small
 * @param {boolean} checked
 * @param {Function} onRowClick
 * @returns 
 */
const WECProjectRow = ({project, archive, small, checked, onRowClick}) => {
  // Redux
  const dispatch = useDispatch();
  const c_selectBaseProtocol = useSelector(selectBaseProtocol);
  const s_selectBackendUrl = useSelector(selectBackendUrl);
  const stage_types = useSelector(selectClStageTypes);

  // translation
  const { t } = useTranslation('corrector');
  
  //
  let imgURL = '/media/icons/WE_correct_icon_schliessen.svg';
  let imgStyle = {
    backgroundColor: '#909090', 
    opacity: '0.2', 
    backgroundImage: 'url('+imgURL+')', 
    backgroundPositionY: '-18px', 
    backgroundPositionX: '65px',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat'
  };
  if (typeof project.correctionLevel !== 'undefined' && typeof project.correctionLevel.id !== 'undefined') {
    imgURL = c_selectBaseProtocol + '//' + s_selectBackendUrl + '/media/projects/' + project.domain + '/' + project.id + '/' + project.correctionLevel.id + '/thumbsL/1.jpg';
    imgStyle = {backgroundImage: 'url('+imgURL+')'};
  }

  // tags list
  const tagsList = typeof project.tags === 'undefined' ? <></> : project.tags.map((tag, ii) => {
    return (
      <Label key={ii} className="hashtag" size='mini' icon='hashtag' content={tag.name} />
    );
  })

  // stage
  const stage_txt = project.archived ? 'archiviert' : project.correctionLevel && project.correctionLevel.stage ? stage_types[project.correctionLevel.stage].title : stage_types[1].title;

  //
  if (typeof small !== 'undefined' && small) {
    return (
      <Table.Row onClick={onRowClick}>
        <Table.Cell width={2} style={{backgroundImage: 'url('+imgURL+')'}}>&nbsp;</Table.Cell>
        <Table.Cell width={onRowClick ? 12 : 14}>
          <Item>
            <Item.Content>
              <Item.Header>{project.title}</Item.Header>
              <Item.Description>{stage_txt}</Item.Description>
              <Item.Extra><div class='tags'>{tagsList}</div></Item.Extra>
            </Item.Content>
          </Item>
        </Table.Cell>
        {onRowClick && (
          <Table.Cell width={2} textAlign='center'>
            {checked && (<Icon name='check' size='large' />)}
          </Table.Cell>
        )}
      </Table.Row>
    );
  }

  // format date
  const fdateCreated  = typeof project.log !== 'undefined' && typeof project.log.CREATE !== 'undefined' && project.log.CREATE ? format(new Date(project.log.CREATE), "dd.MM.yyyy, HH:mm") : <></>
  const fdateChanged  = typeof project.log !== 'undefined' && typeof project.log.UPDATE !== 'undefined' && project.log.UPDATE ? format(new Date(project.log.UPDATE), "dd.MM.yyyy, HH:mm") : <></>
  const fdateArchived = typeof project.log !== 'undefined' && typeof project.log.ARCHIVED !== 'undefined' && project.log.ARCHIVED ? format(new Date(project.log.ARCHIVED), "dd.MM.yyyy, HH:mm") : <></>
  const fdatePrint    = project.correctionLevel && project.correctionLevel.printApproval ? format(new Date(project.correctionLevel.printApproval.date), "dd.MM.yyyy, HH:mm") : null
  const fdateDeadline = project.correctionLevel && project.correctionLevel.deadline ? format(new Date(project.correctionLevel.deadline.date), "dd.MM.yyyy, HH:mm") : null

  // deadline
  let diffWarnIcon = null;
  let diffWarnTxt  = '';
  let diffWarnCol  = '#000'
  //let dateCLDf = null;
  if (typeof project.correctionLevel !== 'undefined' && typeof project.correctionLevel.deadline !== 'undefined' && project.correctionLevel.deadline && typeof project.correctionLevel.deadline.date !== 'undefined') {
    const dateCLD    = new Date(project.correctionLevel.deadline.date);
    const dateNow    = new Date();
    const diffDays   = differenceInDays(new Date(dateCLD), new Date(dateNow))
    const diffHours  = differenceInHours(new Date(dateCLD), new Date(dateNow))
    const diffTxt2   = t('corrector:ROW.WARNING1');
    const diffTxt1   = t('corrector:ROW.WARNING2');
    
    //dateCLDf   = format(dateCLD, "dd.MM.yyyy HH:mm");

    if (diffDays <= 7) {
      diffWarnTxt = diffTxt1.replace('[X]', diffDays);
      diffWarnIcon = '/media/icons/WE_correct_icon_achtung.svg';
      diffWarnCol  = '#FFCC0F';
    }
    if (diffDays <= 3) {
      diffWarnTxt = diffHours <= 36 ? diffTxt2.replace('[X]', diffHours) : diffWarnTxt = diffTxt1.replace('[X]', diffDays);
      diffWarnIcon = '/media/icons/WE_correct_icon_achtung-orange.svg';
      diffWarnCol  = '#FF7F15';
    }
    if (diffDays <= 1) {
      diffWarnTxt = diffTxt2.replace('[X]', diffHours);
      diffWarnIcon = '/media/icons/WE_correct_icon_achtung-rot.svg';
      diffWarnCol  = '#FF3333';
    }
  }
  const deadlineStyle = {
    color: diffWarnCol
  }
  const deadlinePopupStyle = {
    borderRadius: 5,
    opacity: 0.95,
    padding: '1em',
    color: diffWarnCol,
    minWidth: '300px'
  }

  return (
    <Table.Row onClick={onRowClick}>
      <Table.Cell width={2} style={imgStyle}>&nbsp;</Table.Cell>
      <Table.Cell width={8} className='Description'>
        <Item>
          <Item.Content>
            <Item.Header>{project.title}</Item.Header>
            <Item.Description>{stage_txt}</Item.Description>
            <Item.Extra><div class='tags'>{tagsList}</div></Item.Extra>
          </Item.Content>
        </Item>
      </Table.Cell>
      <Table.Cell width={1} className='Stufe'>
        <div className='stufe'>
          <span className='stufe_head'>{t('corrector:ROW.Stufe')}</span>
          <span className='stufe_value'>{project.correctionLevels.length}</span>
        </div>
      </Table.Cell>
      <Table.Cell width={3} className='Meta' style={{paddingTop: '2px', paddingBottom: '2px'}}>
        <table>
          <tr>
            <td style={{width: '99px'}}>{t('corrector:ROW.Erstellt')}</td>
            <td><span className='date'>{fdateCreated}</span></td>
            {!archive && fdateDeadline && (
              <td rowSpan='3'>
                <Popup
                  trigger ={<Image size='tiny' src={diffWarnIcon} style={{width: '40px', height: '40px', marginLeft: '20px'}} />}
                  content = {diffWarnTxt}
                  style = {deadlinePopupStyle}
                  inverted
                />
              </td>
            )}
          </tr>
          {!archive && (
            <tr>
              <td>{t('corrector:ROW.Geändert')}</td>
              <td><span className='date'>{fdateChanged}</span></td>
            </tr>)}
          {!archive && fdateDeadline && (
            <tr>
              <td>{t('corrector:ROW.Korrekturen')}</td>
              <td><span className='date' style={deadlineStyle}>{fdateDeadline}</span></td>
            </tr>
          )}
          {archive && (
            <tr>
              <td>{t('corrector:ROW.Archiviert')}</td>
              <td><span className='date'>{fdateArchived}</span></td>
            </tr>
          )}
          {fdatePrint && (
            <tr>
              <td>{t('corrector:ROW.Druckfreigabe')}</td>
              <td><span className='date'>{fdatePrint}</span></td>
            </tr>
          )}
        </table>




        
      </Table.Cell>
      <Table.Cell width={2} className='Buttons'>
        <Button icon size='mini' floated='right' labelPosition='left' primary onClick={()=>{
            dispatch(loadProject(project.domain, project.id, archive));
            dispatch(setActiveModule('corrector'));
          }}>{t('corrector:ROW.Öffnen')}<Icon circular name='folder open outline' /></Button>
        {!archive && (<Button icon size='mini' labelPosition='left' floated='right' onClick={()=>{ 
          dispatch(showConfirm({
            cancelButton: t('corrector:ROW.Abbrechen'),
            confirmButton: t('corrector:ROW.Archivieren'),
            message: <>{t('corrector:ROW.Projekt wirklich archivieren', project.title)}</>,
            addContent: <>
              <Form>
                <Form.Field>
                  <Checkbox id='archive_private' toggle value={1} label={t('corrector:ROW.Projekt privat archivieren')} />
                </Form.Field>
                <Form.Field>
                {t('corrector:ROW.Privat projekt info')}
                </Form.Field>
              </Form>
            </>,
            onConfirm: ()=>{
              dispatch(archiveProject(project.domain, project.id, document.getElementById("archive_private").checked));
              dispatch(setActiveModule('dashboard'));
            }
          })) 
        }}>{t('corrector:ROW.Archivieren')}<Icon name='file archive outline' /></Button>)}
        {archive && (<Button icon size='mini' labelPosition='left' floated='right' onClick={()=>{ dispatch(reactivateProject(project.domain, project.id)); dispatch(setActiveModule('archive')); }}>{t('corrector:ROW.Reaktivieren')}<Icon name='undo' /></Button>)}
      </Table.Cell>
    </Table.Row>
  );
}

export default WECProjectRow;