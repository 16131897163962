// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
//import { useTranslation } from 'react-i18next';

// semantic ui
import { Modal, Header, Button, Icon, Divider, TextArea, Form } from 'semantic-ui-react';

// Redux
import { isVisibleSingle, hideMessageSingle, messageUser, messageContent, setMessageContent, sendSingleMessage } from './message.slice';

// Styles
import './message.css';






/**
 *
 */
const WECMessageSingle = () => {
    // global state
    const dispatch  = useDispatch();
    const visible = useSelector(isVisibleSingle);
    const reciepient = useSelector(messageUser);
    const content = useSelector(messageContent);

    // 
    if (!visible) {
        return <></>
    }

    return (
        <Modal
            open = {visible}
            className = 'wecmessage single'
            size = 'small'
            closeIcon 
            closeOnEscape = {false} 
            closeOnDimmerClick = {false}
            onClose = {() => dispatch(hideMessageSingle())}
        >
            <Modal.Content>
                <Header>{reciepient.details.forename} {reciepient.details.surname} benachrichtigen</Header>
                <Form>
                    <Form.Field>
                        <label>Nachricht</label>
                        <TextArea placeholder='Nachricht hinzufügen' onChange={(e, {value})=>dispatch(setMessageContent(value))}>{content}</TextArea>
                    </Form.Field>
                </Form>
                <Divider />
                <Button size='mini' floated='right' icon labelPosition='left' onClick={()=>dispatch(sendSingleMessage(reciepient.id, content))} primary><Icon name='send' />Benachrichtigen</Button>
                <Button size='mini' floated='right' icon labelPosition='left' onClick={()=>dispatch(hideMessageSingle())}><Icon name='times' />Schließen</Button>
            </Modal.Content>
        </Modal>
    );
}

export default WECMessageSingle;