// react base
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { dataProject } from '../corrector.slice';

// semantic ui
import { Header, Image, Label } from 'semantic-ui-react';


// Styles
import './project.css';



/**
 * 
 */
const WECProjectTags = () => {
  // local state
  const [tags, showTags] = useState(false);
  
  //global state
  const _project = useSelector(dataProject);

  // translation
  const { t } = useTranslation('corrector');

  // tags list
  const tagsList = typeof _project.tags === 'undefined' ? <></> : _project.tags.map((tag, i) => {
    return (
      <Label key={i} className="hashtag" size='mini' icon='hashtag' content={tag.name} />
    );
  })

  // tooltipp
  const tagsTooltipp = tags ? t('corrector:TAGS.Schlagworte ausbleden') : t('corrector:TAGS.Schlagworte anzeigen');

  // icon src
  const iconSrc = tags ? '/media/icons/WE_correct_icon_chevron-kreis-unten.svg' : '/media/icons/WE_correct_icon_chevron-kreis-oben.svg';

  return (
    <React.Fragment>
      <Header style={{float: "left"}}>{t('corrector:TAGS.Schlagworte')}</Header>
      <Image style={{width: "20px", cursor: 'pointer'}} src={iconSrc} title={tagsTooltipp} onClick={()=>{if (tags) { showTags(false) } else { showTags(true) }}} />
      {tags && (
        <div className="tagslist" style={{clear: "both", paddingTop: "15px"}}>
        {tagsList}
        </div>
      )}
    </React.Fragment>
  )
}

export default WECProjectTags;