// react base
import React, { useEffect }  from 'react';
import { useSelector, useDispatch } from 'react-redux';

// semantic ui
import { Modal, Header, Button, Table, Icon } from 'semantic-ui-react';

// Redux
import { isVisible, hideRoles, getRoles, setRoles, deleteRole } from './roles.slice';
import { showRole } from './role.slice';
import { selectConfig, selectBaseProtocol, selectAuthUrl } from '../../WECorrect.slice';
import { fetchGET } from "../toolbox/requestor.slice";
import { showConfirm } from "../confirm/confirm.slice";
import { selectUser } from '../login/login.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// Styles
import './roles.css';



/**
 * 
 * @param {Array} roles
 * @param {Function} onRowClick
 * @param {Function} onDelete
 * @param {Boolean} permissionEdit 
 * @returns 
 */
const WECRoles_ListFixed = ({roles, onRowClick, permissionEdit}) => {
    if (typeof roles === 'undefined' || !roles) {
        return <></>
    }

    const roles_list = roles.map((role) => {
        return (
            <Table.Row>
                <Table.Cell collapsing>{role.name}</Table.Cell>
                <Table.Cell collapsing textAlign='right'>
                    {permissionEdit && (
                        <Button primary icon onClick={()=>onRowClick(role)}><Icon name='edit' /></Button>
                    )}
                </Table.Cell>
            </Table.Row>
        );
    })

    return (
        <React.Fragment>    
            <Header>Feste Rollen</Header>
            <Table basic='very' selectable>
                <Table.Body>
                    {roles_list}
                </Table.Body>
            </Table>
        </React.Fragment>
    );
}



/**
 * 
 * @param {Array} roles 
 * @param {Function} onRowClick 
 * @param {Function} onDelete 
 * @param {Boolean} permissionEdit 
 * @returns 
 */
const WECRoles_ListFree = ({roles, onRowClick, onDelete, permissionEdit}) => {
    if (typeof roles === 'undefined' || !roles) {
        return <></>
    }

    const roles_list = roles.map((role) => {
        return (
            <Table.Row>
                <Table.Cell collapsing>{role.name}</Table.Cell>
                <Table.Cell collapsing textAlign='right'>
                    {permissionEdit && (
                        <>
                            <Button icon onClick={()=>onDelete(role)}><Icon name='trash' /></Button>
                            <Button primary icon onClick={()=>onRowClick(role)}><Icon name='edit' /></Button>
                        </>
                    )}
                </Table.Cell>
            </Table.Row>
        );
    })

    return (
        <React.Fragment>    
            <Header>Freie Rollen</Header>
            <Table basic='very' selectable>
                <Table.Body>
                    {roles_list}
                </Table.Body>
            </Table>
        </React.Fragment>
    );
}



/**
 *
 */
const WECRoles = () => {
    // global state
    const dispatch  = useDispatch();
    const visible = useSelector(isVisible);
    const roles = useSelector(getRoles);
    const config = useSelector(selectConfig);
    const baseProtocol  = useSelector(selectBaseProtocol);
    const authUrl = baseProtocol + '//' + useSelector(selectAuthUrl);
    const userLoggedIn  = useSelector(selectUser);

    //
    useEffect(() => {
        if (visible && !roles) {
            const _sorting = { name: 'ASC' };
            const _filter =  { domain: config.domain};
            const options = { filter: _filter, sorting: _sorting };
            dispatch(fetchGET(authUrl + "/api/roles/?options=" + btoa(JSON.stringify(options)).replace(/=/g, '_'))).then(
                response => dispatch(setRoles(response))
            );
        }
    })

    // 
    if (!visible || !roles) {
        return <></>
    }

    // Permissions
    let permissionHandler = new WECPermissionHandler();
    const permission_PERM_SYS_08 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_08') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
    const permission_PERM_SYS_09 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_09') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);

    // new Role object
    const newRole = {
        id: null,
        name: ''
    }

    // split roles
    let roles_fixed = []
    let roles_free = []
    roles.forEach(role => {
        if (role.type === 'S_CORRECTOR' || role.type === 'S_ADMIN' || role.type === 'S_GRAPHIC') {
            roles_fixed.push(role);
        } else {
            roles_free.push(role);
        }
    });


    return (
        <Modal
            open = {true}
            className = 'wecroles'
            size = 'small'
            closeIcon 
            closeOnEscape = {false} 
            closeOnDimmerClick = {false}
            onClose = {() => dispatch(hideRoles())}
        >
            <React.Fragment>
                <Modal.Content>
                    <WECRoles_ListFixed 
                        roles = {roles_fixed} 
                        permissionEdit = {permission_PERM_SYS_09}
                        onRowClick = {(role)=> dispatch(showRole(role))}
                    />

                    <WECRoles_ListFree 
                        roles = {roles_free}
                        permissionEdit = {permission_PERM_SYS_09}
                        onRowClick = {(role)=> dispatch(showRole(role))} 
                        onDelete = {(role)=> dispatch(showConfirm({
                            cancelButton: 'Abbrechen',
                            confirmButton: 'Löschen',
                            message: 'Role ' + role.name + ' wirklich löschen?',
                            onConfirm: ()=>dispatch(deleteRole(role))
                        }))} 
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button icon labelPosition='left' onClick={()=>dispatch(hideRoles())}><Icon name='times' />Schließen</Button>
                    {permission_PERM_SYS_08 && (
                        <Button primary icon labelPosition='left' onClick={()=>dispatch(showRole(newRole))}><Icon name='plus' />Hinzufügen</Button>
                    )}
                </Modal.Actions>
            </React.Fragment>
        </Modal>
    );
}

export default WECRoles;