// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { initCorrections, initCorrectionsFromDB } from './editor.slice';
import { dataProject, dataCorrectionLevel, selectedPage, selectPage } from '../corrector.slice';
import { selectBaseProtocol, selectBackendUrl } from '../../../WECorrect.slice';

// semantic ui
import { Divider, Header, Icon } from 'semantic-ui-react';

// Styles
import './editor.css';



/**
 * 
 */
const WECCorrectorEditorPages = (props) => {
  const dispatch = useDispatch();
  const c_correctionLevel = useSelector(dataCorrectionLevel);
  const c_project = useSelector(dataProject);
  const c_selectedPageNumber = useSelector(selectedPage);
  const c_selectBaseProtocol = useSelector(selectBaseProtocol);
  const s_selectBackendUrl = useSelector(selectBackendUrl);

  // translation
  const { t } = useTranslation('corrector');
  
  return (
    <React.Fragment>
      <Header as='h3'>{t('corrector:EDITOR_PAGES.Seiten')}</Header>
      <Divider />
      <div className='pages'>
        {c_correctionLevel.pages.map((_page, i) => {
          const src = c_selectBaseProtocol + '//' + s_selectBackendUrl + '/media/projects/' + c_project.domain + '/' + c_project.id + '/' + c_correctionLevel.id+'/thumbs/' + _page.pageNr + '.jpg';
          
          // user state text 
          let _state = t('corrector:EDITOR_PAGES.Kein Status');
          if (typeof _page.userstate !== 'undefined') {
            switch(_page.userstate.state) {
              case 'open':  _state = t('corrector:EDITOR_PAGES.in Bearbeitung'); break;
              case 'locked': _state = t('corrector:EDITOR_PAGES.Abgeschlossen'); break;
              default: _state = t('corrector:EDITOR_PAGES.Kein Status');
            }
          }

          // page image & check icon
          let pageImg;
          if (typeof _page.userstate !== 'undefined' && typeof _page.userstate.state !== 'undefined' && _page.userstate.state === 'locked') {
            pageImg = (
              <>
                  <div className='checkmarkOverlay'>
                    <Icon name='checkmark' size='massive' />
                  </div>
                  <img alt='' className='checked' src={src} />
                </>
            );
          } else {
            pageImg = (<img alt='' src={src} />);
          }

          // className
          const className = _page.id === c_selectedPageNumber ? 'page selected' : 'page'; 

          return (
            <div 
              key = {i}
              className = {className}
              title = {_state} 
              onClick = {()=> { 
                //dispatch(selectPage({id: _page.id, key: i, resetInitialZoomLevel: true}));
                //dispatch(initCorrections(_page.corrections));
                dispatch(initCorrectionsFromDB(_page.domain, _page.id, i, true));
              }
            }>
              <div class="image thumbL">{pageImg}</div>
              <div className={'page_number'}>{t('corrector:EDITOR_PAGES.Seite')} {_page.pageNr}</div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default WECCorrectorEditorPages;