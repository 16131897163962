// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Modal, Header, Button, Divider, Container } from 'semantic-ui-react';

// Redux
import { selectUserId, selectUsername, selectPassword, selectRPassword, selectEmail, selectType, selectTeams, selectDetails, selectSettings, deleteUser, removeUserFromProjects, setReload, selectRole, selectLanguage } from './user.slice';
import { isEditUserVisible, saveUser, hideModal, isDirty, isActive } from './user.slice';
import { selectToken } from '../login/login.slice';
import { selectMandant, selectBaseProtocol, selectAuthUrl } from '../../WECorrect.slice';
import { showConfirm } from '../confirm/confirm.slice';
import { selectUser } from '../login/login.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// App
import WECUser_Basics from './basics';
import WECUser_Details from './details';
import WECUser_Assignments from './assignments';
import WECUser_Settings from './settings';
import WECUser_Projects from './projects';

// Styles
import './user.css';





/**
 *
 */
const WECUserEdit = () => {
    // global state
    const dispatch  = useDispatch();

    // translations
    const { t } = useTranslation('user', 'common');
    
    // User
    const userId        = useSelector(selectUserId);
    const username      = useSelector(selectUsername);
    const password      = useSelector(selectPassword);
    const rpassword     = useSelector(selectRPassword);
    const email         = useSelector(selectEmail);
    const type          = useSelector(selectType);
    const teams         = useSelector(selectTeams);
    const role          = useSelector(selectRole);
    const userDetails   = useSelector(selectDetails);
    const active        = useSelector(isActive);
    const dirty         = useSelector(isDirty);
    const visible       = useSelector(isEditUserVisible);
    const settings      = useSelector(selectSettings);
    const language      = useSelector(selectLanguage);

    // Base
    const mandant       = useSelector(selectMandant);
    const authUrl       = useSelector(selectBaseProtocol) + '//' + useSelector(selectAuthUrl);
    const token         = useSelector(selectToken);

    // Login
    const userLoggedIn  = useSelector(selectUser);

    // 
    if (!visible) {
        return <></>
    }

    // Permissions
    let permissionHandler = new WECPermissionHandler();
    const permission_PERM_SYS_03 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_03') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
    
    // Save button
    let saveData = { 
        id : userId, 
        mandant : mandant, 
        token : token, 
        authUrl : authUrl, 
        username : username, 
        email : email, 
        password : password, 
        rpassword : rpassword,
        details : userDetails,
        type: type,
        teams: teams,
        role: role,
        active: active,
        settings: settings,
        language: language
    }
    for (const key in userDetails) {
        if (Object.hasOwnProperty.call(userDetails, key)) {
            saveData[key] = userDetails[key];
        }
    }
    const save_button = dirty ? <Button primary onClick={() => dispatch(saveUser(saveData))}>{t('user:EDIT.BUTTONS.Save')}</Button> : <></>;

    
    return (
        <Modal 
            open = {true} 
            className = 'wecuser_edit' 
            size = 'xlarge' 
            closeIcon 
            closeOnEscape = {false} 
            closeOnDimmerClick = {false}
            onClose = {() => dispatch(hideModal({ modal : 'EditUser' }))}
        >
            <Modal.Content>
                <Header>{t('user:EDIT.LABELS.Edit User', username)}</Header>
                <Container className='leftColumn'>    
                    <WECUser_Basics />
                    <Divider />
                    <WECUser_Details />
                    <Divider />
                    <WECUser_Assignments />
                    <Divider />
                    <WECUser_Settings />
                </Container>
                <Container className='rightColumn'>
                    <WECUser_Projects userId={userId} />
                </Container>
            </Modal.Content>
            <Modal.Actions>
                {permission_PERM_SYS_03 && (
                    <Button icon='trash' floated='left' onClick={() => 
                        dispatch(showConfirm({
                            cancelButton: t('user:EDIT.BUTTONS.Abort'),
                            confirmButton: t('user:EDIT.BUTTONS.Remove'),
                            message: <>{t('user:EDIT.MESSAGES.Remove user', username)}</>,
                            addContent: <>{t('user:EDIT.MESSAGES.Remove user info', username)}</>,
                            onConfirm: ()=>{
                                dispatch(deleteUser({user: userId, mandant: mandant}));
                                dispatch(removeUserFromProjects({user: userId, mandant: mandant}));
                                dispatch(setReload(true));
                                dispatch(hideModal({ modal : 'EditUser' }));
                            }
                        }))
                    }>{t('user:EDIT.BUTTONS.Remove')}</Button>
                )}
                <Button icon='times' onClick={() => dispatch(hideModal({ modal : 'EditUser' }))}>{t('user:EDIT.BUTTONS.Close')}</Button>
                {save_button}
            </Modal.Actions>
        </Modal>
    );
}

export default WECUserEdit;