// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { selectedCorrection } from './editor.slice';
import { dataPage } from '../corrector.slice';
import { isDialogVisible, hideDialog, addComment, setFile, setText, getFile, getText } from './dialogAddComment.slice';

// semantic ui
import { Button, Comment, TextArea, Modal, Header, Form, Icon, Container, Input } from 'semantic-ui-react';

// Styles
import './editor.css';




/**
 * 
 * @returns 
 */
const WECCorrectorEditorAddComment = () => {
  const dispatch = useDispatch();
  const c_dataPage = useSelector(dataPage);
  const e_selectedCorrection = useSelector(selectedCorrection);
  const fileInputRef = React.createRef();
  const _isDialogVisible = useSelector(isDialogVisible);
  const c_file = useSelector(getFile)
  const c_text = useSelector(getText)

  // translation
  const { t } = useTranslation('corrector');

  // filename
  const e_newCommentFileName = c_file && typeof c_file.name !== 'undefined' ? c_file.name : '';

  //
  let correction;
  c_dataPage.corrections.map((_correction, i) => {
    if (_correction.id === e_selectedCorrection) {
      correction = _correction;
    }
    return true;
  });
  if (!correction) {
    return <></>;
  }
  

  // Attachment
  let attachment = (<></>);
  if (typeof correction.comment !== 'undefined' && typeof correction.comment.attachment !== 'undefined' && typeof correction.comment.attachment.filename !== 'undefined') {
    attachment = (<Comment.Text><Icon link name="attach" onClick={()=>{window.open('/media/uploads/'+correction.comment.attachment.filename, '_blank');}} /></Comment.Text>)
  }

  // Replies
  let replies = <></>;
  if (typeof correction.comment !== 'undefined' && typeof correction.comment.comments !== 'undefined' && correction.comment.comments.length) {
    replies = correction.comment.comments.map((rcomment, ii) => {
      let rattachment = (<></>);
      if (typeof rcomment.attachment !== 'undefined' && typeof rcomment.attachment.filename !== 'undefined') {
        rattachment = (<Comment.Text><Icon link name="attach" onClick={()=>{window.open('/media/uploads/'+rcomment.attachment.filename, '_blank');}} /></Comment.Text>)
      }
      return (
        <Comment key={ii} commentProps={rcomment}>
          <Comment.Content>
            <Comment.Author as='a'>{rcomment.user}</Comment.Author>
            <Comment.Metadata><span>{rcomment.creation}</span></Comment.Metadata>
            <Comment.Text>{rcomment.content}</Comment.Text>
            {rattachment}
          </Comment.Content>
        </Comment>
      );
    })
    replies = (<Comment.Group size='mini' className='replies'>{replies}</Comment.Group>);
  }

  // 
  const addCommentData = {
    content: c_text,
    file: c_file,
    domain: correction.domain,
    correctionId: correction.id,
    commentId: correction.comment.id
  }

  return (
    <Modal open={_isDialogVisible} className='wec_editor_newcomment' size='small' closeOnEscape={false} closeOnDimmerClick={false}>
      <Modal.Content>
        <Header as='h1'>{t('corrector:EDITOR_ADDCOMMENT.Neuer Kommentar')}</Header>
        <Container className='Comment'>
          <Comment.Group threaded size='mini'>
            {c_dataPage.corrections.map((correction, i) => {
              if (correction.id === e_selectedCorrection) {
                return (
                  <React.Fragment>
                    <Comment key={i} commentProps={correction.comment}>
                      <Comment.Content>
                        <Comment.Author as='a'>{correction.comment.user}</Comment.Author>
                        <Comment.Metadata><span>{correction.comment.creation}</span></Comment.Metadata>
                        <Comment.Text>{correction.comment.content}</Comment.Text>
                        {attachment}
                      </Comment.Content>
                      {replies}
                    </Comment>
                  </React.Fragment>
                );
              }
              return <></>
            })}
          </Comment.Group>
        </Container>
        <Form>
          <Form.Field>
            <label>{t('corrector:EDITOR_ADDCOMMENT.Kommentar')}</label>
            <TextArea id='addcomment-content' placeholder="Kommentar" onChange={(e, {value})=>dispatch(setText(value))}>{c_text}</TextArea>
          </Form.Field>
          <Form.Group widths='equal'>
            <Form.Field width={12}>
              <label>{t('corrector:EDITOR_ADDCOMMENT.Anhang')}</label>
              <input ref={fileInputRef} type="file" hidden onChange={(e)=>dispatch(setFile(e.target.files[0]))} />
              <Input size="small" fluid readOnly value={e_newCommentFileName} placeholder={t('corrector:EDITOR_ADDCOMMENT.Datei vom Computer')} />
            </Form.Field>
            <Form.Field width={4}>
              <Button size='mini' content="Durchsuchen" labelPosition="left" icon="file" onClick={() => fileInputRef.current.click()} style={{marginTop: '25px'}}/>
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button size='mini' icon labelPosition='left' onClick={() => dispatch(hideDialog())}>{t('corrector:EDITOR_ADDCOMMENT.Abbrechen')}<Icon name='close' /></Button>
        <Button size='mini' icon labelPosition='left' onClick={() => dispatch(addComment(addCommentData))} primary>{t('corrector:EDITOR_ADDCOMMENT.Übernehmen')}<Icon name='check' /></Button>
      </Modal.Actions>
    </Modal>
  );
}

export default WECCorrectorEditorAddComment;