import { createSlice } from '@reduxjs/toolkit';
import { fileUpload, fetchPOST } from "../../toolbox/requestor.slice";
import { setCorrectionND, setSaving, resetHistory, selectCorrection } from './editor.slice'
import { addMessage } from '../../snackbar/snackbar.slice';
import { format } from 'date-fns/esm'
import { changeData } from '../corrector.slice';



/**
 * 
 */
export const dialogNewCorrectionSlice = createSlice({
  name: 'dialogNewCorrection',
  initialState: {
    dialogVisible : false,
    projectDomain: null,
    refresh: true,
    scale: 1,
    offset: { 
      x: 0, 
      y: 0, 
      margin: 0
    },
    correction: null,
    newCorrection: {
      content: '',
      file: null
    },
  },

  reducers: {
    hideDialog : (state) => { 
      state.dialogVisible = false;
      state.projectDomain = null;
    },
    showDialog : (state, action) => { 
      state.dialogVisible = true;
      if (typeof action.payload.domain !== 'undefined') {
        state.projectDomain = action.payload.domain;
      }
      if (typeof action.payload.correction !== 'undefined') {
        state.correction = action.payload.correction;
      }
    },
    showCorrectionDialog : (state, action) => { 
      state.dialogVisible = true;
      if (typeof action.payload.domain !== 'undefined') {
        state.projectDomain = action.payload.domain;
      }
      if (typeof action.payload.correction !== 'undefined') {
        state.correction = action.payload.correction;
      }
    },
    toggleDialog : (state) => { state.dialogVisible = !state.dialogVisible; },
    setRefresh : (state, action) => { state.refresh = action.payload },

    //
    setScale: (state, action) => { state.scale = parseInt(action.payload) / 100; },
    setOffset: (state, action) => { 
      state.offset = {
        x: parseInt(action.payload.x), 
        y: parseInt(action.payload.y),
        margin: parseInt(action.payload.margin)
      };
    },
    setColor: (state, action) => {
      state.correction.marker.fill = action.payload;
      state.correction.marker.color = action.payload;
    },
    setTool: (state, action) => { state.correction.marker.tool = action.payload; },
    setToolMessage: (state, action) => { state.newCorrection.content = action.payload; },

    setCorrectionMarker: (state, action) => { state.correction.marker = action.payload; },

    //
    changeNewCorrection: (state, action) => { state.newCorrection[action.payload.key] = action.payload.value; },
    resetNewCorrection: (state) => {
      state.newCorrection = {
        content: '',
        file: null
      };
      state.correction = null;
      state.refresh = true;
      state.scale = 1;
      state.offset = {x: 0, y: 0, margin: 0};
    },

  },
});

export const { hideDialog, showDialog, showCorrectionDialog, toggleDialog, setRefresh, setScale, setOffset, setColor, setTool, setToolMessage, resetNewCorrection, changeNewCorrection, setCorrectionMarker } = dialogNewCorrectionSlice.actions;

export const isDialogVisible = (state) => state.dialogNewCorrection.dialogVisible;
export const projectDomain = (state) => state.dialogNewCorrection.projectDomain;
export const refresh = (state) => state.dialogNewCorrection.refresh;
export const scale = (state) => state.dialogNewCorrection.scale;
export const offset = (state) => state.dialogNewCorrection.offset;
export const newCorrection = (state) => state.dialogNewCorrection.newCorrection;

export default dialogNewCorrectionSlice.reducer;





/**
 * 
 * @returns 
 */
export function applyNewCorrection(domain, pageData) {
  return async (dispatch, getState) => {
    const state = getState();
    const initialScale  = state.editor.scale;
    const backendUrl    = state.wec.baseProtocol + '//' + state.wec.backendUrl;
    const attachmentUrl = state.wec.baseProtocol + '//' + state.wec.attachmentUrl;
    const username = state.login.username;

    let _correction = Object.assign({}, state.editor.corrections[state.editor.corrections.length - 1]);
    _correction.marker = Object.assign({}, _correction.marker);
    let _pages = Object.assign([], state.corrector.data.correctionlevel.pages);
    let _file = null;

    // start saving animation
    dispatch(setSaving(true));
    
    // Anhang hochladen
    if (state.dialogNewCorrection.newCorrection.file) {
      const filename = await fileUpload(attachmentUrl, state.dialogNewCorrection.newCorrection.file, (progressData)=>{
        console.log(progressData);
      });
      _file = filename;
    }

    // 
    _correction.pageData = pageData;
    _correction.domain = domain;
    if (typeof _correction.cnt === 'undefined') {
      _correction.cnt = state.editor.corrections.length;
    }

    // Comment
    _correction.comment = {
      creation: format(new Date(), "yyyy-MM-dd, HH:mm"),
      content: state.dialogNewCorrection.newCorrection.content,
      user: username,
      file: _file,
      new: true
    };

    // calculate 100% zoom measurements
    _correction.marker.x      = Math.ceil(_correction.marker.x / initialScale);
    _correction.marker.y      = Math.ceil(_correction.marker.y / initialScale);

    // prepare page data for stage.corrector.data.correctionlevel / stage.corrector.data.page
    let _page = Object.assign({}, _pages[pageData.number - 1]);
    _page.corrections = Object.assign([], _page.corrections);
    _page.corrections.push(_correction);

    // save correction
    try {
      dispatch(fetchPOST(backendUrl + '/api/latest/'+domain+'/correction/create', _correction)).then(
        correction => {
          if (typeof correction.cnt === 'undefined') {
            correction.cnt = state.editor.corrections.length;
          }
          delete correction.dirty;
          correction.marker.cnt = correction.cnt;

          // origin
          correction.marker.origin = {
            x: correction.marker.x,
            y: correction.marker.y,
            width: correction.marker.width,
            height: correction.marker.height
          }

          // for drawing
          correction.marker.x = _correction.marker.x * initialScale;
          correction.marker.y = _correction.marker.y * initialScale;
          correction.marker.width = _correction.marker.width * initialScale;
          correction.marker.height = _correction.marker.height * initialScale;

          // apply
          dispatch(addMessage({type: 'NOTICE', text: 'Korrektur wurde angelegt'}));
          dispatch(setCorrectionND(correction));
          dispatch(changeData({key: 'page', value: _page}))
          dispatch(resetHistory(correction));
          dispatch(resetNewCorrection())
          dispatch(setSaving(false));
          dispatch(selectCorrection(correction.id));
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}