// react base
import React from 'react';
import { useSelector } from 'react-redux';

// Redux
import { selected } from './corrector.slice';

// App
import WECCorrectorDetails from "./details";
import WECCorrectorPages from "./pages";
import WECCorrectorEditor from "./editor/editor";
import TSCorrectorCorrectionDate from "./correctionDate"
import WECCorrectorStage from "./correctionStage"
import WECUserCLStates from "./userCLStates/userCLStates"
import WECCorrectionlevelNew from './correctionlevel/new';

// Styles
import './corrector.css';



/**
 * 
 */
const WECCorrector = () => {
  const c_selected = useSelector(selected);
  
  return (
    <React.Fragment>
      {c_selected.project && !c_selected.correctionlevel && !c_selected.page && (<WECCorrectorDetails />)}
      {c_selected.project &&  c_selected.correctionlevel && !c_selected.page && (<WECCorrectorPages />)}
      {c_selected.project &&  c_selected.correctionlevel &&  c_selected.page && (<WECCorrectorEditor />)}
      
      <WECUserCLStates />
      <WECCorrectorStage />
      <WECCorrectionlevelNew />

      <TSCorrectorCorrectionDate />
    </React.Fragment>
  );
}

export default WECCorrector;