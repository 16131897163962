// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
//import { useTranslation } from 'react-i18next';

// semantic ui
import { Modal, Header, Button, Icon, Divider, TextArea, Form, Container, List } from 'semantic-ui-react';

// Redux
import { isVisibleMultiple, hideMessageMultiple, messageContent, messageReciepent, sendMultipleMessage } from './message.slice';
import { selectAttendees } from '../corrector/project/attendees.slice';


// Styles
import './message.css';






/**
 *
 */
const WECMessageMultiple = () => {
    // global state
    const dispatch  = useDispatch();
    const visible = useSelector(isVisibleMultiple);
    const content = useSelector(messageContent);
    const reciepient = useSelector(messageReciepent);
    const attendees = useSelector(selectAttendees);

    // 
    if (!visible) {
        return <></>
    }

    // 
    let roleAttendees = []
    let roleAttendeesIds = []
    reciepient.ids.forEach(roleId => {
        if (typeof attendees[roleId] !== 'undefined') {
            for (const key in attendees[roleId]) {
                if (Object.hasOwnProperty.call(attendees[roleId], key)) {
                    const attendee = attendees[roleId][key];
                    roleAttendees.push(
                        <List.Item key={key}>
                            <List.Content>{attendee.details.forename} {attendee.details.surname}</List.Content>
                        </List.Item>
                    );
                    roleAttendeesIds.push(attendee.userid);
                }
            }
        }
    });

    return (
        <Modal
            open = {visible}
            className = 'wecmessage multiple'
            size = 'small'
            closeIcon 
            closeOnEscape = {false} 
            closeOnDimmerClick = {false}
            onClose = {() => dispatch(hideMessageMultiple())}
        >
            <Modal.Content>
                <Header>Kontrolleure benachrichtigen</Header>
                <Form>
                    <Form.Group>
                        <Form.Field width={11}>
                            <label>Nachricht</label>
                            <TextArea placeholder='Nachricht hinzufügen'>{content}</TextArea>
                        </Form.Field>
                        <Form.Field width={5}>
                            <label>Kontrolleure</label>
                            <Container className='correctors'>
                                <List>
                                    {roleAttendees}
                                </List>
                            </Container>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Divider />
                <Button size='mini' floated='right' icon labelPosition='left' onClick={()=>dispatch(sendMultipleMessage(roleAttendeesIds, content))} primary><Icon name='send' />Benachrichtigen</Button>
                <Button size='mini' floated='right' icon labelPosition='left' onClick={()=>dispatch(hideMessageMultiple())}><Icon name='times' />Schließen</Button>
            </Modal.Content>
        </Modal>
    );
}

export default WECMessageMultiple;