// react base
import React, { useEffect }  from 'react';
import { useSelector, useDispatch } from 'react-redux';

// semantic ui
import { Modal, Header, Button, Table, Label, Icon } from 'semantic-ui-react';

// Redux
import { isVisible, hideTeams, getTeams, setTeams, deleteTeam, setFilter, getFilter } from './teams.slice';
import { showTeam } from './team.slice';
import { selectConfig, selectBaseProtocol, selectAuthUrl } from '../../WECorrect.slice';
import { fetchGET } from "../toolbox/requestor.slice";
import { showConfirm } from "../confirm/confirm.slice";
import { selectUser } from '../login/login.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// Styles
import './teams.css';




/**
 * 
 * @param {Array} teams
 * @param {Integer} filter
 * @param {Function} onRowClick
 * @param {Function} onDelete
 * @param {Function} onFilter
 * @param {Boolean} permissionEdit
 * @returns 
 */
const WECTeams_List = ({teams, filter, onRowClick, onDelete, onFilter, permissionEdit}) => {
    if (typeof teams === 'undefined' || !teams) {
        return <></>
    }

    const teams_list = teams.map((team) => {
        if (team.type.name !== 'System') {
            const team_user_list = team.user.map((user) => {
                return (
                    <Label as='a'><Icon name='user' />{user.username}</Label>
                )
            })

            // users
            if (team_user_list.length > 5 ) {
                team_user_list.splice(20, team_user_list.length - 20);
            }
            const cnt_users = team_user_list.length;

            // type
            const type_name = typeof team.type !== 'undefined' ? team.type.name : '';

            // name
            const name_label = <Label className={team.type.name} icon='users' content={team.name} />
            
            return (
                <Table.Row>
                    <Table.Cell collapsing>{name_label}</Table.Cell>
                    <Table.Cell collapsing>{type_name}</Table.Cell>
                    <Table.Cell collapsing>{cnt_users}</Table.Cell>
                    <Table.Cell collapsing textAlign='right'>
                        {permissionEdit && (
                            <>
                            <Button primary icon onClick={()=>onRowClick(team)}><Icon name='edit' /></Button>
                            <Button icon onClick={()=>onDelete(team)}><Icon name='trash' /></Button>
                            </>
                        )}
                    </Table.Cell>
                </Table.Row>
            );
        }
        return <></>;
    })

    return (
        <React.Fragment>    
            <Header>Teams</Header>

            <Label
                as = 'a' 
                className = "Agentur"
                size = 'mini'
                content = 'Agentur'
                onClick = {()=>onFilter(2)}
            />
            <Label
                as = 'a' 
                className = "Kunde"
                size = 'mini'
                content = 'Kunde'
                onClick = {()=>onFilter(3)}
            />
            <Label
                as = 'a' 
                className = "Gast"
                size = 'mini'
                content = 'Gast'
                onClick = {()=>onFilter(4)}
            />
            <Label
                as = 'a' 
                className = "Alle"
                size = 'mini'
                content = 'Alle'
                onClick = {()=>onFilter(null)}
            />
            
            <Table basic='very' selectable>
                <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell collapsing>Bezeichnung</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Typ</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Mitglieder</Table.HeaderCell>
                    <Table.HeaderCell collapsing></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {teams_list}
                </Table.Body>
            </Table>
        </React.Fragment>
    );
}



/**
 *
 */
const WECTeams = () => {
    // global state
    const dispatch  = useDispatch();
    const visible = useSelector(isVisible);
    const teams = useSelector(getTeams);
    const filter = useSelector(getFilter);
    const config = useSelector(selectConfig);
    const baseProtocol  = useSelector(selectBaseProtocol);
    const authUrl = baseProtocol + '//' + useSelector(selectAuthUrl);
    const userLoggedIn  = useSelector(selectUser);

    //
    useEffect(() => {
        if (visible && !teams) {
            const _sorting = { name: 'ASC' };
            const _filter = filter === null ? { domain: config.domain} : { domain: config.domain, type: filter };
            const options = { filter: _filter, sorting: _sorting };
            dispatch(fetchGET(authUrl + "/api/teams/?options=" + btoa(JSON.stringify(options)).replace(/=/g, '_'))).then(
                response => dispatch(setTeams(response))
            );
        }
    })

    // 
    if (!visible) {
        return <></>
    }

    // 
    const newTeam = {
        id: null,
        name: '',
        type: {id: null},
        role: {id: null},
        user: []
    }

    // Permissions
    let permissionHandler = new WECPermissionHandler();
    const permission_PERM_SYS_05 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_05') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
    const permission_PERM_SYS_06 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_06') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);

    return (
        <Modal
            open = {true}
            className = 'wecteams'
            size = 'large'
            closeIcon 
            closeOnEscape = {false} 
            closeOnDimmerClick = {false}
            onClose = {() => dispatch(hideTeams())}
        >
            <React.Fragment>
                <Modal.Content>
                    <WECTeams_List 
                        teams = {teams} 
                        filter = {filter}
                        permissionEdit = {permission_PERM_SYS_06}
                        onFilter = {(filter)=> {
                            dispatch(setFilter(filter))
                            dispatch(setTeams(null))
                        }}
                        onRowClick = {(team)=> dispatch(showTeam(team))} 
                        onDelete = {(team)=> dispatch(showConfirm({
                            cancelButton: 'Abbrechen',
                            confirmButton: 'Löschen',
                            message: 'Team ' + team.name + ' wirklich löschen?',
                            onConfirm: ()=>dispatch(deleteTeam(team))
                        }))} 
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button icon labelPosition='left' onClick={()=>dispatch(hideTeams())}><Icon name='times' />Schließen</Button>
                    {permission_PERM_SYS_05 && (
                        <Button primary icon labelPosition='left' onClick={()=>dispatch(showTeam(newTeam))}><Icon name='plus' />Hinzufügen</Button>
                    )}
                </Modal.Actions>
            </React.Fragment>
        </Modal>
    );
}

export default WECTeams;