// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Grid, Dropdown } from 'semantic-ui-react';

// Redux
import { selectType, selectTeams, selectRole } from './user.slice';
import { setType, setTeams, setRole } from './user.slice';
import { selectConfig } from '../../WECorrect.slice';
import { selectUser } from '../login/login.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// Styles
import './user.css';





/**
 *
 */
const WECUser_Assignments = () => {
    // global state
    const dispatch  = useDispatch();
    
    // translation
    const { t } = useTranslation('user', 'common');
    
    // User
    const type  = useSelector(selectType);
    const teams = useSelector(selectTeams);
    const role  = useSelector(selectRole);

    // Login
    const userLoggedIn      = useSelector(selectUser);

    // Permissions
    let permissionHandler = new WECPermissionHandler();
    const permission_PERM_SYS_03 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_03') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);

    // settings for types and teams 
    const config    = useSelector(selectConfig);
    const sys_roles = Object.assign([], config[config.domain].user.roles);
    const sys_types = Object.assign([], config[config.domain].user.types);
    let sys_teams   = Object.assign([], config[config.domain].user.teams);
    for (const key in sys_teams) {
        if (Object.hasOwnProperty.call(sys_teams, key)) {
            if (sys_teams[key].type.name === 'System') {
                sys_teams.splice(key, 1);
            }
        }
    }

    const renderLabel = (label) => (Object.assign({className: label.type.name}, {
        content: label.text,
        icon: 'users'
      }))
    
    return (
        <React.Fragment>
            <Grid>
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={4}><label>{t('user:ASSIGNMENTS.LABELS.Type')}</label></Grid.Column>
                    <Grid.Column width={12}>
                        {permission_PERM_SYS_03 && (
                            <Dropdown 
                                fluid selection search 
                                placeholder = {t('user:ASSIGNMENTS.LABELS.Select type')} 
                                options = {sys_types} 
                                value = {type}
                                onChange = {(e, { value })=>dispatch(setType({ type : value }))}
                            />
                        )}
                        {!permission_PERM_SYS_03 && (
                            <Dropdown 
                                fluid selection search 
                                placeholder = {t('user:ASSIGNMENTS.LABELS.Select type')} 
                                options = {sys_types} 
                                value = {type}
                                readonly
                            />
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={4}><label>{t('user:ASSIGNMENTS.LABELS.Teams')}</label></Grid.Column>
                    <Grid.Column width={12}>
                        {permission_PERM_SYS_03 && (
                            <Dropdown 
                                fluid selection multiple search 
                                placeholder = {t('user:ASSIGNMENTS.LABELS.Select team')} 
                                value = {teams} 
                                options = {sys_teams} 
                                renderLabel = {renderLabel}
                                onChange = {(e, { value })=>dispatch(setTeams({ teams : value }))}
                            />
                        )}
                        {!permission_PERM_SYS_03 && (
                            <Dropdown 
                                fluid selection multiple search 
                                placeholder = {t('user:ASSIGNMENTS.LABELS.Select team')} 
                                value = {teams} 
                                options = {sys_teams} 
                                renderLabel = {renderLabel}
                                readonly
                             />
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={4}><label>{t('user:ASSIGNMENTS.LABELS.Default Role')}</label></Grid.Column>
                    <Grid.Column width={12}>
                        {permission_PERM_SYS_03 && (
                            <Dropdown 
                                fluid selection search 
                                placeholder = {t('user:ASSIGNMENTS.LABELS.Select role')} 
                                value = {role} 
                                options = {sys_roles} 
                                renderLabel = {renderLabel}
                                onChange = {(e, { value })=>dispatch(setRole({ role : value }))}
                            />
                        )}
                        {!permission_PERM_SYS_03 && (
                            <Dropdown 
                                fluid selection search 
                                placeholder = {t('user:ASSIGNMENTS.LABELS.Select role')} 
                                value = {role} 
                                options = {sys_roles} 
                                renderLabel = {renderLabel}
                                readonly 
                            />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
}

export default WECUser_Assignments;