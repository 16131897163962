import { createSlice } from '@reduxjs/toolkit';
import { fetchPOST } from "../../toolbox/requestor.slice";
import { fileUploadRequest } from "../../toolbox/request_handler";
import { changeData } from "../corrector.slice";



/**
 * 
 */
export const newCorrectionlevelSlice = createSlice({
  name: 'newCorrectionlevel',
  initialState: {
    dialogVisible : false,
    correctionlevelDomain: null,
    projectId: null,
    project: null,
    loader: false,
    loaderText: null,
    counter: 1,
    file: null
  },
  reducers: {
    hideDialogCL : (state) => { 
      state.dialogVisible = false;
      state.correctionlevelDomain = null;
      state.loader = false;
      state.loaderText = null;
      state.projectId = false;
    },
    showDialogCL : (state, action) => { 
      state.dialogVisible = true;
      if (typeof action.payload.domain !== 'undefined') {
        state.correctionlevelDomain = action.payload.domain;
      }
      if (typeof action.payload.projectId !== 'undefined') {
        state.projectId = action.payload.projectId;
      }
      if (typeof action.payload.counter !== 'undefined') {
        state.counter = action.payload.counter;
      }
    },
    toggleDialogCL : (state) => { state.dialogVisible = !state.dialogVisible; },
    setFileCL : (state, action) => { state.file = action.payload },
    showLoaderCL : (state) => { state.loader = true },
    hideLoaderCL : (state) => { state.loader = false },
    setLoaderTextCL : (state, action) => { state.loaderText = action.payload },
  },
});

export const { hideDialogCL, showDialogCL, toggleDialogCL, showLoaderCL, hideLoaderCL, setFileCL, setLoaderTextCL } = newCorrectionlevelSlice.actions;

export const isDialogVisibleCL = (state) => state.newCorrectionlevel.dialogVisible;
export const correctionlevelDomain = (state) => state.newCorrectionlevel.correctionlevelDomain;
export const getFileCL = (state) => state.newCorrectionlevel.file;
export const isLoaderCL = (state) => state.newCorrectionlevel.loader;
export const getCounter = (state) => state.newCorrectionlevel.counter;
export const getLoaderText = (state) => state.newCorrectionlevel.loaderText;


export default newCorrectionlevelSlice.reducer;



/**
 * 
 * @param {Object} correctionlevelData 
 * @param {Object} params 
 * @returns 
 */
 export function createCorrectionlevel(correctionlevelData, params) {
  
  return async (dispatch, getState) => {
    const state = getState();
    const uploadUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl + '/api/latest/upload';
    const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
    const token = state.login.token;

    try {
      dispatch(showLoaderCL());

      // file upload request
      const filename = await fileUploadRequest(uploadUrl, correctionlevelData.file, token, (progressData)=>{
        const text = 'PDF wird hochgeladen: ' + parseInt(progressData.loaded) + '%';
        if (parseInt(progressData.loaded) === 100) {
          dispatch(setLoaderTextCL(params.loaderText));    
        } else {
          dispatch(setLoaderTextCL(text));
        }
      });

      // extend CorrectLevel object 
      const newCorrectionLevel = Object.assign({
        filename: filename
      }, correctionlevelData);

      // create new CorrectLevel
      dispatch(fetchPOST(backendUrl + '/api/latest/' + params.domain + '/project/' + state.newCorrectionlevel.projectId + '/cl', newCorrectionLevel)).then(
        (project) => {
          dispatch(changeData({ key: 'project', value: project}));
          dispatch(hideDialogCL());
        }
      );
    } catch (error) {
      console.error(error);
      dispatch(hideLoaderCL());
    }
  }
}