import { createSlice } from '@reduxjs/toolkit';
import { fetchDELETE, fetchPOST, fetchPUT } from "../toolbox/requestor.slice";
import { addMessage } from '../snackbar/snackbar.slice'




export const tagsSlice = createSlice({
  name: 'tags',
  initialState: {
    visible: false,
    tags: null
  },

  reducers: {
    showTags: (state) => { 
      state.visible = true;
    },
    hideTags: (state) => {
      state.visible = false;
      state.tags = null;
    },
    setTags: (state, actions) => {
      state.tags = actions.payload;
    }
  }
});
export const { showTags, hideTags, setTags } = tagsSlice.actions;
export const isVisible = (state) => state.tags.visible;
export const getTags = (state) => state.tags.tags;

export default tagsSlice.reducer;



export function updateTag(tag) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const url = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      if (typeof tag.id !== 'undefined' && tag.id) {
        console.log('[tag]', tag)
        dispatch(fetchPUT(url + '/api/latest/'+tag.domain+'/tag/'+tag.id, tag)).then(
          team => {
            dispatch(addMessage({type: 'NOTICE', text: 'Tag ' + tag.name + ' wurde gespeichert'}));
            dispatch(setTags(null))
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
}



export function createTag(tag) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const url = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      if (typeof tag.id !== 'undefined' && tag.id) {
        console.log('[tag]', tag)
        dispatch(fetchPOST(url + '/api/latest/'+tag.domain+'/tag', tag)).then(
          team => {
            dispatch(addMessage({type: 'NOTICE', text: 'Tag ' + tag.name + ' wurde angelegt'}));
            dispatch(setTags(null))
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
}



export function deleteTag(tag) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const url = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      if (typeof tag.id !== 'undefined' && tag.id) {
        dispatch(fetchDELETE(url + '/api/latest/'+tag.domain+'/tag/'+tag.id)).then(
          response => {
            dispatch(addMessage({type: 'NOTICE', text: 'Tag ' + tag.name + ' wurde gelöscht'}));
            dispatch(setTags(null))
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
}