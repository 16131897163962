// react base
import React, { useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { getActiveDomain, setActive, setProjects, setFilter, setSorting, setListStyle } from './projects.slice';
import { selectToken, selectUser } from '../login/login.slice';
import { showDialog } from './project/new.slice';
import { selectBaseProtocol, selectBackendUrl } from "../../WECorrect.slice";

// semantic ui
import { Container, Button, Icon, Divider, Card, Table, Input, Label, Image } from 'semantic-ui-react'

// App
import WECProjectCard from "./project/card";
import WECProjectRow from "./project/row";
import { asyncFetchGET } from "../toolbox/requestor.slice";

// Styles
import './corrector.css';
import { saveSetting } from '../user/user.slice';




/**
 * 
 * @param {String} domain
 * @param {Object} data
 * @param {Object} tags
 * @param {Array} usedTags
 * @param {String} listStyle
 * @param {Boolean} archive
 * @param {Function} onReload
 * @param {Function} onNew
 * @param {Function} setTableStyle
 * @param {Function} setCardsStyle
 * @param {Boolean} permission
 */
const WECProjectsList = ({domain, data, tags, usedTags, listStyle, archive, onReload, onNew, setTableStyle, setCardsStyle, permission}) => {
  // redux
  const dispatch  = useDispatch();

  // translation
  const { t } = useTranslation('corrector');

  // build projects cards
  const WECProjectCards = !data.projects ? <></> : data.projects.map((project, i) => { return (<WECProjectCard key={i} project={project} archive={archive} />)});

  // build projects rows
  const WECProjectRows = !data.projects ? <></> : data.projects.map((project, i) => { return (<WECProjectRow key={i} project={project} archive={archive} />)});
  
  // autocomplete suggestion
  const filter_title_value = data.filter !== null && typeof data.filter.title !== 'undefined' ? data.filter.title : '';
  const terms = filter_title_value.replace(',', '').split(' ');
  const last_term = terms[terms.length - 1];
  let filter_title_suggestion = filter_title_value;
  let found = false;
  tags.forEach(tag => {
    if (!found && last_term.length && tag.name.toLowerCase().startsWith(last_term.toLowerCase())) {
      filter_title_suggestion = filter_title_suggestion.replace(last_term, tag.name);
      found = true;
    }
  });

  // sorting && default sorting
  let sorting = data.sorting;
  if (!data.sorting || typeof data.sorting.key === 'undefined') {
    sorting = { key : 'title', direction: 'ASC' };
  }


  // filter 
  const filterComponent = (
    <>
      <Input 
        iconPosition = 'left' 
        style = {{background: 'white', borderRadius: '5px', width: '500px'}}
        placeholder = {t('corrector:PROJECTS.Suche')} 
        onChange = {(e, {value})=>dispatch(setFilter({domain: domain, key: 'title', value: value}))}
        onKeyUp = {(e)=>{
          if (e.keyCode === 39) {
            dispatch(setFilter({domain: domain, key: 'title', value: filter_title_suggestion}))
          }
        }}>
        <Icon name='search' />
        <input type="text" value={filter_title_suggestion} style={{background: 'transparent', position: 'absolute', left: '0px', width: '100%', color: '#bbbbbb'}} readOnly/>
        <input placeholder={t('corrector:PROJECTS.Suche')} type="text" value={filter_title_value} style={{background: 'transparent', zIndex: '2'}} />
      </Input>

      <Image className='refresh' title={t('corrector:PROJECTS.Aktualisieren')} src='/media/icons/WE_correct_icon_aktualisieren.svg' onClick={onReload} />

      <Label as='a' className={sorting !== null && sorting.key === 'title' && ('selected')} onClick={()=>dispatch(setSorting({domain: domain, key: 'title'}))}>
        {sorting !== null && sorting.key === 'title' && sorting.direction === 'ASC' && (<Icon name='arrow up' />)}
        {sorting !== null && sorting.key === 'title' && sorting.direction === 'DESC' && (<Icon name='arrow down' />)}
        {t('corrector:PROJECTS.Name')}
      </Label>
      <Label as='a' className={sorting !== null && sorting.key === 'changed' && ('selected')} onClick={()=>dispatch(setSorting({domain: domain, key: 'changed'}))}>
        {sorting !== null && sorting.key === 'changed' && sorting.direction === 'ASC' && (<Icon name='arrow up' />)}
        {sorting !== null && sorting.key === 'changed' && sorting.direction === 'DESC' && (<Icon name='arrow down' />)}
        {t('corrector:PROJECTS.Letzte Änderung')}
      </Label>
      <Label as='a' className={sorting !== null && sorting.key === 'created' && ('selected')} onClick={()=>dispatch(setSorting({domain: domain, key: 'created'}))}>
        {sorting !== null && sorting.key === 'created' && sorting.direction === 'ASC' && (<Icon name='arrow up' />)}
        {sorting !== null && sorting.key === 'created' && sorting.direction === 'DESC' && (<Icon name='arrow down' />)}
        {t('corrector:PROJECTS.Erstellung')}
      </Label>
      {typeof archive !== 'undefined' && archive && (
        <Label as='a' className={sorting !== null && sorting.key === 'archived' && ('selected')} onClick={()=>dispatch(setSorting({domain: domain, key: 'archived'}))}>
          {sorting !== null && sorting.key === 'archived' && sorting.direction === 'ASC' && (<Icon name='arrow up' />)}
          {sorting !== null && sorting.key === 'archived' && sorting.direction === 'DESC' && (<Icon name='arrow down' />)}
          {t('corrector:PROJECTS.Archiviert')}
        </Label>
      )}

      {!archive && permission&& (<Button icon primary labelPosition='left' floated='right' onClick={onNew}>{t('corrector:PROJECTS.Neues Projekt anlegen')}<Icon name='add' /></Button>)}
      {!archive && (<div className='verticalDivider'><div></div></div>)}
      {listStyle === 'cards' && (<Image className='listStyle' title={t('corrector:PROJECTS.Listenansicht')} src='/media/icons/WE_correct_icon_ansicht-liste.svg' onClick={setTableStyle} />)}
      {listStyle === 'table' && (<Image className='listStyle' title={t('corrector:PROJECTS.Kachelansicht')} src='/media/icons/WE_correct_icon_ansicht-kacheln.svg' onClick={setCardsStyle} />)}
      {typeof archive !== 'undefined' && archive && (
        <div class='usedTags'>
          {usedTags.map((tag, i) => {
            if (i < 10) {
              return (
                <Label as='a' key={i} className="hashtag" size='mini' icon='hashtag' content={tag.tag.name} onClick={()=>dispatch(setFilter({domain: domain, key: 'title', value: tag.tag.name, quick: true}))} />
              );
            }
            return <></>
          })}
        </div>
      )}
    </>
  );

  // cards template
  if (listStyle === 'cards') {
    return (
      <Container className='wec_corrector_projects'>
        {filterComponent}
        <Divider />
        <div className="resultList" domain={domain}>
          <Card.Group>{WECProjectCards}</Card.Group>
        </div>
      </Container>
    );
  }

  // table template
  if (listStyle === 'table') {
    return (
      <Container className='wec_corrector_projects'>
        {filterComponent}
        <Divider />
        <div className="resultList" domain={domain}>
          <Table className='result' fixed>
            <Table.Body>
              {WECProjectRows}
            </Table.Body>
          </Table>
        </div>
      </Container>
    );
  }
}



/**
 * 
 * @param {Array} domains 
 * @param {Object} domains 
 * @param {String} listStyle
 * @param {Boolean} archive
 * @param {Boolean} permission
 */
const WECProjects = ({domains, config, listStyle, archive, permission}) => {
  // redux
  const dispatch  = useDispatch();
  const baseProtocol  = useSelector(selectBaseProtocol);
  const backendUrl    = useSelector(selectBackendUrl);
  const token         = useSelector(selectToken);
  const activeDomain  = useSelector(getActiveDomain);
  const l_user        = useSelector(selectUser);
  
  // check if active domain given
  if (!activeDomain.domain) {
    if (config.domain) {
      domains.forEach(element => {
        if (element.id === config.domain) {
          dispatch(setActive(element));
        }
      });
    } else {
      dispatch(setActive(domains[0]));
    }
  }

  // autostart load projects
  useEffect(() => {
    if (!activeDomain.projects && activeDomain.domain && activeDomain.domain.id) {
      let options = {
        domain: activeDomain.domain.id, 
        filter: {
          active: typeof archive !== 'undefined' && archive ? false : true
        },
        archive: archive
      };
      if (activeDomain.filter) {
        for (const key in activeDomain.filter) {
          options.filter[key] = activeDomain.filter[key];
        }
      }
      if (activeDomain.sorting) {
        options.sorting = activeDomain.sorting;
      }
      const url = baseProtocol + '//' + backendUrl + '/api/latest/'+activeDomain.domain.id.toLowerCase()+'/projects/?options=' + btoa( unescape(encodeURIComponent(JSON.stringify(options)))).replace(/=/g, '_');
      asyncFetchGET(url, token).then(
        (projects) => dispatch(setProjects({domain: activeDomain.domain.id, projects: projects}))
      );
    }
  });

  // determine most used project tags
  let used_tags = {};
  let used_tags_sorted = [];
  if (typeof activeDomain.projects !== 'undefined' && activeDomain.projects) {
    activeDomain.projects.forEach(project => {
      if (typeof project.tags !== 'undefined') {
        project.tags.forEach(tag => {
          if (typeof used_tags[tag.id] === 'undefined') {
            used_tags[tag.id] = {cnt: 0, tag: tag}
          }
          used_tags[tag.id].cnt++;
        });
      }
    });
    for (const key in used_tags) {
      const tag = used_tags[key];
      used_tags_sorted.push(tag);
    }
    used_tags_sorted.sort(function (a, b) {
      return b.cnt - a.cnt;
    });
  }

  // List style
  const resultListSyle = activeDomain.listStyle ? activeDomain.listStyle : listStyle;

  return (
    <React.Fragment>
      {activeDomain.domain && (
        <WECProjectsList 
          title = {activeDomain.domain.name}
          domain = {activeDomain.domain.id}
          data = {activeDomain}
          tags = {config[config.domain].user.tags}
          usedTags = {used_tags_sorted} 
          listStyle = {resultListSyle}
          archive = {archive}
          permission = {permission}
          onReload = {()=>dispatch(setProjects({domain: activeDomain.domain.id, projects: null}))}
          onNew = {()=>dispatch(showDialog({domain: activeDomain.domain.id}))}
          setTableStyle = {()=>{ dispatch(setListStyle({domain: activeDomain.domain.id, style: 'table'})); dispatch(saveSetting(l_user.id, {resultListStyle: {type: 'resultListStyle', name: 'Darstellung Projektliste', value: 'table'}})) }}
          setCardsStyle = {()=>{ dispatch(setListStyle({domain: activeDomain.domain.id, style: 'cards'})); dispatch(saveSetting(l_user.id, {resultListStyle: {type: 'resultListStyle', name: 'Darstellung Projektliste', value: 'cards'}})) }}
        />
      )}
    </React.Fragment>
  );
}

export default WECProjects;