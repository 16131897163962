import { createSlice } from '@reduxjs/toolkit';



/**
 * 
 */
export const attendeesSlice = createSlice({
  name: 'attendees',
  initialState: {
    attendees: {},
    attendeesSearchResult: [],
    role: null,
    term: ''
  },

  reducers: {
    setAttendees: (state, action) => { 
      state.attendees = action.payload;
      state.attendeesSearchResult = [];
    },
    setAttendeesSearchResult: (state, action) => { 
      state.attendeesSearchResult = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload
    },
    setTerm: (state, action) => {
      state.term = action.payload
    },
    abortAttendees: (state) => {
      state.attendeesSearchResult = [];
      state.term = '';
      state.role = null;
    }
  },
});

export const { setAttendeesSearchResult, setAttendees, abortAttendees, setRole, setTerm } = attendeesSlice.actions;

export const attendeesSearchResult = (state) => state.attendees.attendeesSearchResult;
export const selectAttendees = (state) => state.attendees.attendees;
export const selectRole = (state) => state.attendees.role;
export const selectTerm = (state) => state.attendees.term;


export default attendeesSlice.reducer;




/**
 * 
 * @param {Array} attendees 
 * @returns 
 */
 export function removeAttendee(id) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const added = state.attendees.attendees;
      let new_attendees = {};

      for (const key1 in added) {
        if (typeof new_attendees[key1] === 'undefined') {
          new_attendees[key1] = []
        }
        for (const key2 in added[key1]) {
          if (added[key1][key2].id !== id) {
            new_attendees[key1].push(added[key1][key2]);
          }
        }
      }
      
      dispatch(setAttendees(new_attendees));
    } catch (error) {
      console.error(error);
    }
  }
}



/**
 * 
 * @param {Array} attendees
 * @param {Integer} roleId
 * @returns 
 */
export function addAttendees(attendees, roleId) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const localDomain  = state.wec.config.domain
      const masterDomain = state.wec.config.masterDomain;
      const users   = state.wec.config[state.wec.config.domain].user.users;
      const usersM  = state.wec.config[masterDomain].user.users;
      const added   = state.attendees.attendees;
      let new_attendees = Object.assign({}, state.attendees.attendees);

      // reset search field
      dispatch(setTerm(''));

      // User und Teams der gewählten Rolle hinzufügen
      attendees.forEach(item => {
        if (item.type === 'user') {
          if (typeof new_attendees[roleId] === 'undefined') {
            new_attendees[roleId] = [];
          }
          users.forEach(user => {
            let found = false;
            for (const key in added) {
              added[key].forEach((addedUser, found) => {
                if (addedUser.id === user.id) {
                  found = true;
                }
              });
            };
            for (const key in new_attendees) {
              new_attendees[key].forEach((addedUser, found) => {
                if (addedUser.id === user.id) {
                  found = true;
                }
              });
            };

            if (item.id === user.id && !found) {
              new_attendees[roleId] = Object.assign([], new_attendees[roleId]);
              new_attendees[roleId].push({
                id: 'ADD_'+Math.round(+new Date()/1000),
                userid: user.id,
                role: roleId,
                domain: user.domain,
                details: user.details
              });
            }
          });

          if (localDomain !== masterDomain) {
            usersM.forEach(user => {
              let found = false;
              for (const key in added) {
                added[key].forEach((addedUser, found) => {
                  if (addedUser.id === user.id) {
                    found = true;
                  }
                });
              };
              for (const key in new_attendees) {
                new_attendees[key].forEach((addedUser, found) => {
                  if (addedUser.id === user.id) {
                    found = true;
                  }
                });
              };

              if (item.id === user.id && !found) {
                new_attendees[roleId] = Object.assign([], new_attendees[roleId]);
                new_attendees[roleId].push({
                  id: 'ADD_'+Math.round(+new Date()/1000),
                  userid: user.id,
                  role: roleId,
                  domain: user.domain,
                  details: user.details
                });
              }
            });
          }
        }

        // alle Benutzer der gefundenen Teams holen
        // und entsprechend der gewählten Rolle dem Projekt hinzufügen
        if (item.type === 'team') {
          users.forEach(user => {
            if (typeof user.teams !== 'undefined') {
              user.teams.forEach(team => {
                if (team.id === item.id) {
                  if (typeof new_attendees[roleId] === 'undefined') {
                    new_attendees[roleId] = [];
                  }
                  let found = false;
                  for (const key in added) {
                    added[key].forEach((addedUser, found) => {
                      if (addedUser.id === user.id) {
                        found = true;
                      }
                    });
                  };
                  for (const key in new_attendees) {
                    new_attendees[key].forEach((addedUser, found) => {
                      if (addedUser.id === user.id) {
                        found = true;
                      }
                    });
                  };
                  if (!found) {
                    new_attendees[roleId].push({
                      id: 'ADD_'+Math.round(+new Date()/1000),
                      userid: user.id,
                      role: roleId,
                      domain: user.domain,
                      details: user.details
                    });
                  }
                }
              })  
            }
          })
        }
      });

      //
      dispatch(setAttendees(new_attendees));

    } catch (error) {
      console.error(error);
    }
  }
}



/**
 * 
 * @param {String} searchString Suchwort
 * @returns 
 */
export function searchAttendees(searchString) {
  return async (dispatch, getState) => {
    try {
      // 
      const state = getState();
      const localDomain  = state.wec.config.domain
      const masterDomain = state.wec.config.masterDomain;
      const added   = state.attendees.attendees;
      const users   = state.wec.config[state.wec.config.domain].user.users;
      const teams   = state.wec.config[state.wec.config.domain].user.teams;
      const usersM  = state.wec.config[masterDomain].user.users;
      const teamsM  = state.wec.config[masterDomain].user.teams;
      let result = [];
      
      //
      const myReg = new RegExp(searchString, 'gi')
      
      // search users
      users.forEach(user => {
        let found = false;

        // filter user list
        if (user.username.search(myReg) !== -1) {
          result.push({
            type: 'user',
            domain: user.domain,  
            id: user.id,
            name: user.details.forename + " " + user.details.surname + ' (' + user.username + ')'
          });
          found = true;
        }

        // add user to search result
        if (!found && typeof user.details !== 'undefined') {
          for (const key in user.details) {
            if (Object.hasOwnProperty.call(user.details, key)) {
              const element = user.details[key];
              if (!found && element != null) {
                if (element.search(myReg) !== -1) {

                  // check if user already added 
                  for (const key in added) {
                    added[key].forEach((attendee, found) => {
                      if (attendee.id === user.id) {
                        found = true;
                      }
                    });
                  };

                  // add to search result
                  if (!found) {
                    result.push({ type: 'user', domain: user.domain, id: user.id, name: user.details.forename + " " + user.details.surname + ' (' + user.username + ')' });
                    found = true;
                  }
                }
              }
            }
          }
        }
      });

      // search teams
      if (typeof teams !== 'undefined' && teams) {
        teams.forEach(team => {
          if (team.name.search(myReg) !== -1 && team.type.name !== 'System') {
            result.push({ type: 'team', domain: team.domain, id: team.id, name: team.name });
          }
        });
      }

      // search users from master domain
      if (localDomain !== masterDomain) {
        usersM.forEach(user => {
          if (user.type === 1 || user.type === 2) {
            let found = false;

            // filter user list
            if (user.username.search(myReg) !== -1) {
              result = Object.assign([], result);
              result.push({
                type: 'user',
                domain: user.domain, 
                id: user.id,
                name: user.details.forename + " " + user.details.surname + ' (' + user.username + ')'
              });
              found = true;
            }

            // add user to search result
            if (!found && typeof user.details !== 'undefined') {
              for (const key in user.details) {
                if (Object.hasOwnProperty.call(user.details, key)) {
                  const element = user.details[key];
                  if (!found && element != null) {
                    if (element.search(myReg) !== -1) {

                      // check if user already added 
                      for (const key in added) {
                        added[key].forEach((attendee, found) => {
                          if (attendee.id === user.id) {
                            found = true;
                          }
                        });
                      };

                      // add to search result
                      if (!found) {
                        result = Object.assign([], result);
                        result.push({ type: 'user', domain: user.domain, id: user.id, name: user.details.forename + " " + user.details.surname + ' (' + user.username + ')' });
                        found = true;
                      }
                    }
                  }
                }
              }
            }
          }
        });

        // search teams from master domain
        if (typeof teamsM !== 'undefined' && teams) {
          teams.forEach(team => {
            if (team.type.id === 1 || team.type.id === 2) {
              if (team.name.search(myReg) !== -1 && team.type.name !== 'System') {
                result = Object.assign([], result);
                result.push({ type: 'team', domain: team.domain, id: team.id, name: team.name });
              }
            }
          });
        }
      }
      
      // apply search result to store
      dispatch(setAttendeesSearchResult(result));
    } catch (error) {
      console.error(error);
    }
  }
}