import { createSlice } from '@reduxjs/toolkit';
import { fetchDELETE } from "../toolbox/requestor.slice";
import { addMessage } from '../snackbar/snackbar.slice'




export const teamsSlice = createSlice({
  name: 'teams',
  initialState: {
    visible: false,
    teams: null,
    filter: null
  },

  reducers: {
    showTeams: (state) => { 
      state.visible = true;
    },
    hideTeams: (state) => {
      state.visible = false;
      state.teams = null;
    },
    setTeams: (state, actions) => {
      state.teams = actions.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    }
  }
});
export const { showTeams, hideTeams, setTeams, setFilter } = teamsSlice.actions;
export const isVisible = (state) => state.teams.visible;
export const getTeams = (state) => state.teams.teams;
export const getFilter = (state) => state.teams.filter;

export default teamsSlice.reducer;




export function deleteTeam(team) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const authUrl = state.wec.baseProtocol + '//' + state.wec.authUrl;
      if (typeof team.id !== 'undefined' && team.id) {
        dispatch(fetchDELETE(authUrl + '/api/team/'+team.id)).then(
          team => {
            dispatch(addMessage({type: 'NOTICE', text: 'Team ' + team.name + ' wurde gelöscht'}));
            dispatch(setTeams(null))
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
}