// react base
import React, { useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Modal, Header, Button, Icon, Input, Form, Divider } from 'semantic-ui-react';

// Redux
import { selectBaseProtocol, selectAuthUrl, selectBaseUrl } from '../../WECorrect.slice';
import { fetchPOST } from '../toolbox/requestor.slice';

// Styles
import './lostpw.css';




/**
 *
 */
const WECLostPW = ({onClose}) => {
    // local state
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');

    // global state
    const dispatch      = useDispatch();
    const baseUrl       = useSelector(selectBaseUrl);
    const baseProtocol  = useSelector(selectBaseProtocol);
    const authUrl = baseProtocol + '//' + useSelector(selectAuthUrl);

    // translation
    const { t } = useTranslation('user');



    //
    const resetPW = () => {
        const data = {
            username: username,
            email: email,
            url: baseUrl+'/resetpw?token=[TOKEN]'
        };

        dispatch(fetchPOST(authUrl + "/api/login/resetpw", data)).then(
            (resetpwToken) => {
                console.log('[resetpwToken]', resetpwToken)
                onClose();
            }
        );
    }

    return (
        <Modal
            open = {true}
            className = 'weclostpw'
            size = 'small'
            closeIcon 
            closeOnEscape = {false} 
            closeOnDimmerClick = {false}
            onClose = {() => onClose()}
        >
            <React.Fragment>
                <Modal.Content>
                    <Header>{t('user:LOSTPW.Zugangsdaten vergessen')}</Header>
                    <Form>
                        <Form.Field>
                            <label>{t('user:LOSTPW.Username')}</label>
                            <Input size="mini" value={username} placeholder={t('user:LOSTPW.Username')} onChange={(event)=>setUsername(event.target.value)} />
                        </Form.Field>
                        <Form.Field>
                            <Divider horizontal>{t('user:LOSTPW.Oder')} </Divider>
                        </Form.Field>
                        <Form.Field>
                            <label>{t('user:LOSTPW.E-Mail')}</label>
                            <Input size="mini" placeholder={t('user:LOSTPW.E-Mail')} value={email} onChange={(event)=>setEmail(event.target.value)} />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button icon labelPosition='left' onClick={()=>onClose()}><Icon name='times' />{t('user:LOSTPW.Schließen')}</Button>
                    <Button primary icon labelPosition='left' onClick={()=>{
                        resetPW();
                    }}><Icon name='plus' />{t('user:LOSTPW.Passwort zurücksetzen')}</Button>
                </Modal.Actions>
            </React.Fragment>
        </Modal>
    );
}

export default WECLostPW;