// react base
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns/esm'
import Parser from 'html-react-parser';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { isDomains, isNews, toggleDomains, toggleNews } from './dashboard.slice';
import { selectBaseProtocol, selectAuthUrl, getConfig } from '../../WECorrect.slice';
import { asyncFetchGET } from '../toolbox/requestor.slice';
import { selectUser, selectToken } from '../login/login.slice';
import { readMessage, showMessageSingle, unreadMessage } from '../message/message.slice';
import { getActiveDomain, setActive } from '../corrector/projects.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// App
import WECProjects from "../corrector/projects";

// semantic ui
import { Comment, Container, Divider, Header, Icon, Image } from 'semantic-ui-react'

// Styles
import './dashboard.css';



/**
 * 
 * @param {Array} domains 
 * @param {Object} activeDomain
 * @param {Function} onSelectDomain
 * @returns 
 */
const WECDomains = ({domains, activeDomain, onSelectDomain}) => {
    // translations
    const { t } = useTranslation('dashboard', 'common');

    const domainList = domains.map((domain) => {
        if (activeDomain && typeof activeDomain.id !== 'undefined' && domain.id === activeDomain.id) {
            return (
                <Image className='active' as='a' title={domain.name} src={'/media/logos/domain_' + domain.id + '.png'} onClick={()=>onSelectDomain(domain)} />
            )
        } else {
            return (
                <Image as='a' title={domain.name} src={'/media/logos/domain_' + domain.id + '.png'} onClick={()=>onSelectDomain(domain)} />
            )
        }
    });

    return (
        <>
            <Header as='h3'>{t('dashboard:LABELS.Kunden')}</Header>
            <Divider />
            <Container className='List'>
                {domainList}
            </Container>
        </>
    )
}




/**
 * 
 * @returns 
 */
 const WECNews = () => {
     // local state
    const [messages, setMessages] = useState(null);
    const [buttonsVisible, setButtonsVisible] = useState(false);

    // global state
    const dispatch = useDispatch();
    const baseProtocol = useSelector(selectBaseProtocol);
    const authUrl = useSelector(selectAuthUrl);
    const token = useSelector(selectToken);
    const user = useSelector(selectUser);

    // translations
    const { t } = useTranslation('dashboard', 'common');

    // autostart load messages
    useEffect(() => {
        if (!messages) {
            const url = baseProtocol + '//' + authUrl + '/api/messages/user/' + user.id;
            asyncFetchGET(url, token).then(
                (response) => setMessages(response)
            );
        }
    });

    //
    const messageItems = !messages ? <></> : messages.map((message, i) => {
        // format date
        message.received.fdate = format(new Date(message.received.date), "dd.MM.yyyy, HH:mm");

        // buttons visible?
        const visibleStyle = buttonsVisible === message.id ? {} : {display:'none'}

        // flags
        const unread = message.flags.includes('U') ? true : false;
        if (!unread) {
            return <></>;
        }

        // Parsed html message content
        const parsedContent = Parser(message.content);

        return (
            <>
            <Comment key={i} id = {'message_' + message.id} onMouseOver={()=>setButtonsVisible(message.id)} onMouseOut={()=>setButtonsVisible(false)}>
                <Comment.Content>
                    <Comment.Author as='a'>{message.subject}</Comment.Author>
                    <Comment.Metadata className='state'>
                        {unread && (<Icon link title={t('dashboard:LABELS.Als gelesen markieren')} size="large"  name='envelope outline' onClick={()=>dispatch(readMessage(message.id, ()=>{setMessages(null)}))} />)}
                        {!unread && (<Icon link title={t('dashboard:LABELS.Als ungelesen markieren')} size="large" name='envelope open outline' onClick={()=>dispatch(unreadMessage(message.id, ()=>{setMessages(null)}))} />)}
                    </Comment.Metadata>
                    <Comment.Metadata className='from'><span>von: {message.sender.forename} {message.sender.surname} am: {message.received.fdate}</span></Comment.Metadata>
                    <Comment.Text>{parsedContent}</Comment.Text>
                    <React.Fragment>
                        <Comment.Actions>
                        <Icon link title='Antworten' name='reply' onClick={()=>{dispatch(showMessageSingle(message.sender))}} style={visibleStyle} />
                        </Comment.Actions>
                    </React.Fragment>
                </Comment.Content>
            </Comment>
            <Divider />
            </>
        );
    });

    return (
        <>
            <Header as='h3' style={{float: 'left'}}>{t('dashboard:LABELS.Neuigkeiten')}</Header>
            <Image className='refresh' title={t('dashboard:LABELS.Aktualisieren')} src='/media/icons/WE_correct_icon_aktualisieren.svg' onClick={()=>setMessages(null)} />
            <Divider  style={{clear: 'both'}}/>
            <Container className='List'>
                <Comment.Group threaded>
                    {messageItems}
                </Comment.Group>
                <span>more coming soon...</span>
            </Container>
        </>
    )
}






/**
 * 
 */
const WECDashboard = ({config}) => {    
    const dispatch = useDispatch();
    const _isDomains = useSelector(isDomains)
    const _isNews = useSelector(isNews)
    const l_user = useSelector(selectUser);
    const activeDomain = useSelector(getActiveDomain);

    // translations
    const { t } = useTranslation('dashboard', 'common');

    // Permissions
    let permissionHandler = new WECPermissionHandler();
    const permission_PERM_DSH_01 = permissionHandler.hasPermission(l_user, 'PERM_DSH_01') && (l_user.type === 1 || l_user.type === 2);
    const permission_PERM_DSH_02 = permissionHandler.hasPermission(l_user, 'PERM_DSH_02') && (l_user.type === 1 || l_user.type === 2);

    // Resultlist style 
    const resultListSyle = typeof l_user.settings !== 'undefined' && l_user.settings && typeof l_user.settings.resultListStyle !== 'undefined' ? l_user.settings.resultListStyle.value : 'cards';

    //
    let domains = [];
    if (config && typeof config.domains !== 'undefined') {
        const _domains = Object.assign([], config.domains);
        _domains.forEach(domain => {
            if (typeof config[domain] !== 'undefined') {
                domains.push(Object.assign({id: domain}, config[domain]));
            }
        });
    }

    // check visibility of sidebars
    const hideDomains = _isDomains ? {} : {display: 'none'};
    const hiderDomains = _isDomains ? 'links' : 'rechts';
    const hiderDomainsTT = _isDomains ? t('dashboard:BUTTONS.Kundenliste ausblenden') : t('dashboard:BUTTONS.Kundenliste einblenden');
    const hideNews = _isNews ? {} : {display: 'none'};
    const hiderNews = _isNews ? 'rechts' : 'links';
    const hiderNewsTT = _isNews ? t('dashboard:BUTTONS.Neuigkeiten ausblenden') : t('dashboard:BUTTONS.Neuigkeiten einblenden');

    // editor size
    let contentSize = 'small'
    if ( _isDomains &&  _isNews) contentSize = 'small';
    if (!_isDomains &&  _isNews) contentSize = 'medium1';
    if ( _isDomains && !_isNews) contentSize = 'medium2';
    if (!_isDomains && !_isNews) contentSize = 'large';

    return (
        <div className='wec_dashboard'>
            <div className='Domains' style={hideDomains}>
                {permission_PERM_DSH_02 && (
                    <WECDomains 
                        domains = {domains} 
                        activeDomain = {activeDomain.domain}
                        onSelectDomain = {(domain)=>{
                            dispatch(getConfig(domain.id));
                            dispatch(setActive(domain))
                        }}
                    />
                )}
            </div>
            <div class="HiderLeft">
                {permission_PERM_DSH_02 && (
                    <Image src={'/media/icons/WE_correct_icon_chevron-' + hiderDomains + '.svg'} title={hiderDomainsTT} onClick={()=>dispatch(toggleDomains())} />
                )}
            </div>
            <div className={'DashboardContent ' + contentSize}>
                <WECProjects domains={domains} config={config} listStyle={resultListSyle} permission={permission_PERM_DSH_01} />
            </div>
            <div class="HiderRight">
                <Image src={'/media/icons/WE_correct_icon_chevron-' + hiderNews + '.svg'} title={hiderNewsTT} onClick={()=>dispatch(toggleNews())} />
            </div>
            <div className='News' style={hideNews}>
                <WECNews />
            </div>
        </div>
    );
}

export default WECDashboard;