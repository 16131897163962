// react base
import React, { useState }from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { compareMode, setMousePosition, checkDeselect, resizeRect, isDrawRect, scale, correctionMode, addMode, compare, setScale, setCompareMode } from './editor.slice';
import { corrections, selectedCorrection, setComparedPage, selectCorrection, unselectCorrection } from './editor.slice';
import { showDialog } from './dialogCompare.slice'

import { dataPage, dataCorrectionLevel, dataProject } from '../corrector.slice';
import { selectBaseProtocol, selectBackendUrl, selectConfig } from '../../../WECorrect.slice';

// Permissions
import WECPermissionHandler from '../../toolbox/permission_handler';

// semantic ui
import { Button, Icon, Image, Input, Table } from 'semantic-ui-react';

// Konvajs
import { Stage, Layer } from 'react-konva';

// App
import WECCorrectorEditorPageImage from "./pageImage"
import WECCorrectorEditorRectangle from "./rectangle"
import WECCorrectorEditorIcon from "./icon"
import WECCorrectorEditorMenu from "./menu"

// Styles
import './editor.css';
import { selectUser } from '../../login/login.slice';



/**
 * 
 * @param {Boolean} readOnly 
 * @returns 
 */
const WECCorrectorEditorCompare = ({readOnly}) => {
  // local state
  const [pnvalue, setPnvalue] = useState(null);
  const [lCompare, setLCompare] = useState(null);
  const [dragPage, setDragPage] = useState(false);
  const [dragStart, setDragStart] = useState(null);
  const [dragPageC, setDragPageC] = useState(false);
  const [dragStartC, setDragStartC] = useState(null);

  // global state
  const dispatch = useDispatch();
  const selectedId              = useSelector(selectedCorrection);
  const initialScale            = useSelector(scale);
  const c_addMode               = useSelector(addMode);
  const e_compareMode           = useSelector(compareMode)
  const e_correctionMode        = useSelector(correctionMode);
  const c_project               = useSelector(dataProject);
  const c_correctionLevel       = useSelector(dataCorrectionLevel);
  const c_dataPage              = useSelector(dataPage);
  const c_selectBaseProtocol    = useSelector(selectBaseProtocol);
  const s_selectBackendUrl      = useSelector(selectBackendUrl);
  const e_corrections           = useSelector(corrections);
  const e_isDrawRect            = useSelector(isDrawRect);
  const e_compare               = useSelector(compare);
  const _config                 = useSelector(selectConfig);
  const userLoggedIn            = useSelector(selectUser);
  
  // 
  let c_dataPageCompare = null;
  let e_correctionsCompare = null;

  // React Component References
  const stageRef = React.useRef();

  // translation
  const { t } = useTranslation('corrector');

  // navigation
  let pageNavInputVal = ''; 
  let pageNavInputValFirst = 0;
  let pageNavInputValPrev = 0;
  let pageNavInputValNext = 0;
  let pageNavInputValLast = 0;
  if (e_compare.pageKey !== null) {
    c_dataPageCompare = e_compare.correctionLevel.pages[e_compare.pageKey];
    e_correctionsCompare = e_compare.corrections;
    pageNavInputVal = (e_compare.pageKey + 1) + ' von ' + e_compare.correctionLevel.pages.length;
    pageNavInputValFirst = e_compare.correctionLevel.pages[0].id;
    pageNavInputValPrev = e_compare.pageKey > 0 ? e_compare.correctionLevel.pages[e_compare.pageKey - 1].id : e_compare.correctionLevel.pages[0].id;
    pageNavInputValNext = e_compare.pageKey < (e_compare.correctionLevel.pages.length-1) ? e_compare.correctionLevel.pages[e_compare.pageKey + 1].id : e_compare.correctionLevel.pages[e_compare.correctionLevel.pages.length-1].id;
    pageNavInputValLast = e_compare.correctionLevel.pages[e_compare.correctionLevel.pages.length-1].id;

    if (!pnvalue) {
      setPnvalue(pageNavInputVal);
      setLCompare(e_compare);
    }
  }

  // build up tools list
  let tools = {}
  _config[_config.domain].toolbar.tool.tools.forEach(tool => {
    tools[tool[0]] = {
      id: tool[0],
      name: tool[1],
      icon: tool[2],
      text: tool[3]
    }
  });

  // Permissions
  let permissionHandler = new WECPermissionHandler();
  const permission_PERM_EDT_05 = permissionHandler.hasPermission(userLoggedIn, 'PERM_EDT_05', c_project.attendees, _config[_config.domain].user.roles) && userLoggedIn.type !== 4;

  // checks if page given  
  if (typeof c_dataPage === 'undefined' || c_dataPage === null) {
    return (<></>);
  }

  //
  const handleFocus = (event) => {
    event.target.select();
  }
  const handleChance = (event, {value}, ) => {
    if (!isNaN(value)) {
      if (value >= 0 && value <= (lCompare.correctionLevel.pages.length - 1)) {
        setPnvalue(value);
      }
    }
  }
  const handleBlur = (event) => {
    dispatch(setComparedPage(lCompare.correctionLevel.pages[pnvalue-1].id))
    setPnvalue(null);
  }

  return (
    <div className='corrector_editor'>
      <div className='corrector_editor_canvashl'>
        <div className='Menu'>
          {e_compareMode === 'compare' && (<WECCorrectorEditorMenu readOnly={readOnly} compare='compare' />)}
          {e_compareMode === 'normal' && (<WECCorrectorEditorMenu readOnly={readOnly} />)}
        </div>
      </div>
      <div className='corrector_editor_canvashr'>
        <Table basic='very'>
          <Table.Body>
            <Table.Row>
              <Table.Cell textAlign='left' rowSpan="2">
                <div className='stufe'>
                  <span className='stufe_head'>Stufe</span>
                  <span className='stufe_value'>{e_compare.correctionLevel.counter}</span>
                </div>
              </Table.Cell>
              <Table.Cell textAlign='left' colSpan="2">
                <p>{e_compare.project.title}</p>
              </Table.Cell>
              <Table.Cell textAlign='right'>
                <Icon link name='close' title={t('corrector:EDITOR_COMPARE.Schließen')} onClick={()=>{dispatch(setScale(100)); dispatch(setCompareMode('normal'));}}></Icon>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              
              <Table.Cell textAlign='center'>
                <Table basic='very' collapsing className='pagenav'>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell textAlign='center'>
                        <Image src='/media/icons/WE_correct_icon_last_page.svg' className='icon_mini' title={t('corrector:EDITOR_COMPARE.erste Seite')} style={{transform: 'rotate(180deg)'}} onClick={()=>{setPnvalue(null); dispatch(setComparedPage(pageNavInputValFirst))}}/>
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Image src='/media/icons/WE_correct_icon_next_page.svg' className='icon_mini' title={t('corrector:EDITOR_COMPARE.vorherige Seite')} style={{transform: 'rotate(180deg)'}} onClick={()=>{setPnvalue(null); dispatch(setComparedPage(pageNavInputValPrev))}}/>
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Input size='mini' value={pnvalue} style={{width:'75px'}} onFocus={handleFocus} onBlur={handleBlur} onChange={handleChance}/>
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Image src='/media/icons/WE_correct_icon_next_page.svg' className='icon_mini' title={t('corrector:EDITOR_COMPARE.nächste Seite')} onClick={()=>{setPnvalue(null); dispatch(setComparedPage(pageNavInputValNext))}}/>
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Image src='/media/icons/WE_correct_icon_last_page.svg' className='icon_mini' title={t('corrector:EDITOR_COMPARE.letzte Seite')} onClick={()=>{setPnvalue(null); dispatch(setComparedPage(pageNavInputValLast))}}/>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Table.Cell>
              <Table.Cell textAlign='center' colSpan="2">
                <Button icon size='mini' labelPosition='left' onClick={()=>dispatch(showDialog({domain: c_project.domain}))}><Icon name='wrench' />{t('corrector:EDITOR_COMPARE.Vergleichsoptionen')}</Button>    
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <div id='corrector_editor_canvasl' className='corrector_editor_canvasl'>
        <Stage 
            ref = {stageRef}
            width = {c_dataPage.width * initialScale} 
            height = {c_dataPage.height * initialScale}
            onMouseDown = {(event) => {              
              dispatch(checkDeselect(event))

              //start scoll
              if (!dragPage) {
                setDragStart({ x: event.evt.offsetX, y: event.evt.offsetY })
              }
              setDragPage(true);
            }}
            onMouseUp = {(event) => {
              // end scroll
              if (dragPage && dragStart) {
                setDragStart(null);
                setDragPage(false);
              }
            }}
            onMouseOut = {(event) => {
              // end scroll
              if (dragPage && dragStart) {
                setDragStart(null);
                setDragPage(false);
              }
            }}
            onMouseMove = { (event) => {
              dispatch(setMousePosition({ x: event.evt.offsetX, y: event.evt.offsetY }))
              if (e_correctionMode && c_addMode === 'rect' && e_isDrawRect && !readOnly) {
                dispatch(resizeRect())
              } else {
                if (dragPage && dragStart) {
                  const canvas = document.querySelector('.corrector_editor_canvasl');
                  canvas.scrollTo({
                    top: ( canvas.scrollTop + (dragStart.y - event.evt.offsetY)),
                    left: ( canvas.scrollLeft + (dragStart.x - event.evt.offsetX)),
                    behavior: "smooth"
                  });
                }
              }
            }}
          >
            <Layer>
              <WECCorrectorEditorPageImage 
                imageProps = {c_dataPage} 
                imageURL = {c_selectBaseProtocol + '//' + s_selectBackendUrl + '/media/projects/' + c_project.domain + '/' + c_project.id + '/' + c_correctionLevel.id + '/pages/'} 
                scale = {initialScale} 
                onClick = {()=>dispatch(unselectCorrection(null))}
              />
              {e_corrections.map((correction, i) => {
                switch (correction.marker.type) {
                  case 'euro':
                    return (
                      <WECCorrectorEditorIcon
                        permissionEdit = {permission_PERM_EDT_05}
                        readOnly={readOnly}
                        key = {i}
                        id = {correction.id}
                        icon = {'/media/tools/' + _config[_config.domain].toolbar.euro.icon}
                        shapeProps = {correction.marker}
                        isSelected = {correction.id === selectedId}
                        isCorrectionMode = {e_correctionMode}
                        isDirty = {false}
                        mode = {c_addMode}
                        correction = {correction}
                        onSelect = {() => {
                          dispatch(selectCorrection(correction.id))
                          const comment = document.querySelector('#comment_' + correction.id);
                          const commentslist = document.querySelector('.commentslist');
                          commentslist.scrollTo({
                            top: ( comment.offsetTop - 190),
                            behavior: "smooth"
                          });
                        }}
                        onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
                        onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
                      />
                    );
                  case 'ok':
                    return (
                      <WECCorrectorEditorIcon
                        readOnly={readOnly}
                        key = {i}
                        id = {correction.id}
                        icon = {'/media/tools/' + _config[_config.domain].toolbar.ok.icon}
                        shapeProps = {correction.marker}
                        isSelected = {correction.id === selectedId}
                        isCorrectionMode = {e_correctionMode}
                        isDirty = {false}
                        mode = {c_addMode}
                        correction = {correction}
                        onSelect = {() => {
                          dispatch(selectCorrection(correction.id))
                          const comment = document.querySelector('#comment_' + correction.id);
                          const commentslist = document.querySelector('.commentslist');
                          commentslist.scrollTo({
                            top: ( comment.offsetTop - 190),
                            behavior: "smooth"
                          });
                        }}
                        onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
                        onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
                      />
                    );
                  case 'tool':
                    const icon = '/media/tools/' +  tools[correction.marker.tool].icon
                    return (
                      <WECCorrectorEditorIcon
                        readOnly={readOnly}
                        key = {i}
                        id = {correction.id}
                        icon = {icon}
                        shapeProps = {correction.marker}
                        isSelected = {correction.id === selectedId}
                        isCorrectionMode = {e_correctionMode}
                        isDirty = {false}
                        mode = {c_addMode}
                        correction = {correction}
                        onSelect = {() => {
                          dispatch(selectCorrection(correction.id))
                          const comment = document.querySelector('#comment_' + correction.id);
                          const commentslist = document.querySelector('.commentslist');
                          commentslist.scrollTo({
                            top: ( comment.offsetTop - 190),
                            behavior: "smooth"
                          });
                        }}
                        onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
                        onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
                      />
                    );
                  default:
                    return (
                      <WECCorrectorEditorRectangle
                        readOnly={readOnly}
                        key = {i}
                        id = {correction.id}
                        idCorrection = {correction.id}
                        shapeProps = {correction.marker}
                        isSelected = {correction.id === selectedId}
                        isCorrectionMode = {e_correctionMode}
                        isDirty = {false}
                        mode = {c_addMode}
                        correction = {correction}
                        onSelect = {() => {
                          dispatch(selectCorrection(correction.id))
                          const comment = document.querySelector('#comment_' + correction.id);
                          const commentslist = document.querySelector('.commentslist');
                          commentslist.scrollTo({
                            top: ( comment.offsetTop - 190),
                            behavior: "smooth"
                          });
                        }}
                        onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
                        onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
                      />
                    );
                }
              })}
            </Layer>
            </Stage>
      </div>
      <div id='corrector_editor_canvasr' className='corrector_editor_canvasr'>
        <Stage 
          width={c_dataPageCompare.width * initialScale} 
          height={c_dataPageCompare.height * initialScale}
          onMouseDown = {(event) => {              
            if (!dragPageC) {
              setDragStartC({ x: event.evt.offsetX, y: event.evt.offsetY })
            }
            setDragPageC(true);
          }}
          onMouseUp = {(event) => {
            // end scroll
            if (dragPageC && dragStartC) {
              setDragStartC(null);
              setDragPageC(false);
            }
          }}
          onMouseOut = {(event) => {
            // end scroll
            if (dragPageC && dragStartC) {
              setDragStartC(null);
              setDragPageC(false);
            }
          }}
          onMouseMove = { (event) => {
            if (dragPageC && dragStartC) {
              const canvas = document.querySelector('.corrector_editor_canvasr');
              canvas.scrollTo({
                top: ( canvas.scrollTop + (dragStartC.y - event.evt.offsetY)),
                left: ( canvas.scrollLeft + (dragStartC.x - event.evt.offsetX)),
                behavior: "smooth"
              });
            }
          }}
        >
          <Layer>
            <WECCorrectorEditorPageImage 
              imageProps = {c_dataPageCompare} 
              imageURL = {c_selectBaseProtocol + '//' + s_selectBackendUrl + '/media/projects/' + c_project.domain + '/' + c_project.id + '/' + e_compare.correctionLevel.id + '/pages/'} 
              scale = {initialScale} 
            />
            {e_correctionsCompare && e_correctionsCompare.map((correction, i) => {
              //const labelVisible = correction.id === e_markerLabelCompareVisible ? true : false;
              switch (correction.marker.type) {
                case 'euro':
                  /*return (
                    <React.Fragment>
                      <WECCorrectorEditorLabel visible={labelVisible} text={correction.comment.content} x={correction.marker.x} y={correction.marker.y} />
                      <TSCorrectorEditorEurosign
                        key = {i}
                        id = {'compare_'+correction.id}
                        shapeProps = {correction.marker}
                        onMouseOver = {()=>dispatch(setMarkerLabelCompareVisible(correction.id))}
                        onMouseOut = {()=>dispatch(setMarkerLabelCompareVisible(null))}
                      />
                    </React.Fragment>
                  );*/
                  break;
                default:
                  /*return (
                    <React.Fragment>
                      <WECCorrectorEditorLabel visible={true} text={correction.comment.content} x={correction.marker.x} y={correction.marker.y} />
                      <WECCorrectorEditorRectangle
                        key = {i}
                        id = {'compare_' + correction.id}
                        shapeProps = {correction.marker}
                        onMouseOver = {()=>dispatch(setMarkerLabelCompareVisible(correction.id))}
                        onMouseOut = {()=>dispatch(setMarkerLabelCompareVisible(null))}
                      />
                    </React.Fragment>
                  );*/
              }
              return <></>
            })}
          </Layer>
        </Stage>
      </div>
    </div>
  );
}

export default WECCorrectorEditorCompare;