import { createSlice } from '@reduxjs/toolkit';
import { fetchGET } from "../toolbox/requestor.slice";


export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    newUserModal: false,
    loading: false,
    showDomains: false,
    showNews: false,
    systemData: { 
      tags: null, 
      teams: null, 
      user: null,
      roles: [
        {id: 'agency', key: 'agency', value: 'agency', text: 'Agentur', title: 'Agentur'},
        {id: 'customer', key: 'customer', value: 'customer', text: 'Kunde', title: 'Netto'},
        {id: 'guest', key: 'guest', value: 'guest', text: 'Gast', title: 'Gast'}
      ]
    },
  },

  reducers: {
    changeSystemData: (state, action) => { 
      state.systemData.user = action.payload.user
      state.systemData.teams = action.payload.teams  
      state.systemData.tags = action.payload.tags
      if (typeof action.payload.hideLoading !== 'undefined' && action.payload.hideLoading) {
        state.loading = false;
      }
    },
    changeLoading: (state, action) => { state.loading = action.payload },
    toggleDomains: (state) => { state.showDomains = !state.showDomains; },
    toggleNews: (state) => { state.showNews = !state.showNews; },
  },
});

export const { changeSystemData, changeLoading, toggleDomains, toggleNews } = dashboardSlice.actions;
export const isLoading = (state) => state.dashboard.loading;
export const isDomains = (state) => state.dashboard.showDomains;
export const isNews = (state) => state.dashboard.showNews;

export default dashboardSlice.reducer;



/**
 * 
 */
export function loadSystemData(props) {
  return async (dispatch, getState) => {
    try {
      //
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      const authUrl = state.wec.baseProtocol + '//' + state.wec.authUrl;
      props.domain = state.wec.mandant;

      // filter options for system data requests
      const options = { filter: { active : true, domain: props.domain }, sorting : { name : 'ASC' }};
      const options2 = { filter: { active : true, domain: props.domain }, sorting : { username : 'ASC' }};

      // 
      dispatch(fetchGET(authUrl + '/api/teams/?options=' + btoa(JSON.stringify(options)).replace(/=/g, '_'))).then(
        teams => {
          dispatch(fetchGET(backendUrl + '/api/latest/'+props.domain+'/tags/?options=' + btoa(JSON.stringify(options)).replace(/=/g, '_'))).then(
            tags => {
              dispatch(fetchGET(authUrl + '/api/users/?options=' + btoa(JSON.stringify(options2)).replace(/=/g, '_'))).then(
                user => {
                  dispatch(changeSystemData({user: user, tags: tags, teams: teams, hideLoading: true}))
                }
              )
            }
          );
        }
      );
    } catch (error) {
      //dispatch(saveUserFailed(error))
    }
  }
}