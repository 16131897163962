import { createSlice } from '@reduxjs/toolkit';
import { fetchGET } from "./components/toolbox/requestor.slice";


export const wecSlice = createSlice({
  name: 'wec',
  initialState: {
    mandant       : 'WSEPIC',
    activeModule  : 'dashboard',
    loading       : true,
    baseUrl       : window.location.origin,
    baseProtocol  : window.location.protocol,
    authUrl       : window.wecorrect.authUrl,
    backendUrl    : window.wecorrect.backendUrl,
    clStageTypes  : {
      '1': {id: 1, title: 'Initialisierung', description: 'Das Projekt wird vorbereitet.'},
      '2': {id: 2, title: 'Korrektur', description: 'Die Abteilungen fügen neue Korrekturen hinzu.'},
      '3': {id: 3, title: 'Qualifizierung', description: 'Die Marketingabteilung qualifiziert die neuen Korrekturen.'},
      '4': {id: 4, title: 'Umsetzung', description: 'Die Grafikabteilung bearbeitet die neuen Korrekturen.'},
      '5': {id: 5, title: 'Freigabe', description: 'Die (Druck-)Freigabe wurde erteilt.'}
    },
    
    config: null,
    tags: null,
    redirected: null
  },

  reducers: {
    setActiveModule:  (state, action) => { state.activeModule = action.payload; },
    setLoading:       (state, action) => { state.loading = action.payload; },
    setConfig:        (state, action) => { state.config = action.payload; },
    getConfigSuccess: (state, action) => { state.config = action.payload.config; state.tags = action.payload.tags; },
    getConfigFailed:  (state, action) => { state.config = null; state.tags = null; },
    setRedirected:    (state, action) => { state.redirected = action.payload; },
  },
});

export const { setActiveModule, setLoading, getConfigSuccess, getConfigFailed, setRedirected } = wecSlice.actions;

export const isLoading             = state => state.wec.loading;
export const selectActiveModule    = state => state.wec.activeModule;
export const selectMandant         = state => state.wec.mandant;
export const selectBaseUrl         = state => state.wec.baseUrl;
export const selectBaseProtocol    = state => state.wec.baseProtocol;
export const selectAuthUrl         = state => state.wec.authUrl;
export const selectBackendUrl      = state => state.wec.backendUrl;
export const selectClStageTypes    = state => state.wec.clStageTypes;
export const selectRedirected      = state => state.wec.redirected;



/**
 * 
 * @param {Object} state 
 * @returns 
 */
export const selectConfig   = (state) => {
  if (!state.wec.config) {
    return null;
  }
  let retval = Object.assign({}, state.wec.config);
  let domain = retval.domain;
  let roles  = Object.assign({}, retval[domain].user.roles);
  let teams  = Object.assign({}, retval[domain].user.teams);
  
  retval[domain] = Object.assign({}, retval[domain]);
  retval[domain].user = Object.assign({}, retval[domain].user);
  retval[domain].teams = Object.assign({}, retval[domain].teams);

  for (const key in roles) {
    roles[key] = Object.assign({
      key: roles[key].id,
      value: roles[key].id,
      text: roles[key].name
    }, roles[key]);
  }
  retval[domain].user.roles = roles;

  for (const key in teams) {
    teams[key] = Object.assign({
      key: teams[key].id,
      value: teams[key].id,
      text: teams[key].name,
      type: teams[key].type,
      role: teams[key].role
    }, teams[key]);
  }
  retval[domain].user.teams = teams;

  retval[domain].user.tags = state.wec.tags;

  return retval;
}

export default wecSlice.reducer;



/**
 * Asynchronous thunk action getConfig
 */
 export function getConfig(domain) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      const authUrl = state.wec.baseProtocol + '//' + state.wec.authUrl;
      const options = { filter: { active : true, domain: domain }, sorting : { name : 'ASC' }};
      
      // load global settings from server 
      dispatch(fetchGET(authUrl + '/api/config/' + domain + '/all')).then(
        config => {
          dispatch(fetchGET(backendUrl + '/api/latest/' + domain + '/tags/?options=' + btoa(JSON.stringify(options)).replace(/=/g, '_'))).then(
            tags => {
              dispatch(getConfigSuccess({ config: config, tags: tags }));
            }
          );
        }
      );
    } catch (error) {
      dispatch(getConfigFailed(error))
    }
  }
}