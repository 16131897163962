import { createSlice } from '@reduxjs/toolkit';



/**
 * 
 */
export const contextMenuSlice = createSlice({
  name: 'contextMenu',
  initialState: {
    visible: false,
    position: {
      x: 0,
      y: 0
    },
    correction: null
  },

  reducers: {
    showContextMenu: (state, action) => { 
      state.visible = true
      state.position.x = action.payload.x
      state.position.y = action.payload.y
      state.correction = action.payload.correction
    },
    hideContextMenu: (state) => { state.visible = false }
  }
});

export const { showContextMenu, hideContextMenu } = contextMenuSlice.actions;

export const isVisible = (state) => state.contextMenu.visible;
export const getPosition = (state) => state.contextMenu.position;
export const getCorrection = (state) => state.contextMenu.correction;


export default contextMenuSlice.reducer;