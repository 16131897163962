import { createSlice } from '@reduxjs/toolkit';
import { fetchGET, fetchPUT } from "../../toolbox/requestor.slice";
import { setAttendees } from './attendees.slice';
import { addMessage } from '../../snackbar/snackbar.slice';



/**
 * 
 */
export const editProjectSlice = createSlice({
  name: 'editroject',
  initialState: {
    dialogVisible : false,
    projectId: null,
    projectDomain: null,
    project: null
  },
  reducers: {
    hideDialog : (state) => { 
      state.dialogVisible = false; 
      state.project = null;
      state.projectId = null;
      state.projectDomain = null;
    },
    showDialog : (state, action) => { 
      state.dialogVisible = true;
      if (typeof action.payload.id !== 'undefined') {
        state.projectId = action.payload.id;
      }
      if (typeof action.payload.domain !== 'undefined') {
        state.projectDomain = action.payload.domain;
      }
    },
    toggleDialog : (state) => { state.dialogVisible = !state.dialogVisible; },
    setProject : (state, action) => { state.project = action.payload },
    
  },
});

export const { hideDialog, showDialog, toggleDialog, setProject } = editProjectSlice.actions;

export const isDialogVisible = (state) => state.editProject.dialogVisible;
export const getProjectId = (state) => state.editProject.projectId;
export const projectDomain = (state) => state.editProject.projectDomain;
export const getProject = (state) => state.editProject.project;

export default editProjectSlice.reducer;



/**
 * 
 */
 export function loadProject() {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      const projectId = state.editProject.projectId
      const projectDomain = state.editProject.projectDomain

      // get project data
      if (projectId) {
        dispatch(fetchGET(backendUrl + '/api/latest/' + projectDomain + '/project/' + projectId)).then(
          project => {
            dispatch(setProject(project));
            let attendees = {};
            project.attendees.forEach(attendee => {
              if (typeof attendees[attendee.role] === 'undefined') {
                attendees[attendee.role] = [];
              }
              attendees[attendee.role].push(attendee);
            });
            dispatch(setAttendees(attendees));
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
}




/**
 *
 * @param {string} props.domain
 * @param {*} props 
 */
 export function saveProject(props) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      if (typeof props.domain === 'undefined' || !props.domain) {
        props.domain = state.wec.mandant;
      }

      //
      props.attendees = state.attendees.attendees;

      // checks
      //dispatch(validate(props));

      // request
      dispatch(fetchPUT(backendUrl + '/api/latest/' + props.domain + '/project/'+props.id, props)).then(
        project => {
          //state.project.dirty = false;
          dispatch(addMessage({type: 'NOTICE', text: 'Projekt ' + project.title + ' wurde gespeichert'}));
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}