import { createSlice } from '@reduxjs/toolkit';
import { fetchPOST, fetchPUT } from "../toolbox/requestor.slice";
import { addMessage } from '../snackbar/snackbar.slice'
import { setRoles } from './roles.slice';



export const roleSlice = createSlice({
  name: 'role',
  initialState: {
    visible: false,
    role: null,
    dirty: 0
  },

  reducers: {
    showRole: (state, action) => { 
      state.visible = true;
      state.role = action.payload
    },
    hideRole: (state) => {
      state.visible = false;
      state.role = null;
      state.dirty = 0;
    },
    setRole: (state, action) => {
      state.role = action.payload;
      state.dirty = state.dirty + 1
    },
    setDirty: (state) => { state.dirty = state.dirty + 1}
  }
});
export const { showRole, hideRole, setRole, setDirty } = roleSlice.actions;
export const isVisible = (state) => state.role.visible;
export const getRole = (state) => state.role.role;
export const getDirty = (state) => state.role.dirty;

export default roleSlice.reducer;




export function saveRole(role) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const authUrl = state.wec.baseProtocol + '//' + state.wec.authUrl;
      if (typeof role.id !== 'undefined' && role.id) {
        dispatch(fetchPUT(authUrl + '/api/role', role)).then(
          role => {
            dispatch(addMessage({type: 'NOTICE', text: 'Rolle ' + role.name + ' wurde gespeichert'}));
            dispatch(setRole(role))
            dispatch(setRoles(null))
            dispatch(setDirty(false))
          }
        );
      } else {
        dispatch(fetchPOST(authUrl + '/api/role', role)).then(
          role => {
            dispatch(addMessage({type: 'NOTICE', text: 'Rolle ' + role.name + ' wurde angelegt'}));
            dispatch(setRole(role))
            dispatch(setRoles(null))
            dispatch(setDirty(false))
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
}