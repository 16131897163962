import { createSlice } from '@reduxjs/toolkit';
import { fetchDELETE, fetchGET, fetchPUT, fileUpload } from "../../toolbox/requestor.slice";
import { addMessage } from '../../snackbar/snackbar.slice';
import { selectPage } from '../corrector.slice';



/**
 * 
 */
export const editorSlice = createSlice({
  name: 'editor',
  initialState: {
    dirty: false,
    saving: false,
    mousePosition: { x:0, y:0 },
    marker: null,
    corrections: null,
    history: {},
    correctionsHistory: [],
    correctionsHistoryPointer: 0,
    selectedMarker: null,
    selectedCorrection: null,
    addMode: 'select',
    correctionMode: true,
    compareMode: 'normal',
    showPages: true,
    showComments: 2,
    scale: 1,
    background: {
      scale: 1
    },
    drawRect: false,
    selectedColor: null,
    selectedTool: null,
    comment: {
      visible: false,
      content: '',
      file: null,
      creation: null,
      user: null,
      new: null
    },
    compare: {
      projects: null,
      projectId: null,
      correctionLevels: null,
      correctionLevelId: null,
      correctionLevel: null,
      pageId: null,
      pageKey: null,
      corrections: null
    },
    markerLabelVisible: null,
    markerLabelCompareVisible: null,
  },

  reducers: {
    setDirty: (state, action) => { state.dirty = action.payload },
    setDirtyCorrection: (state, action) => { 
      if (typeof action.payload.cKey !== 'undefined' && typeof action.payload.dirty !== 'undefined')
      state.corrections[action.payload.cKey].dirty = action.payload.dirty
    },
    setSaving: (state, action) => { state.saving = action.payload },
    setMousePosition: (state, action) => { state.mousePosition = action.payload },
    setScale: (state, action) => { 
      state.scale = parseInt(action.payload) / 100;
      for (const key in state.corrections) {
        if (Object.hasOwnProperty.call(state.corrections, key)) {
          state.corrections[key].marker.x = state.corrections[key].marker.origin.x * state.scale;
          state.corrections[key].marker.y = state.corrections[key].marker.origin.y * state.scale;
          state.corrections[key].marker.width  = state.corrections[key].marker.origin.width * state.scale;
          state.corrections[key].marker.height = state.corrections[key].marker.origin.height * state.scale;
        }
      }
      if (state.compare.corrections) {
        for (const key in state.compare.corrections) {
          if (Object.hasOwnProperty.call(state.compare.corrections, key)) {
            state.compare.corrections[key].marker.x = state.compare.corrections[key].marker.origin.x * state.scale;
            state.compare.corrections[key].marker.y = state.compare.corrections[key].marker.origin.y * state.scale;
            state.compare.corrections[key].marker.width  = state.compare.corrections[key].marker.origin.width * state.scale;
            state.compare.corrections[key].marker.height = state.compare.corrections[key].marker.origin.height * state.scale;
          }
        }
      }
    },

    //
    setAddMode: (state, action) => { state.addMode = action.payload },
    setDragMode: (state) => { state.addMode = 'drag' },
    setSelectMode: (state) => { state.addMode = 'select' },
    setSelectedColor: (state, action) => { 
      state.selectedColor = action.payload;
    },
    setSelectedTool: (state, action) => {
      state.selectedTool = action.payload;
    },
    startDrawRect: (state) => { state.drawRect = true },
    stopDrawRect: (state) => { state.drawRect = false },
    resizeRect: (state) => {
      const x = state.corrections[state.corrections.length - 1].marker.x;
      const y = state.corrections[state.corrections.length - 1].marker.y;
      state.corrections[state.corrections.length - 1].marker.width = state.mousePosition.x - x;
      state.corrections[state.corrections.length - 1].marker.height = state.mousePosition.y - y;
    },
    setFlashMarker: (state, action) => {
      state.corrections[action.payload.key].marker.flash = action.payload.value;
    },
    
    //    
    initCorrections: (state, action) => {
      state.corrections = [];
      action.payload.forEach(correction => {
        let marker = { 
          ...correction.marker, 
          correction_id: correction.id,
          fill: correction.marker.color, 
          scaleX: 1, 
          scaleY: 1, 
          type: correction.marker.type,
          stroke: 'silver',
          strokeWidth: 1,
          cornerRadius: 5,
          origin: {
            x: correction.marker.x,
            y: correction.marker.y,
            width: correction.marker.width,
            height: correction.marker.height,
          },
        };
        state.corrections.push({...correction, marker: marker});
      });
    },
    setCorrections: (state, action) => { 
      state.corrections = action.payload;
      state.dirty = true;
    },
    setCorrectionsNH: (state, action) => { 
      state.corrections = action.payload;
      state.dirty = true; 
    },
    setCorrection: (state, action) => { 
      const key = action.payload.cnt - 1;
      let c_before = state.corrections[key];
      let c_change = action.payload;

      // init correction history
      if (typeof state.history[c_change.id] === 'undefined') {
        state.history[c_change.id] = [];
        state.history[c_change.id].push(c_before);
      }

      // push changed correction into history
      state.history[c_change.id].push(c_change);

      // set dirty flag
      c_change.dirty = true;

      // refresh corrections storage
      state.corrections[key] = c_change;
    },
    setCorrectionND: (state, action) => { 
      const key = action.payload.cnt - 1;
      let c_before = state.corrections[key];
      let c_change = action.payload;

      // init correction history
      if (typeof state.history[c_change.id] === 'undefined') {
        state.history[c_change.id] = [];
        state.history[c_change.id].push(c_before);
      }

      // refresh corrections storage
      state.corrections[key] = c_change;
    },
    setCorrectionNH: (state, action) => { 
      if (typeof action.payload.cnt !== 'undefined') {
        const key = action.payload.cnt - 1;
        state.corrections[key] = action.payload;
      } else {
        let key = null;
        state.corrections.forEach((element, index) => {
          if (element.id === action.payload.id) {
            key = index;
          }
        });
        if (key !== null) {
          action.payload.cnt = key + 1;
          state.corrections[key] = action.payload;
        }
      }
    },
    selectCorrection: (state, action) => { state.selectedCorrection = action.payload },
    unselectCorrection: (state) => { state.selectedCorrection = null },
    addCorrection: (state, action) => {
      const size = typeof action.payload.size !== 'undefined' && action.payload.size ? action.payload.size : 10; 

      // new marker object
      let new_marker = {
        x: state.mousePosition.x,
        y: state.mousePosition.y,
        width: parseInt(size),
        height: parseInt(size),
        fill: state.selectedColor,
        color: state.selectedColor,
        tool: state.selectedTool,
        id: state.addMode + '-' + (state.corrections.length + 1),
        cnt: (state.corrections.length + 1),
        type: state.addMode,
        scaleX: state.scale,
        scaleY: state.scale,
        cornerRadius: 5,
        new: true
      }
      //stroke: state.selectedColor,
      //strokeWidth: 1,
      
      // new corrections object with marker-object and comment-obect
      state.corrections.push({
        marker: new_marker,
        cnt: state.corrections.length + 1,
        id: new_marker.id
      });
      
      //
      if (state.addMode !== 'rect') {
        state.selectedCorrection = new_marker.id;
        state.addMode = 'select';
      }
    },
    abortCorrection: (state, action) => { 
      let delKey = null;
      for (const key in state.corrections) {
        if (Object.hasOwnProperty.call(state.corrections, key)) {
          const element = state.corrections[key];
          if (element.id === action.payload.id) {
            delKey = key;
          }
        }
      }
      if (delKey !== null) {
        delete state.corrections[delKey];
      }
      delete state.history[action.payload.id];
    },
    deleteCorrectionNH: (state, action) => { 
      state.corrections.splice(action.payload, 1)
      state.selectedMarker = null;
    },
    checkDeselect: (state, action) => {
      const clickedOnEmpty = action.payload.target === action.payload.target.getStage();
      if (clickedOnEmpty) {
        state.selectedMarker = null;
      }
    },
    setMarkerOption: (state, action) => {
      for (const key in state.corrections) {
        if (state.corrections[key].marker.id === action.payload.correction) {
          switch (action.payload.type) {
            case 'color':
              state.corrections[key].marker.fill = action.payload.options.color;
              state.corrections[key].marker.color = action.payload.options.color;

              state.dirty = true;
              state.correctionsHistory.push(state.corrections);
              state.correctionsHistoryPointer++;
              break;
            case 'tool':
              state.corrections[key].marker.tool = action.payload.options.tool;

              state.dirty = true;
              state.correctionsHistory.push(state.corrections);
              state.correctionsHistoryPointer++;
              break;
            case 'cnt':
              state.corrections[key].marker.cnt = action.payload.options;

              state.dirty = true;
              state.correctionsHistory.push(state.corrections);
              state.correctionsHistoryPointer++;
              break;
            default:
          }
        }
      }
    },

    //
    changeComment: (state, action) => { 
      state.comment[action.payload.key] = action.payload.value;
      if (action.payload.key === 'file') {
        console.log('action.payload.value.size:', action.payload.value.size)
        console.log('action.payload.value.type:', action.payload.value.type)
        if (parseInt(action.payload.value.size) > 10000000) {
          state.comment.file = null;
          console.error('File size error');
        }
        const type = action.payload.value.type;
        if (type !== 'application/pdf' && type !== 'image/jpeg' && type !== 'image/png' && type !== 'image/svg+xml') {
          state.comment.file = null;
          console.error('File type error');
        }
      }
    },
    resetComment: (state) => { state.comment = { visible: false, content: '', file: null }; },

    //
    setCompareMode: (state, action) => { 
      state.compareMode = action.payload;
      if (action.payload === 'normal') {
        state.compare = {
          ...state.compare,
          projectId: null,
          correctionLevels: null,
          correctionLevelId: null,
          correctionLevel: null,
          pageId: null,
          pageKey: null,
          corrections: null
        }
      }
    },
    changeCompare: (state, action) => { state.compare = action.payload },
    changeCompareValue: (state, action) => { state.compare[action.payload.key] = action.payload.value },
    setComparedPage: (state, action) => {
      state.compare.corrections = [];
      state.compare.pageId = action.payload;
      state.compare.correctionLevel.pages.forEach((page, key) => {
        if (page.id === action.payload) {
          state.compare.pageKey = key;
          page.corrections.forEach(correction => {
            let marker = { 
              ...correction.marker, 
              x: correction.marker.x * state.scale,
              y: correction.marker.y * state.scale,
              correction_id: correction.id,
              fill: correction.marker.color, 
              scaleX: state.scale,
              scaleY: state.scale,
              type: correction.marker.type,
              stroke: 'silver',
              strokeWidth: 1,
              cornerRadius: 5,
              comment: correction.comment
            };
            state.compare.corrections.push({...correction, marker: marker});
          });
        }
      });
    },
    
    // 
    setMarkerLabelVisible: (state, action) => { state.markerLabelVisible = action.payload },
    setMarkerLabelCompareVisible: (state, action) => { state.markerLabelCompareVisible = action.payload },

    // history
    abort: (state, action) => {
      const c_change = action.payload;
      const key = c_change.cnt - 1;
      const c_initial = state.history[c_change.id][0];
      
      // delete history
      delete state.history[c_change.id];

      // reset correction
      state.corrections[key] = c_initial
    },
    resetHistory: (state, action) => {
      const c_change = action.payload;
      if (typeof state.history[c_change.id] !== 'undefined') {
        delete state.history[c_change.id];
      }
    },

    // 
    togglePages: (state) => { state.showPages = !state.showPages; },
    toggleComments: (state, action) => { 
      switch (action.payload) {
        case '-':
          if (state.showComments) {
              state.showComments--;
          }
          break;
        case '+':
          if (state.showComments < 2) {
              state.showComments++;
          }
          break;
        default:
      }
    },
    toggleCorrectionMode: (state) => { state.correctionMode = !state.correctionMode },
  },
});

export const { 
  abort, resetHistory,
  setSaving, setFlashMarker, startDrawRect, stopDrawRect, resizeRect, setSelectedColor, setSelectedTool, 
  setMarkerLabelVisible, setMarkerLabelCompareVisible, setComparedPage, changeCompare, changeCompareValue, 
  changeComment, resetComment, setDirty, setMousePosition, 
  initCorrections, addCorrection, abortCorrection, setCorrectionsNH, setCorrections, setCorrection, setCorrectionNH, setCorrectionND, selectCorrection, unselectCorrection, changeNewCorrection, resetNewCorrection, deleteCorrectionNH,
  setAddMode, setSelectMode, setDragMode, checkDeselect, setScale, setMarkerOption, setCompareMode,  toggleCorrectionMode, togglePages, toggleComments,
  setDirtyCorrection } = editorSlice.actions;
export const isDirty = (state) => state.editor.dirty;
export const isSaving = (state) => state.editor.saving;
export const mousePosition = (state) => state.editor.mousePosition;
export const selectedCorrection = (state) => state.editor.selectedCorrection;
export const addMode = (state) => state.editor.addMode;
export const correctionMode = (state) => state.editor.correctionMode;
export const scale = (state) => state.editor.scale;
export const comment = (state) => state.editor.comment;
export const compareMode = (state) => state.editor.compareMode;
export const isPages = (state) => state.editor.showPages;
export const isComments = (state) => state.editor.showComments;
export const compare = (state) => state.editor.compare;
export const markerLabelCompareVisible = (state) => state.editor.markerLabelCompareVisible;
export const markerLabelVisible = (state) => state.editor.markerLabelVisible;
export const corrections = (state) => state.editor.corrections;
export const correctionsHistory = (state) => state.editor.correctionsHistory;
export const correctionsHistoryPointer = (state) => state.editor.correctionsHistoryPointer;
export const lastCorrection = (state) => state.editor.corrections[state.editor.corrections.length - 1];
export const selectedColor = (state) => state.editor.selectedColor;
export const selectedTool = (state) => state.editor.selectedTool;
export const isDrawRect = (state) => state.editor.drawRect;

export default editorSlice.reducer;





/**
* Load page data and correction from database
* @param {String} domain
* @param {Integer} pageId
* @param {Integer} pageKey
* @param {Boolean} resetInitialZoomLevel
*/
export function initCorrectionsFromDB(domain, pageId, pageKey, resetInitialZoomLevel) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      // get CorrctionLevel data and push it into store
      dispatch(fetchGET(backendUrl + '/api/latest/'+domain+'/page/' + pageId)).then(
        (page) => {
          dispatch(initCorrections(page.corrections));
          dispatch(selectPage({id: pageId, key: pageKey, resetInitialZoomLevel: resetInitialZoomLevel}))
        }
      ).catch((error)=>{
        console.error(error);
        dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Laden der Seite'}));
      });
    } catch (error) {
      console.error(error);
      dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Laden der Seite'}));
    }
  }
}



/**
 * 
 * @param {Integer} key 
 * @returns 
 */
export function flashCorrectionMarker(key) {
  return (dispatch) => {
    if (typeof key !== 'undefined' && key !== null) {
      dispatch(setFlashMarker({key: key, value: true}))
      setTimeout(function(){ 
        dispatch(setFlashMarker({key: key, value: false}))
      }, 1500);
    }
  }
}



/**
 * 
 * @param {Object} correction 
 * @returns 
 */
export function saveCorrection(correction) {
  return async (dispatch, getState) => {
    const state = getState();
    const initialScale  = state.editor.scale;
    const backendUrl    = state.wec.baseProtocol + '//' + state.wec.backendUrl;
    const attachmentUrl = state.wec.baseProtocol + '//' + state.wec.attachmentUrl;
    let _file = null;
    let _correction = Object.assign({}, correction);

    
    // start saving animation
    dispatch(setSaving(true));

    // take originScale measures for saving and keep current 
    _correction.marker = Object.assign({}, correction.marker);
    _correction.marker.currentScale = {
      x: _correction.marker.x,
      y: _correction.marker.y,
      width: _correction.marker.width,
      height: _correction.marker.height
    }

    // calculate 100% zoom measurements
    _correction.marker.x      = Math.ceil(_correction.marker.x / initialScale);
    _correction.marker.y      = Math.ceil(_correction.marker.y / initialScale);
    _correction.marker.width  = Math.ceil(_correction.marker.width / initialScale);
    _correction.marker.height = Math.ceil(_correction.marker.height / initialScale);
    
    // Upload attachment
    if (_correction.file) {
      const filename = await fileUpload(attachmentUrl, _correction.file, (progressData)=>{
        console.log(progressData);
      });
      _file = filename;
    }

    // apply file
    _correction.comment = Object.assign({}, _correction.comment);
    _correction.comment.file = _file;

    // Save correction
    try {
      dispatch(fetchPUT(backendUrl + '/api/latest/' + _correction.domain + '/correction/' + _correction.id, _correction)).then(
        response => {
          response.dirty = false;
          dispatch(addMessage({type: 'NOTICE', text: 'Korrektur wurde gespeichert'}));

          response.cnt = _correction.cnt;
          response.marker.cnt = _correction.marker.cnt;

          // origin
          response.marker.origin = {
            x: response.marker.x,
            y: response.marker.y,
            width: response.marker.width,
            height: response.marker.height
          }

          // for drawing
          response.marker.x = _correction.marker.x * initialScale;
          response.marker.y = _correction.marker.y * initialScale;
          response.marker.width = _correction.marker.width * initialScale;
          response.marker.height = _correction.marker.height * initialScale;

          dispatch(setCorrectionNH(response));
          dispatch(resetHistory(response));
          dispatch(setSaving(false));
        }
      );
    } catch (error) {
      console.error(error);
      dispatch(addMessage({type: 'ERROR', header: 'Fehler', text: 'Korrektur konnte nicht gespeichert werden'}));
    }
  }
}



/**
 * 
 * @param {String} domain 
 * @returns 
 */
export function save(domain) {
  return (dispatch, getState) => {
    const state = getState();
    const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
    const c_pageKey = state.corrector.data.page.number - 1;
    const e_corrections  = state.editor.corrections;

    let correctionlevel = Object.assign({}, state.corrector.data.correctionlevel);
    correctionlevel.pages = Object.assign([], correctionlevel.pages);
    correctionlevel.pages[c_pageKey] = Object.assign({}, correctionlevel.pages[c_pageKey]);
    correctionlevel.pages[c_pageKey].corrections = Object.assign([], e_corrections);
   
    console.log('[correctionlevel]', correctionlevel)

    try {
      dispatch(fetchPUT(backendUrl + '/api/latest/'+domain+'/correctionlevel/' + correctionlevel.id, correctionlevel)).then(
        cl => { 
          dispatch(setSaving(false));
          dispatch(setDirty(false));
          //dispatch(resetHistory());
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}



/**
 * 
 * @param {Object} correction 
 * @returns 
 */
 export function deleteCorrection(correction) {
  return async (dispatch, getState) => {
    const state = getState();
    const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

    // start saving animation
    dispatch(setSaving(true));
    
    // delete correction
    try {
      dispatch(fetchDELETE(backendUrl + '/api/latest/' + correction.domain + '/correction/' + correction.id)).then(
        response => {
          response.dirty = false;
          dispatch(addMessage({type: 'NOTICE', text: 'Korrektur wurde gelöscht'}));
          dispatch(deleteCorrectionNH(correction.cnt - 1));
          dispatch(setSaving(false));
        }
      );
    } catch (error) {
      console.error(error);
      dispatch(addMessage({type: 'ERROR', header: 'Fehler', text: 'Korrektur konnte nicht gelöscht werden'}));
    }
  }
}



/**
 * 
 * @param {string} props.domain
 * @param {*} props 
 */
 export function acceptCorrection(props) {
  return (dispatch) => {
    dispatch(changeNewCorrection({key: 'visible', value: true}));
  }
}
