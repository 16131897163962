// Redux
import { combineReducers } from '@reduxjs/toolkit'

// Reducers
import wecReducer                   from './WECorrect.slice'
import loginReducer                 from './components/login/login.slice'
import menuReducer                  from './components/menu/menu.slice'
import systemReducer                from './components/system/system.slice'
import userReducer                  from './components/user/user.slice'
import teamsReducer                 from './components/teams/teams.slice'
import teamReducer                  from './components/teams/team.slice'
import rolesReducer                 from './components/roles/roles.slice'
import roleReducer                  from './components/roles/role.slice'
import tagsReducer                  from './components/tags/tags.slice'
import snackbarReducer              from './components/snackbar/snackbar.slice'
import confirmReducer               from './components/confirm/confirm.slice'
import messageReducer               from './components/message/message.slice'

import dashboardReducer             from './components/dashboard/dashboard.slice'
import archiveReducer               from './components/archive/archive.slice'
import correctorReducer             from './components/corrector/corrector.slice'
import projectsReducer              from './components/corrector/projects.slice'
import correctionStageReducer       from './components/corrector/correctionStage.slice'
import correctionDateReducer        from './components/corrector/correctionDate.slice'
import editorReducer                from './components/corrector/editor/editor.slice'
import commentsReducer              from './components/corrector/editor/comments.slice'
import dialogCompareReducer         from './components/corrector/editor/dialogCompare.slice'
import dialogNewCorrectionReducer   from './components/corrector/editor/dialogNewCorrection.slice'
import dialogCorrectionReducer      from './components/corrector/editor/dialogCorrection.slice'
import dialogAddCommentReducer      from './components/corrector/editor/dialogAddComment.slice'
import contextMenuReducer           from './components/corrector/contextMenu/contextMenu.slice'
import userCLStatesReducer          from './components/corrector/userCLStates/userCLStates.slice'

import attendeesReducer             from './components/corrector/project/attendees.slice'
import newProjectReducer            from './components/corrector/project/new.slice'
import editProjectReducer           from './components/corrector/project/edit.slice'

import newCorrectionlevelSlice      from './components/corrector/correctionlevel/new.slice'

// Combine
export default combineReducers({
    wec: wecReducer,
    login: loginReducer,
    menu: menuReducer,
    system: systemReducer,
    user: userReducer,
    teams: teamsReducer,
    team: teamReducer,
    roles: rolesReducer,
    role: roleReducer,
    tags: tagsReducer,
    snackbar: snackbarReducer,
    confirm: confirmReducer,
    dashboard: dashboardReducer,
    archive: archiveReducer,
    message: messageReducer,
    
    corrector: correctorReducer,
    projects: projectsReducer,
    attendees: attendeesReducer,
    newProject: newProjectReducer,
    editProject: editProjectReducer,
    newCorrectionlevel: newCorrectionlevelSlice,
    userCLStates: userCLStatesReducer,
    correctionStage: correctionStageReducer,
    correctionDate: correctionDateReducer,
    editor: editorReducer,
    comments: commentsReducer,
    dialogCompare: dialogCompareReducer,
    dialogNewCorrection: dialogNewCorrectionReducer,
    dialogCorrection: dialogCorrectionReducer,
    dialogAddComment: dialogAddCommentReducer,
    contextMenu: contextMenuReducer
});