import { createSlice } from '@reduxjs/toolkit';
import { fetchPOST, fetchPUT } from "../toolbox/requestor.slice";
import { addMessage } from '../snackbar/snackbar.slice'
import { setTeams } from './teams.slice';



export const teamSlice = createSlice({
  name: 'team',
  initialState: {
    visible: false,
    team: null,
    dirty: 0
  },

  reducers: {
    showTeam: (state, action) => { 
      state.visible = true;
      state.team = action.payload
    },
    hideTeam: (state) => {
      state.visible = false;
      state.team = null;
      state.dirty = 0;
    },
    setTeam: (state, action) => {
      state.team = action.payload;
      state.dirty = state.dirty + 1
    },
    setDirty: (state) => { state.dirty = state.dirty + 1}
  }
});
export const { showTeam, hideTeam, setTeam, setDirty } = teamSlice.actions;
export const isVisible = (state) => state.team.visible;
export const getTeam = (state) => state.team.team;
export const getDirty = (state) => state.team.dirty;

export default teamSlice.reducer;




export function saveTeam(team) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const authUrl = state.wec.baseProtocol + '//' + state.wec.authUrl;
      if (typeof team.id !== 'undefined' && team.id) {
        dispatch(fetchPUT(authUrl + '/api/team', team)).then(
          team => {
            dispatch(addMessage({type: 'NOTICE', text: 'Team ' + team.name + ' wurde gespeichert'}));
            dispatch(setTeam(team))
            dispatch(setTeams(null))
          }
        );
      } else {
        dispatch(fetchPOST(authUrl + '/api/team', team)).then(
          team => {
            dispatch(addMessage({type: 'NOTICE', text: 'Team ' + team.name + ' wurde angelegt'}));
            dispatch(setTeam(team))
            dispatch(setTeams(null))
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
}