import { createSlice } from '@reduxjs/toolkit';
import { fetchGET, fetchPUT, fetchPDF, fetchDELETE } from "../toolbox/requestor.slice";
import { setAttendees } from './project/attendees.slice';
import { addMessage } from '../snackbar/snackbar.slice';
import { setActiveModule } from '../../WECorrect.slice';
import { resetProjects } from './projects.slice';



/**
 * 
 */
export const correctorSlice = createSlice({
  name: 'corrector',
  initialState: {
    dirty: false,
    selected: { project: null, correctionlevel: null, page: null },
    filter: { keywords: null, stage: null },
    projects: null,
    filteredProjects: null,
    clientProjects: null,
    activeProjectsTab: null,
    resetInitialZoomLevel: false,
    data: {
      archived: false,
      project: { 
        id: null, 
        title: '',
        pagesplit: false,
        active: true,
        tags: [],
        attendees: []
      },
      correctionlevel : { 
        id: null, 
        title: '',
        pages: []
      },
      page: null
    },
    newCorrectionLevel: {
      title: null,
      file: null,
      visible: false,
      loading: false
    }
  },

  reducers: {
    changeData: (state, action) => { 
      state.data[action.payload.key] = action.payload.value;
      if (action.payload.key === 'project') {
        state.archived = action.payload.value.archived;
      }
      if (action.payload.key === 'page') {
        const pg_key = action.payload.value.number - 1;
        action.payload.value.pageNr = action.payload.value.number;
        state.data.correctionlevel.pages[pg_key] = action.payload.value;
        state.data.page = action.payload.value; 
      }
    },
    changeProject: (state, action) => { state.data.project[action.payload.key] = action.payload.value; },
    resetProject: (state) => { state.data.project = { id: null, title: '', pagesplit: false, active: true, tags: [], attendees: { lead: [], agency: [], customer: [], guest: [] }}},
    selectProject: (state, action) => { state.selected = { project: action.payload, correctionlevel: null, page: null} },
    selectCorrectionLevel: (state, action) => { state.selected = { project: state.selected.project, correctionlevel:  action.payload, page: null} },
    selectPage: (state, action) => {
      if (action.payload !== null) {
        state.selected = { project: state.selected.project, correctionlevel: state.selected.correctionlevel, page: action.payload.id}
        state.data.page = state.data.correctionlevel.pages[action.payload.key]
      } else {
        state.selected = { project: state.selected.project, correctionlevel: state.selected.correctionlevel, page: null}
        state.data.page = null
      }
      if (typeof action.payload !== 'undefined' && action.payload && typeof action.payload.resetInitialZoomLevel !== 'undefined') {
        state.resetInitialZoomLevel = action.payload.resetInitialZoomLevel;
      }
    },
    unsetResetInitialZoomLevel: (state) => { state.resetInitialZoomLevel = false; },
    setResetInitialZoomLevel: (state) => { state.resetInitialZoomLevel = true; },
    changeNewCorrectionLevel: (state, action) => { state.newCorrectionLevel[action.payload.key] = action.payload.value; },
    setDataCorrection: (state, action) =>  {
      state.data.correctionlevel.pages[action.payload.page_nr].corrections[action.payload.correction_nr] = action.payload.correction;
    }
  },
});

export const { changeProject, changeData, resetProject, selectProject, changeNewCorrectionLevel, selectCorrectionLevel, selectPage, unsetResetInitialZoomLevel, setResetInitialZoomLevel, setDataCorrection } = correctorSlice.actions;

export const activeProjects = (state) => state.corrector.projects;
export const filteredProjects = (state) => state.corrector.filteredProjects;
export const clientProjects = (state) => state.corrector.clientProjects;
export const pages = (state) => state.corrector.pages;
export const data = (state) => state.corrector.data;
export const dataProject = (state) => Object.assign({archived: state.corrector.data.archived}, state.corrector.data.project);
export const dataCorrectionLevel =(state) => state.corrector.data.correctionlevel;
export const dataPage = (state) => state.corrector.data.page;
export const newCorrectionLevel = (state) => state.corrector.newCorrectionLevel
export const selectedProject = (state) => state.corrector.selected.project;
export const selectedCorrectionLevel = (state) => state.corrector.selected.correction;
export const selectedPage = (state) => state.corrector.selected.page;
export const selected = (state) => state.corrector.selected;
export const activeProjectsTab = (state) => state.corrector.activeProjectsTab;
export const selectResetInitialZoomLevel = (state) => state.corrector.resetInitialZoomLevel;

export default correctorSlice.reducer;



/**
 * 
 * @param {String} props.date 
 * @param {Integer} props.id 
 * @returns 
 */
export function setCorrectionLevelStage(props) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      // get CorrctionLevel data and push it into store
      dispatch(fetchPUT(backendUrl + '/api/latest/correctionlevels/' + props.id + '/stage', {stage: props.stage})).then(
        correctionlevel => {
          dispatch(changeData({ key: 'correctionlevel', value: correctionlevel}));
          dispatch(addMessage({type: 'NOTICE', text: 'Phase der Korrekturstufe gewechselt'}));
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}



/**
 * 
 * @param {String} domain 
 * @param {Integer} clid
 * @param {String} ustate 
 * @param {Integer} userId
 * @param 
 * @returns 
 */
 export function setCorrectionLevelState(domain, clid, ustate, userId, userName) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      // get CorrctionLevel data and push it into store
      const data = typeof userId !== 'undefined' ? { ustate: ustate, userId: userId, userName: userName } : { ustate: ustate };
      dispatch(fetchPUT(backendUrl + '/api/latest/'+domain+'/correctionlevels/' + clid + '/ustate', data)).then(
        correctionlevel => {
          dispatch(changeData({ key: 'correctionlevel', value: correctionlevel}));
          dispatch(addMessage({type: 'NOTICE', text: 'Status der Korrekturstufe geändert'}));
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}



/**
 * 
 * @param {String} domain 
 * @param {Integer} pid
 * @param {String} ustate 
 * @returns 
 */
 export function setPageState(domain, pid, ustate) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      // get CorrctionLevel data and push it into store
      dispatch(fetchPUT(backendUrl + '/api/latest/'+domain+'/page/' + pid + '/ustate', ustate)).then(
        page => {
          dispatch(changeData({ key: 'page', value: page}));
          dispatch(addMessage({type: 'NOTICE', text: 'Seitenstatus geändert'}));
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}



/**
 * 
 * @param {String} domain
 * @param {Integer} id
 * @param {Boolean} archived
 */
export function loadProject(domain, id, archived = false) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      // get project data
      dispatch(fetchGET(backendUrl + '/api/latest/'+domain+'/project/' + id)).then(
        project => {
          project.archived = archived;

          // apply project data to store
          dispatch(changeData({ key: 'project', value: project}));
          dispatch(selectProject(id));

          let attendees = {};
            project.attendees.forEach(attendee => {
              if (typeof attendees[attendee.role] === 'undefined') {
                attendees[attendee.role] = [];
              }
              attendees[attendee.role].push(attendee);
            });
            dispatch(setAttendees(attendees));
        }
      ).catch((error)=>{
        console.error(error);
        dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Laden des Projektes'}));

        // redirect to dashboard
        var newurl = window.location.protocol + "//" + window.location.host;
        window.history.pushState({path:newurl}, '', newurl);
        dispatch(setActiveModule('dashboard'))
        dispatch(selectProject(null));
        dispatch(resetProjects());
      });
    } catch (error) {
      console.error(error);
      dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Laden des Projektes'}));

      // redirect to dashboard
      var newurl = window.location.protocol + "//" + window.location.host;
      window.history.pushState({path:newurl}, '', newurl);
      dispatch(setActiveModule('dashboard'))
      dispatch(selectProject(null));
      dispatch(resetProjects());
    }
  }
}



/**
 * 
 * @param {String} domain
 * @param {Integer} id
 * @param {Boolean} archived
 */
 export function loadProjectMirror(domain, id, archived = false) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      // get project data
      dispatch(fetchGET(backendUrl + '/api/latest/'+domain+'/project/' + id)).then(
        project => {
          project.archived = archived;

          // apply project data to store
          dispatch(changeData({ key: 'project', value: project}));
          dispatch(selectProject(id));

          let attendees = {};
            project.attendees.forEach(attendee => {
              if (typeof attendees[attendee.role] === 'undefined') {
                attendees[attendee.role] = [];
              }
              attendees[attendee.role].push(attendee);
            });
            dispatch(setAttendees(attendees));
        }
      ).catch((error)=>{
        console.error(error);
        dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Laden des Projektes'}));

        // redirect to dashboard
        var newurl = window.location.protocol + "//" + window.location.host;
        window.history.pushState({path:newurl}, '', newurl);
        dispatch(setActiveModule('dashboard'))
        dispatch(selectProject(null));
        dispatch(resetProjects());
      });
    } catch (error) {
      console.error(error);
      dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Laden des Projektes'}));

      // redirect to dashboard
      var newurl = window.location.protocol + "//" + window.location.host;
      window.history.pushState({path:newurl}, '', newurl);
      dispatch(setActiveModule('dashboard'))
      dispatch(selectProject(null));
      dispatch(resetProjects());
    }
  }
}



/**
 * 
 * @param {string} domain
 * @param {integer} id
 */
export function loadCorrectionLevel(domain, id) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      // get CorrctionLevel data and push it into store
      dispatch(fetchGET(backendUrl + '/api/latest/'+domain+'/correctionlevels/' + id)).then(
        correctionlevel => {
          dispatch(changeData({ key: 'correctionlevel', value: correctionlevel}));
          dispatch(selectCorrectionLevel(id));
        }
      ).catch((error)=>{
        console.error(error);
        dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Laden der Korrekturstufe'}));

        // redirect to dashboard
        var newurl = window.location.protocol + "//" + window.location.host;
        window.history.pushState({path:newurl}, '', newurl);
        dispatch(setActiveModule('dashboard'))
        dispatch(selectProject(null));
        dispatch(resetProjects());
      });
    } catch (error) {
      console.error(error);
      dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Laden der Korrekturstufe'}));

      // redirect to dashboard
      var newurl = window.location.protocol + "//" + window.location.host;
      window.history.pushState({path:newurl}, '', newurl);
      dispatch(setActiveModule('dashboard'))
      dispatch(selectProject(null));
      dispatch(resetProjects());
    }
  }
}



/**
 * 
 * @param {string} props.domain
 * @param {*} props 
 */
export function archiveProject(props) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      dispatch(fetchGET(backendUrl + '/api/latest/project/' + props.id)).then(
        project => {
          project.active = false;
          dispatch(addMessage({type: 'NOTICE', text: 'Projekt ' + project.title + ' archiviert'}));
        }
      ).catch((error)=>{
        console.error(error);
        dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Projekt konnte nicht archiviert werden'}));
      });
    } catch (error) {
      console.error(error);
      dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Projekt konnte nicht archiviert werden'}));
    }
  }
}



/**
 * 
 * @param {string} props.domain
 * @param {*} props 
 */
export function reactivateProject(props) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      dispatch(fetchGET(backendUrl + '/api/latest/project/' + props.id)).then(
        project => {
          project.active = true;
          dispatch(addMessage({type: 'NOTICE', text: 'Projekt ' + project.title + ' reaktiviert'}));
        }
      ).catch((error)=>{
        console.error(error);
        dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Projekt konnte nicht reaktiviert werden'}));
      });
    } catch (error) {
      console.error(error);
      dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Projekt konnte nicht reaktiviert werden'}));
    }
  }
}



/**
 * 
 * @param {string} domain
 * @param {integer} id
 */
export function getCorrectionLevelSource(domain, id, sourceFilename) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      // get CorrctionLevel data and push it into store
      dispatch(fetchPDF(backendUrl + '/api/latest/'+domain+'/correctionlevels/' + id + '/source')).then(
        (source) => {
          console.log('[source]', source);
          
          var url = window.URL.createObjectURL(source);
          var a = document.createElement('a');
          a.href = url;
          a.target = '_blank';
          a.download = sourceFilename;
          document.body.appendChild(a);
          a.click();    
          a.remove();
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}



/**
 * 
 * @param {String} domain 
 * @param {Integer} clid
 * @param {String} ustate 
 * @param {Integer} userId
 * @param 
 * @returns 
 */
 export function deleteCorrectionLevel(domain, clid) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      // get CorrctionLevel data and push it into store
      dispatch(fetchDELETE(backendUrl + '/api/latest/'+domain+'/correctionlevel/' + clid)).then(
        project => {
          dispatch(changeData({ key: 'project', value: project}));
          if (typeof project.correctionlevel !== 'undefined') {
            dispatch(changeData({ key: 'correctionlevel', value: project.correctionlevel}));
          } else {
            dispatch(changeData({ key: 'correctionlevel', value: null}));
          }
          dispatch(addMessage({type: 'NOTICE', text: 'Korrekturstufe gelöscht'}));
        }
      ).catch((error)=>{
        console.error(error);
        dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Löschen der Korrekturstufe'}));
      });
    } catch (error) {
      console.error(error);
      dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Löschen der Korrekturstufe'}));
    }
  }
}