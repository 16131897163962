// react base
import React from 'react';
import useImage from 'use-image';

// Konvajs
import { Image } from 'react-konva';

// Styles
import './editor.css';




/**
 * Background - Image representation of a page 
 * @param {Object} imageProps 
 * @param {String} imageURL
 * @param {Float} scale 
 * @param {Object} offset
 * @param {Function} onClick 
 */
const WECCorrectorEditorPageImage = ({imageProps, imageURL, scale, offset, onClick}) => {
  const imgRef = React.useRef();
  
  React.useEffect(() => {
    imgRef.current.cache();
    imgRef.current.getLayer().batchDraw();
  });
  
  const [image] = useImage(imageURL + imageProps.pageNr + '.jpg');
  const x = typeof offset !== 'undefined' ? offset.x : 0;
  const y = typeof offset !== 'undefined' ? offset.y : 0;

  return <Image 
      ref= {imgRef} 
      image = {image} 
      {...imageProps} 
      x = {x}
      y = {y}
      scaleX = {scale}
      scaleY = {scale}
      stroke = {"#333"}
      strokeWidth = {2}
      onClick = {onClick}
    />;
  };
  
export default WECCorrectorEditorPageImage;