import { createSlice } from '@reduxjs/toolkit';
import { fileUpload, fetchPUT } from "../../toolbox/requestor.slice";
import { setCorrectionNH, setSaving, resetHistory } from './editor.slice'
import { addMessage } from '../../snackbar/snackbar.slice';
import { format } from 'date-fns/esm'



/**
 * 
 */
export const dialogCorrectionSlice = createSlice({
  name: 'dialogCorrection',
  initialState: {
    dialogVisible : false,
    projectDomain: null,
    refresh: true,
    scale: 1,
    offset: { 
      x: 0, 
      y: 0, 
      margin: 0
    },
    correction: null,
    correction_initial: null,
    file: null
  },

  reducers: {
    hideDialog : (state) => { 
      state.dialogVisible = false;
      state.projectDomain = null;
    },
    showDialog : (state, action) => { 
      state.dialogVisible = true;
      if (typeof action.payload.domain !== 'undefined') {
        state.projectDomain = action.payload.domain;
      }
      if (typeof action.payload.correction !== 'undefined') {
        state.correction = action.payload.correction;
        state.correction_initial = action.payload.correction;
      }
    },
    showCorrectionDialog : (state, action) => {
      state.dialogVisible = true;
      if (typeof action.payload.domain !== 'undefined') {
        state.projectDomain = action.payload.domain;
      }
      if (typeof action.payload.correction !== 'undefined') {
        state.correction = action.payload.correction;
      }
    },
    toggleDialog : (state) => { state.dialogVisible = !state.dialogVisible; },
    setRefresh : (state, action) => { state.refresh = action.payload },

    //
    setScale: (state, action) => { state.scale = parseInt(action.payload) / 100; },
    setOffset: (state, action) => { 
      state.offset = {
        x: parseInt(action.payload.x), 
        y: parseInt(action.payload.y),
        margin: parseInt(action.payload.margin)
      };
    },
    setColor: (state, action) => {
      state.correction.marker.fill = action.payload;
      state.correction.marker.color = action.payload;
    },
    setTool: (state, action) => { state.correction.marker.tool = action.payload; },
    setToolMessage: (state, action) => { state.correction.content = action.payload; },
    
    setCorrectionMarker: (state, action) => {  state.correction.marker = action.payload; },

    //
    changeCorrection: (state, action) => { 
      switch (action.payload.key) {
        case 'content': state.correction.comment.content = action.payload.value; break;
        case 'file': state.file = action.payload.value; break;
        default: 
      }
    },
    resetCorrection: (state) => {
      state.correction = state.correction_initial;
      state.refresh = true;
      state.scale = 1;
      state.offset = {x: 0, y: 0, margin: 0};
    },

  },
});

export const { hideDialog, showDialog, showCorrectionDialog, toggleDialog, setRefresh, setScale, setOffset, setColor, setTool, setToolMessage, resetCorrection, changeCorrection, setCorrectionMarker } = dialogCorrectionSlice.actions;

export const isDialogVisible = (state) => state.dialogCorrection.dialogVisible;
export const projectDomain = (state) => state.dialogCorrection.projectDomain;
export const refresh = (state) => state.dialogCorrection.refresh;
export const scale = (state) => state.dialogCorrection.scale;
export const offset = (state) => state.dialogCorrection.offset;
export const getCorrection = (state) => state.dialogCorrection.correction;
export const getFile = (state) => state.dialogCorrection.file;

export default dialogCorrectionSlice.reducer;





/**
 * 
 * @returns 
 */
export function applyCorrection() {
  return async (dispatch, getState) => {
    const state = getState();
    const initialScale  = state.editor.scale;
    const backendUrl    = state.wec.baseProtocol + '//' + state.wec.backendUrl;
    const attachmentUrl = state.wec.baseProtocol + '//' + state.wec.attachmentUrl;
    const username      = state.login.username;


    let _correction    = Object.assign({}, state.dialogCorrection.correction);
    _correction.marker = Object.assign({}, _correction.marker);
    let _file = null;

    // start saving animation
    dispatch(setSaving(true));
    
    // Anhang hochladen
    if (state.dialogCorrection.file) {
      const filename = await fileUpload(attachmentUrl, state.dialogCorrection.file, (progressData)=>{
        console.log(progressData);
      });
      _file = filename;
    }

    // Comment
    _correction.comment = Object.assign({
      updated: format(new Date(), "yyyy-MM-dd, HH:mm"),
      user: username,
      file: _file
    }, _correction.comment);

    // calculate 100% zoom measurements
    _correction.marker.x      = Math.ceil(_correction.marker.x / initialScale);
    _correction.marker.y      = Math.ceil(_correction.marker.y / initialScale);
    _correction.marker.width  = Math.ceil(_correction.marker.width / initialScale);
    _correction.marker.height = Math.ceil(_correction.marker.height / initialScale);

    // save correction
    try {
      dispatch(fetchPUT(backendUrl + '/api/latest/' + _correction.domain + '/correction/' +  _correction.id, _correction)).then(
        corr => {
          delete corr.dirty;
          corr.cnt = _correction.cnt;
          corr.marker.cnt = _correction.marker.cnt;

          // origin
          corr.marker.origin = {
            x: corr.marker.x,
            y: corr.marker.y,
            width: corr.marker.width,
            height: corr.marker.height
          }

          // for drawing
          corr.marker.x = _correction.marker.x * initialScale;
          corr.marker.y = _correction.marker.y * initialScale;
          corr.marker.width = _correction.marker.width * initialScale;
          corr.marker.height = _correction.marker.height * initialScale;

          // apply
          dispatch(addMessage({type: 'NOTICE', text: 'Korrektur wurde gespeichert'}));
          dispatch(resetHistory(corr));
          dispatch(setCorrectionNH(corr));
          dispatch(resetCorrection())
          dispatch(setSaving(false));
        }
      );
    } catch (error) {
      console.error(error);
      dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Speichern der Änderungen'}));
    } 
  }
}