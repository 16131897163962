import { createSlice } from '@reduxjs/toolkit';
import { fetchGET, fetchPUT } from "../../toolbox/requestor.slice";
import { changeData } from '../corrector.slice';
import { setCorrectionNH, setSaving } from './editor.slice';
import { addMessage } from '../../snackbar/snackbar.slice'



/**
 * 
 */
export const commentsSlice = createSlice({
  name: 'comments',
  initialState: {
    dirty : false,
    selectedComment : null,
  },

  reducers: {
    selectComment : (state, action) => { state.selectedComment = action.payload; },
  },
});

export const { selectComment } = commentsSlice.actions;
export const selectedComment = (state) => state.comments.selectedComment;

export default commentsSlice.reducer;




/**
 * 
 * @param {string} props.domain
 * @param {*} props 
 */
export function setState(props) {
  return (dispatch, getState) => {
    const state = getState();
    const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
    const c_pageKey = state.corrector.data.page.number - 1;
    const c_correctionLevel = state.corrector.data.correctionlevel;
    
    let c_page = {...state.corrector.data.page};

    try {
      // (1)
      dispatch(fetchGET(backendUrl + '/api/latest/'+props.domain+'/correction/' + props.id + '/' + props.state)).then(
        correction => {
          // (1)
          correction.cnt = props.key + 1;
          
          // (2) Korrektur ersetzen
          c_page.corrections = Object.assign([], c_page.corrections);
          c_page.corrections[props.key] = Object.assign({}, correction);

          // (3) Seite in correctionlevel ersetzen
          let pages = [];
          for (const key in c_correctionLevel.pages) {
            if (parseInt(key) === parseInt(c_pageKey)) {
              pages.push(c_page);
            } else {
              pages.push(c_correctionLevel.pages[key]);
            }
          }
          let _correctionLevel = Object.assign({}, c_correctionLevel);
          _correctionLevel.pages = pages;

          // states refreshen
          dispatch(setCorrectionNH(correction));
          dispatch(changeData({key: 'page', value: c_page}));
          dispatch(changeData({key: 'correctionlevel', value: _correctionLevel}));

          // Snackbar notice
          switch (props.state) {
            case 'accept': dispatch(addMessage({type: 'NOTICE', text: 'Korrektur akzeptiert'})); break;
            case 'rejected': dispatch(addMessage({type: 'NOTICE', text: 'Korrektur zurückgewiesen'})); break;
            default:
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}





/**
 * 
 * @param {String} domain 
 * @param {Integer} cid
 * @param {String} ustate 
 * @returns 
 */
 export function setCorrectionState(domain, cid, ustate) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      // start saving animation
      dispatch(setSaving(true));

      // get CorrctionLevel data and push it into store
      dispatch(fetchPUT(backendUrl + '/api/latest/'+domain+'/correction/' + cid + '/ustate', {ustate: ustate})).then(
        correction => {
          console.log('[correction]', correction);
          dispatch(setCorrectionNH(correction));
          dispatch(addMessage({type: 'NOTICE', text: 'Persönlicher Korrekturstatus geändert'}));
          dispatch(setSaving(false));
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}