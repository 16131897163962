import $ from "jquery";
import DKSError from './error';
import axios from 'axios';


  
/**
 * 
 * @param {string} uploadUrl 
 * @param {File} file 
 */
export function fileUploadRequest(uploadUrl, file, token, progressCallbackFunc) {
    // 
    const formData = new FormData()
    formData.append('file', file);

    //
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': token
    };

    //
    return new Promise((resolve, reject) => {
        axios.post(uploadUrl, formData, {
            headers: headers,
            onUploadProgress: ProgressEvent => {
                progressCallbackFunc({loaded: (ProgressEvent.loaded / ProgressEvent.total*100)});
            }
        }).then(response => {
        if (response.status === 200) {
            resolve(response.data.filename);
        } else {
            reject(response.statusText);
        }
        });
    });
}



/**
 * 
 * @param {String} url 
 * @param {Object} data 
 * @returns {JSON}
 */
export async function fetchPOSTRequest(url = '', data = {}, mode = 'cors')
{
    const response = await fetch(url, {
        method: 'POST',
        mode: mode, // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers:
        {
            'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data)
    });
    return response.json();
}



/**
 * 
 * @param {String} url 
 * @param {Object} data 
 * @returns {JSON}
 */
export async function fetchPUTRequest(url = '', data = {})
{  
    const response = await fetch(url, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers:
        {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    });
    return response.json();
}



/**
 * 
 * @param {String} url 
 * @returns {JSON}
 */
export async function fetchGETRequest(url = '')
{
    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers:
        {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    return response.json();
}



/**
 * 
 * @param {String} url 
 * @returns {JSON}
 */
export async function fetchDELETERequest(url = '')
{
    const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers:
        {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    return response.json();
}



/**
 * @description JWT (JSON Web Token) https://jwt.io/introduction/
 * 
 * @param {String} type
 * @param {String} url
 * @param {String} token 
 * @param {String|Json} data
 * @param {integer} id
 * @param {String} url_suffix 
 */
export const request = (type, url, token=null, data=null, id=null, url_suffix=null, cache = true) =>
{
    const types = ['GET', 'PUT', 'POST', 'DELETE'];
    let promise = new $.Deferred();

    // check param
    if (typeof type === 'undefined' || !types.includes(type))
    {
        throw new Error("Invalid request type given");
    }
    if ((type === 'POST' || type === 'PUT') && (typeof data === "undefined" || data === null || !data))
    {
        throw new Error("No data given");
    }
    if (type === 'PUT' && (typeof id === "undefined" || id === null ||  !id))
    {
        throw new Error("No target id given");
    }
    if (type === 'DELETE' && (typeof id === "undefined" || id === null || !id))
    {
        throw new Error("No target id given");
    }
    
    // api request settings
    let settings = {
        type            : type,
        dataType        : 'json',
        contentType     : 'application/json',
        crossDomain     : true,
        //cache           : cache,
        data            : typeof data === "undefined" || data == null || !data ? null : JSON.stringify(data),
    };
    url = typeof id === "undefined" || id == null || !id ? url : url + '/' + id;
    url = typeof url_suffix === "undefined" || url_suffix == null || !url_suffix ? url : url + '/' + url_suffix;
    if (typeof token !== 'undefined' && token !== null && token !== '')
    {
        //settings.headers = { 'Authorization': token };
    }

    // do api request
    let ajax = $.ajax(url, settings);
    ajax.then(
        (response) =>
        {
            return promise.resolve(response);
        }
    ).fail(
        (jqXHR, textStatus, errorThrown) =>
        {
            console.error(textStatus);
            console.error(jqXHR.responseJSON.error.type);
            console.error(jqXHR.responseJSON.error.message);            
            promise.reject(new DKSError(jqXHR.responseJSON.error.type, jqXHR.responseJSON.error.message, textStatus, errorThrown));
        }
    );

    return promise;
}