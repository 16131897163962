// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { searchAttendees, attendeesSearchResult, addAttendees, abortAttendees, removeAttendee, selectAttendees, selectRole, setRole, selectTerm, setTerm } from './attendees.slice';
import { selectConfig } from '../../../WECorrect.slice';
import { showMessageSingle } from '../../message/message.slice';

// semantic ui
import { Container, Input, Header, Button, List, Icon, Label, Dropdown, Form } from 'semantic-ui-react';

// Styles
import './project.css';





/**
 * @param {Boolean} readOnly
 * @param {Function} setDirty
 * @returns 
 */
const WECProjectAttendeesAdd = ({readOnly, setDirty}) => {
  // global state
  const dispatch = useDispatch();
  const p_searchResult = useSelector(attendeesSearchResult);
  const role = useSelector(selectRole);
  const term = useSelector(selectTerm);

  // settings for roles and teams 
  const config    = useSelector(selectConfig);
  const sys_roles = Object.assign([], config[config.domain].user.roles);

  // translation
  const { t } = useTranslation('corrector');

  //
  if (readOnly) {
    return (<></>);
  }

  // search field
  let search = <></>;
  if (typeof readOnly === 'undefined' || readOnly === false) {
    search = (
      <Input fluid id="wec_project_new_attendees_search" iconPosition='left' placeholder='Suchen' value={term} onChange = {(e, {value})=>{
        dispatch(setTerm(value));
        if (value.length) {
          dispatch(searchAttendees(value));
        }
      }} >
        <Icon name='search' />
        <input placeholder = 'Suchen' />
      </Input>
    );
  }
  
  // build attendees search result list
  let role_attendees = [];
  let key = 0;
  if (typeof p_searchResult !== 'undefined' && p_searchResult && p_searchResult.length) {
    p_searchResult.forEach((item) => {
      key++;

      // icon
      const icon = item.type === 'user' ? 'user' : 'users';

      // User type
      let user_type = null;
      if (item.type === 'user') {
        config[item.domain].user.users.forEach(user => {
          if (user.id === item.id && typeof user.type !== 'undefined' && user.type.name !== 'undefined') {
            user_type = <Label>{user.type.name}</Label>
          }
          if (user.id === item.id && typeof user.typeObject !== 'undefined' && user.typeObject.name !== 'undefined') {
            user_type = <Label>{user.typeObject.name}</Label>
          }
        });
      }

      role_attendees.push(
        <List.Item key={key}>
          <List.Icon name={icon} />
          <List.Content as='a'>{item.name} {user_type}</List.Content>
        </List.Item>
      );
    });
  }

  // Buttons
  let buttons = <></>
  if (typeof p_searchResult !== 'undefined' && p_searchResult && p_searchResult.length) {
    buttons = (
      <Form style={{width: '95%'}}>
        <Form.Group>
          <Form.Field inline>
            <label>Als</label>
            <Dropdown 
              selection search
              className = 'mini' 
              placeholder = 'Rolle wählen' 
              options = {sys_roles} 
              value = {role}
              onChange = {(e, {value}) => dispatch(setRole(value))} />
          </Form.Field>
          <Form.Field inline>
            <Button size='mini' onClick={() => dispatch(abortAttendees(p_searchResult, role))}>Abbrechen</Button>
            <Button size='mini' onClick={() => { dispatch(addAttendees(p_searchResult, role)); setDirty(true); }}>{t('corrector.ATTENDEES:Übernehmen')}</Button>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }

  //
  const list = (typeof p_searchResult !== 'undefined' && p_searchResult.length) ? <List className='listAdd'>{role_attendees}</List> : <></>

  return (
    <React.Fragment>
      {search}
      {list}
      {buttons}
    </React.Fragment>
  );
}



/**
 * @param {Boolean} readOnly
 * @param {Boolean} large
 * @param {Function} setDirty
 * @returns 
 */
const WECProjectAttendeesList = ({readOnly, large, setDirty}) => {
  // global state
  const dispatch = useDispatch();
  const c_attendees = useSelector(selectAttendees);
  const p_searchResult = useSelector(attendeesSearchResult);

  // settings for roles and teams 
  const config    = useSelector(selectConfig);
  const sys_roles = Object.assign([], config[config.domain].user.roles);

  //
  let className = '';
  if (large) {
    className = (typeof p_searchResult !== 'undefined' && p_searchResult && p_searchResult.length) ? 'listAddedSmall large' : 'listAdded large';  
  } else {
    className = (typeof p_searchResult !== 'undefined' && p_searchResult && p_searchResult.length) ? 'listAddedSmall' : 'listAdded';
  }

  // build antendees list
  let attendees = [];
  for (const key in c_attendees) {
    if (Object.hasOwnProperty.call(c_attendees, key)) {
      const element = c_attendees[key];
      const role_attendees = element.map((attendee, i) => {
        let attendee_user = null;

        // User type
        let user_type = null;
        config[attendee.domain].user.users.forEach(user => {
          if (user.id === attendee.userid && typeof user.type !== 'undefined') {
            attendee_user = user;
            user_type = user.typeObject.name
          }
        });

        let attendeeDetailName = '';
        if (typeof attendee.details !== 'undefined' && typeof attendee.details.forename !== 'undefined') {
          attendeeDetailName += attendee.details.forename;
        }
        if (typeof attendee.details !== 'undefined' && typeof attendee.details.surname !== 'undefined') {
          attendeeDetailName += ' ' + attendee.details.surname;
        }

        return (
          <List.Item key={key}>
              {!readOnly && (
                <List.Content floated='right'>
                  <Icon name="times" onClick={()=>{ dispatch(removeAttendee(attendee.id)); setDirty(true); }} />
                </List.Content>
              )}
              <List.Content as='a'>{attendeeDetailName}&nbsp;&nbsp;<Icon link name='envelope outline' onClick={()=>dispatch(showMessageSingle(attendee_user))}/></List.Content>
              <List.Content as='a'><Label>{user_type}</Label></List.Content>
            </List.Item>
        )
      });

      let roleName = '';
      sys_roles.forEach(role => {
        if (''+role.id === ''+key) {
          roleName = role.name;
        }
      });
      attendees.push(
        <List.Item key={key}>
          {roleName}
          <List.List>{role_attendees}</List.List>
        </List.Item>  
      );
    }
  }

  return (<List className={className}>{attendees}</List>);
}



/**
 * @param {Boolean} props.readOnly
 * @returns 
 */
const WECProjectAttendees = (props) => {
  // translation
  const { t } = useTranslation('corrector');

  // Search field
  let readOnly = false;
  if (typeof props.readOnly !== 'undefined' && props.readOnly === true) {
    readOnly = true;
  }

  let large = false;
  if (typeof props.large !== 'undefined' && props.large === true) {
    large = true;
  }

  // default user
  if (typeof props.defaultUser !== 'undefined') {
    console.log('[props.defaultUser]', props.defaultUser);
  }
  
  return (
    <React.Fragment>
      <Header>{t('corrector:ATTENDEES.Teilnehmer')}</Header>
      <Container className={'attendees_list'}>
        <WECProjectAttendeesAdd readOnly={readOnly} setDirty = {typeof props.setDirty !== 'undefined' ? props.setDirty : null} />
        <WECProjectAttendeesList 
          readOnly = {readOnly} 
          large = {large} 
          defaultUser = {typeof props.defaultUser !== 'undefined' ? props.defaultUser : null} 
          setDirty = {typeof props.setDirty !== 'undefined' ? props.setDirty : null}
        />
      </Container>
    </React.Fragment>
  );
}

export default WECProjectAttendees;