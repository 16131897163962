// react base
import React from 'react';
import { useSelector, useDispatch} from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { dataCorrectionLevel, dataProject, setCorrectionLevelState } from '../corrector.slice';
import { changeCorrectionDate } from '../correctionDate.slice';
import { showUserCLStates } from '../userCLStates/userCLStates.slice';
import { selectUser } from '../../login/login.slice';
import { selectConfig } from '../../../WECorrect.slice';

// Permissions
import WECPermissionHandler from '../../toolbox/permission_handler';

// semantic ui
import { Container, Button, Icon, Label, Grid } from 'semantic-ui-react'

// Styles
import './correctionlevel.css';





/**
 * 
 * @param {Array} pages 
 */
const WECCorrectorCLDetails = () => {
  const dispatch = useDispatch();
  const c_correctionLevel = useSelector(dataCorrectionLevel);
  const c_project         = useSelector(dataProject);
  const userLoggedIn      = useSelector(selectUser);
  const config            = useSelector(selectConfig);

  // translation
  const { t } = useTranslation('corrector');

  // Permissions
  let permissionHandler = new WECPermissionHandler();
  const permission_PERM_CLV_02 = permissionHandler.hasPermission(userLoggedIn, 'PERM_CLV_02', c_project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
  const permission_PERM_CLV_03 = permissionHandler.hasPermission(userLoggedIn, 'PERM_CLV_03', c_project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
  const permission_PERM_CLV_04 = permissionHandler.hasPermission(userLoggedIn, 'PERM_CLV_04', c_project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2 || userLoggedIn.type === 3);

  // Userstatus
  let userstate_txt = t('corrector:CORRECTIONLEVEL_DETAILS.Kein Status');
  let userstate_col = '';
  if (typeof c_correctionLevel.userstate !== 'undefined' && c_correctionLevel.userstate) {
    switch (c_correctionLevel.userstate.state) {
      case 'open': 
        userstate_txt = t('corrector:CORRECTIONLEVEL_DETAILS.in Bearbeitung'); 
        userstate_col = 'green';
        break;
      case 'locked':
        userstate_txt = t('corrector:CORRECTIONLEVEL_DETAILS.Abgeschlossen');
        userstate_col = 'red';
        break;
      default:
        userstate_txt = t('corrector:CORRECTIONLEVEL_DETAILS.in Bearbeitung');
        userstate_col = 'green';
    }
  }

  // Deadlie
  let deadline_txt = '';
  if (typeof c_correctionLevel.deadline !== 'undefined' && c_correctionLevel.deadline) {
    deadline_txt = c_correctionLevel.deadline.date.split(' ')[0].split('-').reverse().join('.');
  }

  return (
    <Container className='WECProject wec_correctionlevel_details '>
      <Grid columns={4}>
        <Grid.Row>
          <Grid.Column width={5}>
            <h3>{c_correctionLevel.title}</h3>
            <Label tag size='mini' color={userstate_col}>{userstate_txt}</Label>
          </Grid.Column>
          {permission_PERM_CLV_03 && (<Grid.Column width={6}><p className='correctionDate'>{t('corrector:CORRECTIONLEVEL_DETAILS.Termin für Korrekturen')}:<Icon link name='calendar alternate outline' onClick={() => dispatch(changeCorrectionDate({key: 'visible', value: true}))} />{deadline_txt}</p></Grid.Column>)}
          {!permission_PERM_CLV_03 && (<Grid.Column width={6}><p className='correctionDate'>{t('corrector:CORRECTIONLEVEL_DETAILS.Termin für Korrekturen')}:<Icon link name='calendar alternate outline' />{deadline_txt}</p></Grid.Column>)}
          <Grid.Column width={5}>
            {(typeof c_correctionLevel.userstate === 'undefined' || c_correctionLevel.userstate.state === 'open') && permission_PERM_CLV_02 && (
              <Button icon primary size='mini' labelPosition='left' floated='right' onClick={()=>dispatch(setCorrectionLevelState(c_project.domain, c_correctionLevel.id, 'locked'))}><Icon name='check circle outline' />{t('corrector:CORRECTIONLEVEL_DETAILS.Korrekturstufe freigeben')}</Button>
              )}
            {typeof c_correctionLevel.userstate !== 'undefined' && c_correctionLevel.userstate.state === 'locked' && permission_PERM_CLV_02 && (
              <Button icon primary size='mini' labelPosition='left' floated='right' onClick={()=>dispatch(setCorrectionLevelState(c_project.domain, c_correctionLevel.id, 'open'))}><Icon name='circle outline' />{t('corrector:CORRECTIONLEVEL_DETAILS.Korrekturstufenfreigabe widerrufen')}</Button>
              )}
              {permission_PERM_CLV_04 && (<Button size='mini' floated='right' onClick={()=>dispatch(showUserCLStates())}>{t('corrector:CORRECTIONLEVEL_DETAILS.Korrekturübersicht')}</Button>)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

export default WECCorrectorCLDetails;