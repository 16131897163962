// react base
import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';

// semantic ui
import { Modal, Header, Button, Table, Label, Icon, Input } from 'semantic-ui-react';

// Redux
import { isVisible, hideTags, getTags, setTags, deleteTag, updateTag, createTag } from './tags.slice';
import { selectConfig, selectBaseProtocol, selectBackendUrl } from '../../WECorrect.slice';
import { fetchGET } from "../toolbox/requestor.slice";
import { showConfirm } from "../confirm/confirm.slice";
import { selectUser } from '../login/login.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// Styles
import './tags.css';




/**
 *
 */
const WECTags = () => {
    // local state
    const [tagNew, setTagNew] = useState(null);
    const [tagEdit, setTagEdit] = useState(null);

    // global state
    const dispatch  = useDispatch();
    const visible = useSelector(isVisible);
    const tags = useSelector(getTags);
    const config = useSelector(selectConfig);
    const baseProtocol  = useSelector(selectBaseProtocol);
    const backendhUrl = baseProtocol + '//' + useSelector(selectBackendUrl);
    const userLoggedIn  = useSelector(selectUser);

    //
    useEffect(() => {
        if (visible && !tags) {
            const options = { filter: { domain: config.domain}, sorting:{ name: 'ASC' } };
            dispatch(fetchGET(backendhUrl + '/api/latest/'+config.domain+'/tags/?options=' + btoa(JSON.stringify(options)).replace(/=/g, '_'))).then(
                response => dispatch(setTags(response))
            );
        }
    })

    // 
    if (!visible) {
        return <></>
    }

    // Permissions
    let permissionHandler = new WECPermissionHandler();
    const permission_PERM_SYS_11 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_11') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
    const permission_PERM_SYS_12 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_12') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);

    // list of available tags
    let tags_list = <></>;
    if (typeof tags !== 'undefined' && tags) {
        tags_list = tags.map((tag) => {
            if (tag.type === 'tag') {
                const name_label = <Label className={tag.name} icon='hashtag' content={tag.name} />
                if (tagEdit !== null && typeof tagEdit.id !== 'undefined' && tagEdit.id !== null && tagEdit.id === tag.id) {
                    return (
                        <Table.Row>
                            <Table.Cell collapsing>
                                <Input size='small' icon='hashtag' iconPosition='left' placeholder='neues Schlagwort' value={tagEdit.name} onChange={(e, {value})=>{
                                    let _tag = Object.assign({}, tagEdit);
                                    _tag.name = value;
                                    setTagEdit(_tag);
                                }} />
                                
                            </Table.Cell>
                            <Table.Cell collapsing textAlign='right'>
                                {permission_PERM_SYS_12 && (
                                    <>
                                    <Button primary icon onClick={()=>{
                                        dispatch(updateTag(tagEdit))
                                        setTagEdit(null)
                                    }}><Icon name='save' /></Button>
                                    <Button icon onClick={()=>setTagEdit(null)}><Icon name='times' /></Button>
                                    </>
                                )}
                            </Table.Cell>
                        </Table.Row>
                    );
                }

                return (
                    <Table.Row>
                        <Table.Cell collapsing>{name_label}</Table.Cell>
                        <Table.Cell collapsing textAlign='right'>
                            {permission_PERM_SYS_12 && (
                                <>
                                <Button icon onClick={()=>setTagEdit(tag)}><Icon name='edit' /></Button>
                                <Button 
                                    icon 
                                    onClick={()=>dispatch(showConfirm({
                                        cancelButton: 'Abbrechen',
                                        confirmButton: 'Löschen',
                                        message: "Schlagwort '" + tag.name + "' wirklich löschen?",
                                        onConfirm: ()=>dispatch(deleteTag(tag))
                                    }))}
                                ><Icon name='trash' /></Button>
                                </>
                            )}
                        </Table.Cell>
                    </Table.Row>
                );
            }
            return <></>
        })
    }

    //new tag
    let newTagRow = <></>;
    if (tagNew !== null) {
        newTagRow =  (
            <>
                <Table.Row>
                    <Table.Cell collapsing>
                        <Input size='small' icon='hashtag' iconPosition='left' placeholder='neues Schlagwort' onChange={(e, {value})=>{
                            let _tag = Object.assign({}, tagNew);
                            _tag.name = value;
                            setTagNew(_tag);
                        }} />
                    </Table.Cell>
                    <Table.Cell collapsing textAlign='right'>
                        <Button primary icon onClick={()=>{
                            dispatch(createTag(tagNew))
                            setTagNew(null)
                        }}><Icon name='save' /></Button>
                        <Button icon onClick={()=>setTagNew(null)}><Icon name='times' /></Button>
                    </Table.Cell>
                </Table.Row>
            </>
        )
    }

    return (
        <Modal
            open = {visible}
            className = 'wectags'
            size = 'small'
            closeIcon 
            closeOnEscape = {false} 
            closeOnDimmerClick = {false}
            onClose = {() => {
                setTagNew(null)
                setTagEdit(null)
                dispatch(hideTags())
            }}
        >
            <React.Fragment>
                <Modal.Content>
                <Header>Tags</Header>
                    <Table basic='very' selectable>
                        <Table.Body>
                            {tags_list}
                            {newTagRow}
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button icon labelPosition='left' onClick={()=>{
                        setTagNew(null)
                        setTagEdit(null)
                        dispatch(hideTags())
                    }}><Icon name='times' />Schließen</Button>
                    {permission_PERM_SYS_11 && (
                        <Button primary icon labelPosition='left' onClick={()=>setTagNew({ id: 'ADD_'+Math.round(+new Date()/1000), name: '', type: 'tag', domain: config.domain })}><Icon name='plus' />Hinzufügen</Button>
                    )}
                </Modal.Actions>
            </React.Fragment>
        </Modal>
    );
}

export default WECTags;