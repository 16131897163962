// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Redux
import { isDialogVisible, hideDialog, newCorrection, changeNewCorrection, applyNewCorrection, resetNewCorrection } from './dialogNewCorrection.slice';
import { setSelectMode, abortCorrection, lastCorrection } from './editor.slice';
import { dataPage } from '../corrector.slice';
import { selectConfig } from '../../../WECorrect.slice';

// semantic ui
import { Button, TextArea, Modal, Header, Form, Icon, Input, Container, Divider } from 'semantic-ui-react';

// App
import WECCorrectorEditorNew from "./editorNew"

// Styles
import './editor.css';



/**
 *
 * @returns 
 */
const WECCorrectorEditorNewCorrection = () => {
  const dispatch = useDispatch();
  const _newCorrection = useSelector(newCorrection);
  const _lastCorrection = useSelector(lastCorrection);
  const _isDialogVisible = useSelector(isDialogVisible);
  const _config = useSelector(selectConfig);
  const c_dataPage = useSelector(dataPage);
  
  const fileInputRef = React.createRef();

  // filename
  const _newCorrectionFileName = _newCorrection.file && typeof _newCorrection.file.name !== 'undefined' ? _newCorrection.file.name : '';

  return (
    <Modal open={_isDialogVisible} className='wec_editor_newcorrection' size='large' closeOnEscape={false} closeOnDimmerClick={false}>
      <Modal.Content>
        <Container className='preview'>
          <WECCorrectorEditorNew />
        </Container>
        <Container className='data'>
          <Header as='h1'>Neue Korrektur hinzufügen</Header>
          <Form>
            <Form.Field>
              <label>Was soll korrigert werden?</label>
              <TextArea rows={6} id='newcorrection-content' size="small" placeholder="Nachricht hinzufügen..." value={_newCorrection.content} onChange={(e, {value})=>dispatch(changeNewCorrection({key: 'content', value: value}))}></TextArea>
            </Form.Field>
            <Form.Field><label>&nbsp;</label></Form.Field>
            <Form.Group widths='equal'>
              <Form.Field width={12}>
                <label>Anhang</label>
                <input 
                  ref = {fileInputRef}
                  type = "file" 
                  hidden 
                  onBlur = {(e)=>dispatch(changeNewCorrection({key: 'file', value: e.target.files[0]}))} />
                <Input size="small" fluid readOnly value={_newCorrectionFileName} placeholder="Datei vom Computer" />
              </Form.Field>
              <Form.Field width={4}>
                <Button size='mini' content="Durchsuchen" labelPosition="left" icon="file" onClick={() => fileInputRef.current.click()} />
              </Form.Field>
            </Form.Group>
            <Divider />
            <Container textAlign='right'>
              <Button size='mini' icon labelPosition='left' onClick={() => {
                dispatch(setSelectMode())
                dispatch(resetNewCorrection())
                dispatch(abortCorrection(_lastCorrection))
                dispatch(hideDialog())
              }}
              >Abbrechen<Icon name='close' /></Button>
              <Button size='mini' icon labelPosition='left' onClick={(e) => {
                dispatch(setSelectMode())
                dispatch(applyNewCorrection(_config.domain, {id: c_dataPage.id, number: c_dataPage.number}))
                dispatch(hideDialog())
              }} primary>bestätigen<Icon name='check' /></Button>
            </Container>
          </Form>
        </Container>
      </Modal.Content>
    </Modal>
  );
}


export default WECCorrectorEditorNewCorrection;