// React base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Confirm, Icon, Item } from 'semantic-ui-react';

// App
import './confirm.css';

// Redux
import { isVisible, getSettings, hideConfirm } from './confirm.slice';


/**
 * 
 * @param {Object} config 
 */
const WEConfirm = () => {
  const dispatch = useDispatch();
  const visible = useSelector(isVisible) 
  const settings = useSelector(getSettings) 

  if (!visible) {
    return <></>
  }

  return (
    <Confirm
      className = 'wec_confirm'
      open = {visible}
      header = {
        <Item>
          <Item.Content verticalAlign='middle'>
            <Item.Header>
              <Icon size='large' color='yellow' name='exclamation triangle'/>
              Achtung!
            </Item.Header>
          </Item.Content>
        </Item>
      }
      content = {<><span className='Message'>{settings.message}</span><div className="AdditionalContent">{settings.addContent}</div></>}
      cancelButton = {settings.cancelButton}
      confirmButton = {settings.confirmButton}
      onCancel = {()=>dispatch(hideConfirm())}
      onConfirm = {()=>{
        dispatch(hideConfirm());
        settings.onConfirm();
      }}
  />
  );
}


export default WEConfirm;