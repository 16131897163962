// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { selectActiveModule, selectBaseProtocol, selectAuthUrl, setActiveModule } from '../../WECorrect.slice';
import { selectProject, selectCorrectionLevel, selectPage, selected, dataProject, dataCorrectionLevel, dataPage } from '../corrector/corrector.slice';
import { resetProjects } from '../corrector/projects.slice';
import { logoutUser, selectUser } from '../login/login.slice';
import { editUser } from '../user/user.slice';
import { showSystem } from '../system/system.slice';
import { isSaving } from '../corrector/editor/editor.slice';

// semantic ui
import { Menu, Breadcrumb, Image, Header } from 'semantic-ui-react';

// Styles
import './menu.css';



/**
 * 
 * @returns 
 */
const WECMenuBreadcrumb = () => {
  const dispatch  = useDispatch();
  const activeModule = useSelector(selectActiveModule);
  const c_selected = useSelector(selected);
  const c_project = useSelector(dataProject);
  const c_correctionlevel = useSelector(dataCorrectionLevel);
  const c_page = useSelector(dataPage);

  // translation
  const { t } = useTranslation('dashboard', 'common');

  let myBreadcrumb = <></>;

  switch (activeModule) {
    case 'dashboard':
      myBreadcrumb = (
        <Breadcrumb>
          <Breadcrumb.Section link onClick={()=>{
              var newurl = window.location.protocol + "//" + window.location.host;
              window.history.pushState({path:newurl}, '', newurl);
              dispatch(setActiveModule('dashboard'))
              dispatch(selectProject(null));
              dispatch(resetProjects());
            }}>{t('dashboard:MENU.Dashboard')}</Breadcrumb.Section>
        </Breadcrumb>);
      break; 
    case 'corrector': 
      myBreadcrumb = (
        <Breadcrumb>
          <Breadcrumb.Section link onClick={()=>{
              var newurl = window.location.protocol + "//" + window.location.host;
              window.history.pushState({path:newurl}, '', newurl);
              dispatch(setActiveModule('dashboard'))
              dispatch(selectProject(null));
              dispatch(resetProjects());
            }}>{t('dashboard:MENU.Dashboard')}</Breadcrumb.Section>
          {c_selected.project && (
            <React.Fragment>
              <Breadcrumb.Divider icon='right chevron' />

              {c_project.archived && (
                <React.Fragment>
                  <Breadcrumb.Section link>Archiv</Breadcrumb.Section>  
                  <Breadcrumb.Divider icon='right chevron' />
                </React.Fragment>
              )}
              {!c_selected.correctionlevel && (
                <Breadcrumb.Section active>{c_project.title}</Breadcrumb.Section>  
              )}
              {c_selected.correctionlevel && (
                <React.Fragment>
                  <Breadcrumb.Section link onClick={()=>{dispatch(selectCorrectionLevel(null))}}>{c_project.title}</Breadcrumb.Section>  
                  <Breadcrumb.Divider icon='right chevron' />
                  {!c_selected.page && (
                    <Breadcrumb.Section active>{c_correctionlevel.title}</Breadcrumb.Section>
                  )}
                  {c_selected.page && (
                    <React.Fragment>
                      <Breadcrumb.Section link onClick={()=>{dispatch(selectPage(null))}}>{c_correctionlevel.title}</Breadcrumb.Section>
                      <Breadcrumb.Divider icon='right chevron' />
                      <Breadcrumb.Section active>{t('dashboard:MENU.Seite')} {c_page.number}</Breadcrumb.Section>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Breadcrumb>
      );
      break;
    case 'archive': 
      myBreadcrumb = (
        <Breadcrumb>
          <Breadcrumb.Section link onClick={()=>{
              dispatch(setActiveModule('dashboard'))
              dispatch(selectProject(null));
              dispatch(resetProjects());
            }}>Dashboard</Breadcrumb.Section>
            <Breadcrumb.Divider icon='right chevron' />
            <Breadcrumb.Section link onClick={()=>{
              dispatch(selectProject(null));
              dispatch(resetProjects());
              dispatch(setActiveModule('archive'))
            }}>Archiv</Breadcrumb.Section>  
            <Breadcrumb.Divider icon='right chevron' />
            <Breadcrumb.Section active>Projekte</Breadcrumb.Section>
        </Breadcrumb>
      );
      break;
    default: 
  }

  return myBreadcrumb;
}



/**
 * 
 */
const WECMenu = () => {
  const dispatch  = useDispatch();
  const authUrl = useSelector(selectBaseProtocol) + '//' + useSelector(selectAuthUrl);
  const user = useSelector(selectUser);
  const e_isSaving = useSelector(isSaving);

  // translation
  const { t } = useTranslation('dashboard', 'common');

  //
  let logo_className = '';
  let logo_text_src = '/media/logos/WE_Correct_logo_correct_white.svg';
  if (e_isSaving) {
    logo_className = 'saving'
    logo_text_src = '/media/logos/WE_Correct_logo_saving_white.svg';
  }

  return (
    <Menu secondary className='wec_menu'>
      <Menu.Item>
        <Image className={'wecicon ' + logo_className} src='/media/logos/WE_Correct_logo_WE_white.svg' />
        <Image className={'wectext ' + logo_className} src={logo_text_src} />
      </Menu.Item>
      <Menu.Item>
        <WECMenuBreadcrumb />
      </Menu.Item>
      <Menu.Menu position='right'>
        {user.typeObject.name !== 'Gast' && (
          <Menu.Item>
            <Header as='h4' onClick={()=>{ dispatch(selectProject(null)); dispatch(resetProjects()); dispatch(setActiveModule('archive')); }}>
              <Image className='icon' src='/media/icons/WE_correct_icon_white_archiv.svg' /> {t('dashboard:MENU.Archiv')}
            </Header>
          </Menu.Item>
        )}
        <Menu.Item>
          <Header as='h4' onClick={()=>dispatch(editUser(user.id))}>
            <Image className='icon' src='/media/icons/WE_correct_icon_white_profil.svg' /> {t('dashboard:MENU.Profil')}
          </Header>
        </Menu.Item>
        {user.typeObject.name !== 'Gast' && (
          <Menu.Item>
            <Header as='h4' onClick={()=>dispatch(showSystem())}>
              <Image className='icon' src='/media/icons/WE_correct_icon_white_systemeinstellungen.svg' /> {t('dashboard:MENU.System')}
            </Header>
          </Menu.Item>
        )}
        <Menu.Item>
          <Header as='h4' onClick={()=>dispatch(logoutUser({ authUrl : authUrl }))}>
            <Image className='icon' src='/media/icons/WE_correct_icon_white_ausloggen.svg' /> {t('dashboard:MENU.Abmelden')}
          </Header>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
}

export default WECMenu;