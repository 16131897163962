// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { isDialogVisible, hideDialog, changeCorrection, applyCorrection, resetCorrection, getCorrection, getFile } from './dialogCorrection.slice';
import { setSelectMode } from './editor.slice';

// semantic ui
import { Button, TextArea, Modal, Header, Form, Icon, Input, Container, Divider } from 'semantic-ui-react';

// App
import WECCorrectorEditorEdit from "./editorEdit"

// Styles
import './editor.css';



/**
 *
 * @returns 
 */
const WECCorrectorEditorCorrection = () => {
  const dispatch = useDispatch();
  const _Correction = useSelector(getCorrection);
  const _File = useSelector(getFile);
  const _isDialogVisible = useSelector(isDialogVisible);
  const fileInputRef = React.createRef();

  // translation
  const { t } = useTranslation('corrector');

  //
  if (!_isDialogVisible) {
    return <></>
  }

  // filename
  const _FileName = _File && typeof _File.name !== 'undefined' ? _File.name : '';

  return (
    <Modal 
      open = {true}
      className = 'wec_editor_newcorrection' 
      size = 'large' 
      closeIcon 
      closeOnEscape = {false} 
      closeOnDimmerClick = {false}
      onClose = {() => dispatch(hideDialog())}
    >
      <Modal.Content>
        <Container className='preview'>
          <WECCorrectorEditorEdit />          
        </Container>
        <Container className='data'>
          <Header as='h1'>{t('corrector:EDITOR_CORRECTION.Korrektur bearbeiten')}</Header>
          <Form>
            <Form.Field>
              <label>{t('corrector:EDITOR_CORRECTION.Was soll korrigert werden')}</label>
              <TextArea rows={6} id='newcorrection-content' size="small" placeholder={t('corrector:EDITOR_CORRECTION.Nachricht hinzufügen')} value={_Correction.comment.content} onChange={(e, {value})=>dispatch(changeCorrection({key: 'content', value: value}))}></TextArea>
            </Form.Field>
            <Form.Field><label>&nbsp;</label></Form.Field>
            <Form.Group widths='equal'>
              <Form.Field width={12}>
                <label>{t('corrector:EDITOR_CORRECTION.Anhang')}</label>
                <input 
                  ref = {fileInputRef}
                  type = "file" 
                  hidden 
                  onBlur = {(e)=>dispatch(changeCorrection({key: 'file', value: e.target.files[0]}))} />
                <Input size="small" fluid readOnly value={_FileName} placeholder={t('corrector:EDITOR_CORRECTION.Datei vom Computer')} />
              </Form.Field>
              <Form.Field width={4}>
                <Button size='mini' content={t('corrector:EDITOR_CORRECTION.Durchsuchen')} labelPosition="left" icon="file" onClick={() => fileInputRef.current.click()} />
              </Form.Field>
            </Form.Group>
            <Divider />
            <Container textAlign='right'>
              <Button size='mini' icon labelPosition='left' onClick={() => {
                dispatch(setSelectMode())
                dispatch(resetCorrection())
                dispatch(hideDialog())
              }}
              >{t('corrector:EDITOR_CORRECTION.Abbrechen')}<Icon name='close' /></Button>
              <Button size='mini' icon labelPosition='left' onClick={(e) => {
                dispatch(setSelectMode())
                dispatch(applyCorrection())
                dispatch(hideDialog())
              }} primary>{t('corrector:EDITOR_CORRECTION.bestätigen')}<Icon name='check' /></Button>
            </Container>
          </Form>
        </Container>
      </Modal.Content>
    </Modal>
  );
}


export default WECCorrectorEditorCorrection;