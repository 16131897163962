// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// semantic ui
import { Container, Divider, Header, Image } from 'semantic-ui-react';

// Redux
import { isDomains, toggleDomains } from './archive.slice';

// App
import WECProjects from "../corrector/projects";

// Styles
import './archive.css';




/**
 * 
 * @param {Array} domains 
 * @returns 
 */
 const WECDomains = ({domains}) => {

  const domainList = domains.map((domain) => {
      return (
          <Image as='a' title={domain.name} src={'/media/logos/domain_' + domain.id + '.png'} onClick={()=>{}}/>
      )
  });

  return (
      <>
          <Header as='h3'>Kunden</Header>
          <Divider />
          <Container className='List'>
              {domainList}
          </Container>
      </>
  )
}




/**
 * 
 */
const WECArchive = ({config}) => {
  const dispatch = useDispatch();
  const _isDomains = useSelector(isDomains)

  //
  let domains = [];
  if (config && typeof config.domains !== 'undefined') {
      const _domains = Object.assign([], config.domains);
      _domains.forEach(domain => {
          if (typeof config[domain] !== 'undefined') {
              domains.push(Object.assign({id: domain}, config[domain]));
          }
      });
  }

  // check visibility of sidebars
  const hideDomains = _isDomains ? {} : {display: 'none'};
  const hiderDomains = _isDomains ? 'links' : 'rechts';
  const hiderDomainsTT = _isDomains ? 'Kundenliste ausblenden' : 'Kundenliste einblenden';
  

  // archive size
  const _isNews = false;
  let contentSize = 'small'
  if ( _isDomains && !_isNews) contentSize = 'medium2';
  if (!_isDomains && !_isNews) contentSize = 'large';
  
  return (
    <div className='wec_archive'>
      <div className='Domains' style={hideDomains}>
        <WECDomains domains={domains} />
      </div>
      <div class="HiderLeft">
        <Image src={'/media/icons/WE_correct_icon_chevron-' + hiderDomains + '.svg'} title={hiderDomainsTT} onClick={()=>dispatch(toggleDomains())} />
      </div>
      <div className={'ArchiveContent ' + contentSize}>
        <WECProjects domains={domains} config={config} listStyle='table' archive={true} />
      </div>
    </div>
  );
}

export default WECArchive;