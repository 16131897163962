import { createSlice } from '@reduxjs/toolkit';
import { fileUpload, fetchPOST } from "../../toolbox/requestor.slice";
import { setCorrectionNH, setSaving } from './editor.slice'
import { addMessage } from '../../snackbar/snackbar.slice';




/**
 * 
 */
export const dialogAddCommentSlice = createSlice({
  name: 'dialogAddComment',
  initialState: {
    dialogVisible : false,
    projectDomain: null,
    correction: null,
    newComment: {
      text: '',
      file: null
    },
  },

  reducers: {
    hideDialog: (state) => { 
      state.dialogVisible = false;
      state.projectDomain = null;
      state.newComment.file = null;
      state.newComment.text = '';
    },
    showDialog: (state, action) => { 
      state.dialogVisible = true;
      if (typeof action.payload.domain !== 'undefined') {
        state.projectDomain = action.payload.domain;
      }
      if (typeof action.payload.correction !== 'undefined') {
        state.correction = action.payload.correction;
      }
    },
    toggleDialog: (state) => { state.dialogVisible = !state.dialogVisible; },
    setFile: (state, action) => { state.newComment.file = action.payload },
    setText: (state, action) => { state.newComment.text = action.payload },
  },
});

export const { hideDialog, showDialog, toggleDialog, setFile, setText } = dialogAddCommentSlice.actions;

export const isDialogVisible = (state) => state.dialogAddComment.dialogVisible;
export const projectDomain = (state) => state.dialogAddComment.projectDomain;
export const getFile = (state) => state.dialogAddComment.newComment.file;
export const getText = (state) => state.dialogAddComment.newComment.text;

export default dialogAddCommentSlice.reducer;




/**
 * 
 * @param {*} props 
 */
 export function addComment(replyData) {
  return async (dispatch, getState) => {
    const state = getState();
    const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
    const attachmentUrl = state.wec.baseProtocol + '//' + state.wec.attachmentUrl;

    let _file = null;

    // start saving animation
    dispatch(setSaving(true));

    // Upload attachment
    if (replyData.file) {
      const filename = await fileUpload(attachmentUrl, replyData.file, (progressData)=>{
        console.log(progressData);
      });
      _file = filename;
    }
    replyData.file = _file;

    // create reply
    try {
      dispatch(fetchPOST(backendUrl + '/api/latest/' + replyData.domain + '/correction/' + replyData.correctionId + '/reply', replyData)).then(
        correction => {
          dispatch(setCorrectionNH(correction))
          dispatch(hideDialog());
          dispatch(addMessage({type: 'NOTICE', text: 'Kommentar hinzugefügt'}));
          dispatch(setSaving(false)); 
        }
      ).catch((error)=>{
        console.error(error);
        dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Speichern des Kommentars'}));
      });
    } catch (error) {
      console.error(error);
      dispatch(addMessage({type: 'ERROR', header: 'Fehler',  text: 'Fehler beim Speichern des Kommentars'}));
    }
  }
}