import { createSlice } from '@reduxjs/toolkit';


/**
 * 
 */
export const userCLStatesSlice = createSlice({
  name: 'userCLStates',
  initialState: {
    visible: false
  },

  reducers: {
    hideUserCLStates: (state) => { state.visible = false },
    showUserCLStates: (state) => { state.visible = true },
  },
});

export const { hideUserCLStates, showUserCLStates } = userCLStatesSlice.actions;

export const isVisible = (state) => state.userCLStates.visible;


export default userCLStatesSlice.reducer;