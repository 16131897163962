import { createSlice } from '@reduxjs/toolkit';


export const confirmSlice = createSlice({
  name: 'confirm',
  initialState: {
    visible: false,
    settings: {
      cancelButton: 'Abbrechen',
      confirmButton: 'Bestätigen',
      onConfirm: null,
      message: 'Sind Sie sicher?',
      addContent: <></>
    }
  },

  reducers: {
    setSettings: (state, action) => {
      if (typeof action.payload.cancelButton !== 'undefined') state.settings.cancelButton = action.payload.cancelButton
      if (typeof action.payload.confirmButton !== 'undefined') state.settings.confirmButton = action.payload.confirmButton
      if (typeof action.payload.message !== 'undefined') state.settings.message = action.payload.message
      if (typeof action.payload.onConfirm !== 'undefined') state.settings.onConfirm = action.payload.onConfirm
      if (typeof action.payload.addContent !== 'undefined') state.settings.addContent = action.payload.addContent
    },
    hideConfirm: (state) => { state.visible = false; },
    showConfirm: (state, action) => { 
      if (typeof action.payload.cancelButton !== 'undefined') state.settings.cancelButton = action.payload.cancelButton
      if (typeof action.payload.confirmButton !== 'undefined') state.settings.confirmButton = action.payload.confirmButton
      if (typeof action.payload.message !== 'undefined') state.settings.message = action.payload.message
      if (typeof action.payload.onConfirm !== 'undefined') state.settings.onConfirm = action.payload.onConfirm
      if (typeof action.payload.addContent !== 'undefined') state.settings.addContent = action.payload.addContent
      state.visible = true;
    },
  },
});

export const { setSettings, showConfirm, hideConfirm } = confirmSlice.actions;

export const isVisible = state => state.confirm.visible;
export const getSettings = state => state.confirm.settings;

export default confirmSlice.reducer;
