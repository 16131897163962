// react base
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Redux
import { messages, deleteMessage } from './snackbar.slice';

// Semantic-UI
import { Container, Message } from 'semantic-ui-react';

// Styles
import './snackbar.css';






/**
 * 
 * @param {Object} message 
 * @returns 
 */
const WECSnackbarMessage = ({message}) => {
  const dispatch = useDispatch();
  let TIMER;

  //
  function handleTimeout() {
    TIMER = setTimeout(() => {
      dispatch(deleteMessage(message.id));
    }, message.timeout);
  }

  //
  function handleClose() {
    clearTimeout(TIMER);
    dispatch(deleteMessage(message.id));
  }

  //
  useEffect(() => {
    handleTimeout();
    return () => {
      clearTimeout(TIMER);
    };
  }, [TIMER]);

  //
  //onDismiss = {handleClose}
  switch (message.type) {
    case 'NOTICE': 
      return (
        <Message floating onDismiss={()=>handleClose()}>
          <p>{message.text}</p>
        </Message>
      );
    case 'ERROR':
      return (
        <Message 
          floating 
          negative
          icon = 'warning sign'
          onDismiss = {()=>handleClose()}
          content = {message.text}
          header = {message.header}
          style = {{zIndex: '2001'}}
        />
      );
    default: return <></>
  }

  return  <></>
}




/**
 * Usage:
 * 
 *  const msg1 = {type: 'NOTICE', text: 'Lorem ipsum dolor set amet consectetur'}
 *  const msg2 = {type: 'ERROR', header:'Fehler beim XXX', text: 'Lorem ipsum dolor set amet consectetur'}
 *  dispatch(addMessage(msg1));
 *  dispatch(addMessage(msg2));
 * 
 */
const WECSnackbar = () => {
  const _messages = useSelector(messages);

  return (
    <Container className='WECSnackbar' style={{zIndex: '2000'}}>
      {_messages.map((message)=>{
        return (
          <WECSnackbarMessage message={message} />
        );
      })}
    </Container>
  );
}

export default WECSnackbar;