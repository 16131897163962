// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { isVisible, hideUserCLStates } from './userCLStates.slice';
import { dataCorrectionLevel, setCorrectionLevelState } from '../corrector.slice';
import { selectAttendees } from '../project/attendees.slice';
import { selectConfig } from '../../../WECorrect.slice';
import { showMessageSingle } from '../../message/message.slice';

// semantic ui
import { Button, Modal, Header, Icon, Table } from 'semantic-ui-react';



/**
 * 
 * @returns 
 */
const WECUserCLStates = () => {
  const dispatch = useDispatch();
  const visible = useSelector(isVisible);
  const correctionlevel = useSelector(dataCorrectionLevel);
  const attendees = useSelector(selectAttendees);
  const config = useSelector(selectConfig);

  // translation
  const { t } = useTranslation('corrector');
  
  // check if visible
  if (!visible) {
    return (<></>);
  }

  //determine correctors role
  let ids_role_corrector = [];
  const roles = config[config.domain].user.roles;
  for (const key in roles) {
    if (Object.hasOwnProperty.call(roles, key)) {
      const role = roles[key];
      if (role.type === 'S_CORRECTOR') {
        ids_role_corrector.push(role.id)
      }  
    }
  }
  
  // determine correctors
  let correctors = [];
  for (const roleId in attendees) {
    if (Object.hasOwnProperty.call(attendees, roleId)) {
      if (ids_role_corrector.includes(parseInt(roleId))) {
        const roleAttendees = attendees[roleId];
        roleAttendees.forEach(roleAttendee => {
          correctors.push(roleAttendee);
        });
      }   
    }
  }
  
  // build table rows
  const correctorRows = correctors.map(corrector => {
    let correctorUser = null;
    config[config.domain].user.users.forEach(usr => {
      if (usr.id === corrector.userid) {
        correctorUser = usr;
      }
    });

    //
    let userstate_text = null;
    let cl_locked = false;
    correctionlevel.userstates.forEach(userstate => {
      if (userstate.user === correctorUser.id) {
        if (userstate.state === 'locked') {
          userstate_text = t('corrector:USERSTATES.Freigegeben');
          cl_locked = true;
        }
      }
    });
    if (!cl_locked) {
      let lockedPages = 0;
      correctionlevel.pages.forEach(page => {
        page.userstates.forEach(page_userstate => {
          if (page_userstate.user === correctorUser.id && page_userstate.state === 'locked') {
            lockedPages++;
          }
        });
      });
      if (lockedPages > 0) {
        userstate_text = t('corrector:USERSTATES.in Bearbeitung 1', lockedPages);
      } else {
        userstate_text = t('corrector:USERSTATES.in Bearbeitung 2');
      }
    }

    // 
    let usrCorrectionsAll = [];
    let usrCorrectionsA = [];
    let usrCorrectionsR = [];
    correctionlevel.pages.forEach(page => {
      page.corrections.forEach(correction => {
        if (typeof correction.log !== 'undefined' && typeof correction.log.username !== 'undefined' && correction.log.username === correctorUser.username) {
          usrCorrectionsAll.push(correction);
          switch (correction.state) {
            case 'A': usrCorrectionsA.push(correction); break;
            case 'B': usrCorrectionsR.push(correction); break;
            default:
          }
        }
      });  
    });
    const qualified = usrCorrectionsAll.length ? Math.round(100 * (usrCorrectionsA.length + usrCorrectionsR.length) / usrCorrectionsAll.length) : 0;
    
    return (
      <Table.Row>
        <Table.Cell collapsing>
          {corrector.details.forename} {corrector.details.surname}&nbsp;&nbsp;<Icon link name='envelope outline' onClick={()=>dispatch(showMessageSingle(correctorUser))}/>
        </Table.Cell>
        <Table.Cell collapsing>{userstate_text}</Table.Cell>
        <Table.Cell collapsing>{usrCorrectionsAll.length}</Table.Cell>
        <Table.Cell collapsing>{usrCorrectionsA.length}</Table.Cell>
        <Table.Cell collapsing>{usrCorrectionsR.length}</Table.Cell>
        <Table.Cell collapsing>{qualified}%</Table.Cell>
        <Table.Cell collapsing textAlign='right'>
          {!cl_locked && (<Button icon labelPosition='left' size='mini' onClick={()=>{
            dispatch(setCorrectionLevelState(correctionlevel.domain, correctionlevel.id, 'locked', correctorUser.id, correctorUser.username))

          }}><Icon name='check' />{t('corrector:USERSTATES.Alle Seiten abschließen')}</Button>)}
        </Table.Cell>
      </Table.Row>
    )
  })

  


  return (
    <Modal open={visible} className='wec_userclstates' size='xlarge' closeOnEscape={false} closeOnDimmerClick={false}>
      <Modal.Content>
        <Header as='h1'>{t('corrector:USERSTATES.Übersicht Benutzerstatus')}</Header>
        <Table basic='very' selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell collapsing>{t('corrector:USERSTATES.Kontrolleur')}</Table.HeaderCell>
              <Table.HeaderCell collapsing>{t('corrector:USERSTATES.Status')}</Table.HeaderCell>
              <Table.HeaderCell collapsing>{t('corrector:USERSTATES.Gesamt')}</Table.HeaderCell>
              <Table.HeaderCell collapsing>{t('corrector:USERSTATES.Angenommen')}</Table.HeaderCell>
              <Table.HeaderCell collapsing>{t('corrector:USERSTATES.Abgelehnt')}</Table.HeaderCell>
              <Table.HeaderCell collapsing>{t('corrector:USERSTATES.Qualifiziert')}</Table.HeaderCell>
              <Table.HeaderCell collapsing></Table.HeaderCell>
              </Table.Row>
          </Table.Header>
          <Table.Body>
            {correctorRows}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button size='mini' icon labelPosition='left' onClick={() => dispatch(hideUserCLStates())}>{t('corrector:USERSTATES.Schließen')}<Icon name='close' /></Button>
      </Modal.Actions>
    </Modal>
  );
}

export default WECUserCLStates;


