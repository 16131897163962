import { createSlice } from '@reduxjs/toolkit';
import { fetchGET } from "../../toolbox/requestor.slice";
import { changeCompare } from "./editor.slice";



/**
 * 
 */
export const dialogCompareSlice = createSlice({
  name: 'dialogCompare',
  initialState: {
    dialogVisible : false,
    projectDomain: null
  },
  reducers: {
    hideDialog : (state) => { 
      state.dialogVisible = false;
      state.projectDomain = null;
    },
    showDialog : (state, action) => { 
      state.dialogVisible = true;
      if (typeof action.payload.domain !== 'undefined') {
        state.projectDomain = action.payload.domain;
      }
    },
    toggleDialog : (state) => { state.dialogVisible = !state.dialogVisible; },
  },
});

export const { hideDialog, showDialog, toggleDialog } = dialogCompareSlice.actions;

export const isDialogVisible = (state) => state.dialogCompare.dialogVisible;
export const projectDomain = (state) => state.dialogCompare.projectDomain;

export default dialogCompareSlice.reducer;






/**
 * 
 * @param {String} domain
 */
 export function loadComparedProjects(domain) {  
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      
      // filter/sorting
      let options = {
        domain: domain, 
        filter: { active: true },
        sorting: { title: 'ASC' }
      }
      
      // get list of projects
      const options_str = btoa( unescape(encodeURIComponent(JSON.stringify(options)))).replace(/=/g, '_');
      dispatch(fetchGET(backendUrl + '/api/latest/'+domain+'/projects/?options=/' + options_str)).then(
        projects => dispatch(changeCompare({...state.editor.compare, projects: projects}))
      );
    } catch (error) {
      console.error(error);
    }
  }
}



/**
 * @param {String} domain
 * @param {integer} projectId
 */
 export function loadComparedCLs(domain, projectId) {  
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      // get project data and push correctionleves into store
      dispatch(fetchGET(backendUrl + '/api/latest/'+domain+'/project/' + projectId)).then(
        project => dispatch(changeCompare({
          ...state.editor.compare, 
          projectId: project.id,
          project: project,
          correctionLevels: project.correctionLevels
        }))
      );
    } catch (error) {
      console.error(error);
    }
  }
}



/**
 * @param {String} domain
 * @param {integer} clevelId
 */
 export function loadComparedCL(domain, clevelId) {  
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      // get CorrctionLevel data and push it into store
      dispatch(fetchGET(backendUrl + '/api/latest/'+domain+'/correctionlevels/' + clevelId)).then(
        correctionlevel => {
          dispatch(changeCompare({
            ...state.editor.compare, 
            correctionLevelId: clevelId,
            correctionLevel: correctionlevel,
            pageId: correctionlevel.pages[0].id,
            pageKey: 0
          }));
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}