import { createSlice } from '@reduxjs/toolkit';
import { fetchDELETE } from "../toolbox/requestor.slice";
import { addMessage } from '../snackbar/snackbar.slice'




export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    visible: false,
    teans: null,
    filter: null
  },

  reducers: {
    showRoles: (state) => { 
      state.visible = true;
    },
    hideRoles: (state) => {
      state.visible = false;
      state.teans = null;
    },
    setRoles: (state, actions) => {
      state.roles = actions.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    }
  }
});
export const { showRoles, hideRoles, setRoles, setFilter } = rolesSlice.actions;
export const isVisible = (state) => state.roles.visible;
export const getRoles = (state) => state.roles.roles;
export const getFilter = (state) => state.roles.filter;

export default rolesSlice.reducer;




export function deleteRole(role) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const authUrl = state.wec.baseProtocol + '//' + state.wec.authUrl;
      if (typeof role.id !== 'undefined' && role.id) {
        dispatch(fetchDELETE(authUrl + '/api/role/'+role.id)).then(
          role => {
            dispatch(addMessage({type: 'NOTICE', text: 'Role ' + role.name + ' wurde gelöscht'}));
            dispatch(setRoles(null))
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
}