// react base
import React, { useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Modal, Header, Button, Icon, Input, Grid, Label } from 'semantic-ui-react';

// Redux
import { selectPasswordCheck, validatePassword, saveUser } from '../user/user.slice';
import { selectUser, selectToken } from '../login/login.slice';
import { selectAuthUrl, selectBaseProtocol } from '../../WECorrect.slice';



/**
 *
 */
const WECResetPW = () => {
    // local state
    const [visible, setVisible] = useState(true);
    const [password, setPassword] = useState('');
    const [rpassword, setRPassword] = useState('');
    
    // global state
    const dispatch      = useDispatch();
    const passwordCheck = useSelector(selectPasswordCheck);
    const _user         = useSelector(selectUser);
    const authUrl       = useSelector(selectBaseProtocol) + '//' + useSelector(selectAuthUrl);
    const token         = useSelector(selectToken);

    // translation
    const { t } = useTranslation('user');

    // visibility check
    if (!visible){
        return <></>;
    }

    // Error check password
    let key_color;
    switch (passwordCheck.strength) {
        case 4: key_color = 'green'; break;
        case 3: key_color = 'olive'; break;
        case 2: key_color = 'yellow'; break;
        case 1: key_color = 'orange'; break;
        default: key_color = 'red';
    }
    if (typeof password !== 'undefined' && password && !password.length) {
        key_color = 'red';
    }
    let error_pw    = passwordCheck.length && passwordCheck.identically ? '' : 'error';
    let error_pw_label  = <></>;
    if (!passwordCheck.length)      error_pw_label = (<Label basic color='red' pointing>{t('user:BASICS.MESSAGES.Password must consist of at least 8 characters')}</Label>);
    if (!passwordCheck.identically) error_pw_label = (<Label basic color='red' pointing>{t('user:BASICS.MESSAGES.Passwords do not match')}</Label>);

    // Save data
    let saveData = { 
        token : token, 
        authUrl : authUrl,
        id : _user.id, 
        mandant : _user.domain, 
        username : _user.username, 
        email : _user.email, 
        password : password, 
        rpassword : rpassword,
        type: _user.type,
        role: _user.role,
        active: _user.active,
        language: _user.language
    }

    
    return (
        <Modal
            open = {visible}
            className = 'weclostpw'
            size = 'small'
            closeIcon 
            closeOnEscape = {false} 
            closeOnDimmerClick = {false}
            onClose = {() => setVisible(false)}
        >
            <React.Fragment>
                <Modal.Content>
                    <Header>{t('user:RESETPW.LABELS.Passwort setzen')}</Header>
                    <Grid>
                        <Grid.Row style={{padding: '3px', paddingTop: '30px'}}>
                            <Grid.Column width={4}><label>{t('user:RESETPW.LABELS.Domain')}</label></Grid.Column>
                            <Grid.Column width={12}>{_user.domain}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{padding: '3px'}}>
                            <Grid.Column width={4}><label>{t('user:RESETPW.LABELS.Username')}</label></Grid.Column>
                            <Grid.Column width={12}>{_user.username}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{padding: '3px', paddingTop: '20px'}}>
                            <Grid.Column width={4}><label>{t('user:RESETPW.LABELS.Password')}</label></Grid.Column>
                            <Grid.Column width={12}>
                                <Input
                                    fluid 
                                    type = 'password' 
                                    size = 'mini'
                                    icon = {<Icon name='key' color={key_color} />} 
                                    iconPosition = 'left' 
                                    placeholder = {t('user:RESETPW.LABELS.Password')}
                                    className = {error_pw}
                                    value = {password} 
                                    onChange = {(event) => setPassword(event.target.value)} 
                                    onBlur = {(event) => dispatch(validatePassword({ password : event.target.value, rpassword : rpassword }))}
                                />
                                {error_pw_label}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{padding: '3px', paddingBottom: '30px'}}>
                            <Grid.Column width={4}><label>{t('user:RESETPW.LABELS.Repeat Password')}</label></Grid.Column>
                            <Grid.Column width={12}>
                                <Input 
                                    fluid 
                                    type = 'password'
                                    size = "mini"
                                    icon = 'key'
                                    iconPosition = 'left'
                                    placeholder = {t('user:BASICS.LABELS.Repeat Password')}
                                    className = {error_pw}
                                    value = {rpassword}
                                    onChange ={(event)=>setRPassword(event.target.value)} 
                                    onBlur = {(event) => dispatch(validatePassword({ rpassword : event.target.value, password : password }))}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button icon labelPosition='left' onClick={()=>setVisible(false)}><Icon name='times' />{t('user:RESETPW.LABELS.Schließen')}</Button>
                    <Button primary icon labelPosition='left' onClick={()=>{
                        dispatch(saveUser(saveData));
                        setVisible(false);
                    }}><Icon name='plus' />{t('user:RESETPW.LABELS.Passwort setzen')}</Button>
                </Modal.Actions>
            </React.Fragment>
        </Modal>
    );
}

export default WECResetPW;