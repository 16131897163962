// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Modal, Button } from 'semantic-ui-react';

// Redux
import { showNewUserModal, isListUsersVisible, hideModal, showEditUserModal, selectReload } from './user.slice';
import { selectUser } from '../login/login.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// App
import WECUserListContent from './listContent';

// Styles
import './user.css';



/**
 * 
 */
const WECUserList = () => {
  const dispatch  = useDispatch();
  const visible = useSelector(isListUsersVisible); 
  const reload = useSelector(selectReload);
  const userLoggedIn  = useSelector(selectUser);
  const open = true;

  // translations
  const { t } = useTranslation('user', 'common');
  
  // check if modal is visible
  if (!visible) {
    return <></>
  }

  // Permissions
  let permissionHandler = new WECPermissionHandler();
  const permission_PERM_SYS_02 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_02') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);

  return (
    <Modal 
      open = {open} 
      className = 'ts_user_list' 
      id = 'ts_user_list' 
      size = 'fullscreen' 
      closeIcon 
      closeOnEscape = {false} 
      closeOnDimmerClick = {false}
      onClose = {() => dispatch(hideModal({ modal : 'ListUser' }))}
    >
      <Modal.Content>
        <WECUserListContent onRowClick={(user)=>dispatch(showEditUserModal(user))} reload={reload} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => dispatch(hideModal({ modal : 'ListUser' }))}>{t('user:LIST.BUTTONS.Close')}</Button>
        {permission_PERM_SYS_02 && (
          <Button primary onClick={() => dispatch(showNewUserModal())}>{t('user:LIST.BUTTONS.Add user')}</Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}

export default WECUserList;