import { createSlice } from '@reduxjs/toolkit';



export const systemSlice = createSlice({
  name: 'system',
  initialState: {
    visible: false,
    teans: null,
    filter: null
  },

  reducers: {
    showSystem: (state) => { 
      state.visible = true;
    },
    hideSystem: (state) => {
      state.visible = false;
      state.teans = null;
    }
  }
});
export const { showSystem, hideSystem } = systemSlice.actions;
export const isVisible = (state) => state.system.visible;

export default systemSlice.reducer;