// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { setAddMode, setScale, scale, compareMode, setCompareMode } from './editor.slice';
import { addMode } from './editor.slice';
import { dataProject, dataPage, setPageState } from '../corrector.slice';
import { changeCorrectionStage } from '../correctionStage.slice';
import { showDialog } from './dialogCompare.slice'
import { selectConfig } from '../../../WECorrect.slice';
import { showMessageMultiple } from '../../message/message.slice';
import { showConfirm } from '../../confirm/confirm.slice';
import { selectUser } from '../../login/login.slice';

// Permissions
import WECPermissionHandler from '../../toolbox/permission_handler';

// semantic ui
import { Button, Input, Icon, Image, Container, Popup } from 'semantic-ui-react';

// Styles
import './menu.css';



/**
 * 
 * @params {Boolean} readOnly
 * @returns 
 */
const WECCorrectorEditorMenu = ({readOnly}) => {
  // locale state
  const [dimensions, setDimensions]   = React.useState(null)
  const [displayMode, setDisplayMode] = React.useState('large')

  // global state
  const dispatch = useDispatch();
  const initialScale  = useSelector(scale);
  const config        = useSelector(selectConfig);
  const c_dataProject = useSelector(dataProject);
  const c_dataPage    = useSelector(dataPage);  
  const e_addMode     = useSelector(addMode);
  const e_compareMode = useSelector(compareMode)
  const userLoggedIn  = useSelector(selectUser);

  // translation
  const { t } = useTranslation('corrector');

  //
  const scalePercent = parseInt(initialScale * 100);
  const _selectedColorActive = e_addMode === 'rect' ? 'active' : null; 
  const _selectedToolActive = e_addMode === 'tool' ? 'active' : null; 
  const _selectedEuroActive = e_addMode === 'euro' ? 'active' : null; 
  const _selectedOKActive = e_addMode === 'ok' ? 'active' : null; 

  // menu dimensions
  React.useEffect(() => {
    const _menu = document.querySelector('.editor_menu');
    function handleResize() {
      setDimensions({height: _menu.clientHeight, width: _menu.clientWidth})
      if (_menu.clientWidth >= 1050) {
        setDisplayMode('large');
      }
      if (_menu.clientWidth < 1050) {
        setDisplayMode('medium');
      }
      if (_menu.clientWidth < 770) {
        setDisplayMode('small');
      }
    }
    if (dimensions === null) {
      handleResize()
    }
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })

  // Permissions
  let permissionHandler = new WECPermissionHandler();
  const permission_PERM_EDT_01 = permissionHandler.hasPermission(userLoggedIn, 'PERM_EDT_01', c_dataProject.attendees, config[config.domain].user.roles) && userLoggedIn.type !== 4;
  const permission_PERM_EDT_02 = permissionHandler.hasPermission(userLoggedIn, 'PERM_EDT_02', c_dataProject.attendees, config[config.domain].user.roles) && userLoggedIn.type !== 4;
  const permission_PERM_EDT_03 = permissionHandler.hasPermission(userLoggedIn, 'PERM_EDT_03', c_dataProject.attendees, config[config.domain].user.roles) && userLoggedIn.type !== 4;
  const permission_PERM_EDT_04 = permissionHandler.hasPermission(userLoggedIn, 'PERM_EDT_04', c_dataProject.attendees, config[config.domain].user.roles) && userLoggedIn.type !== 4;
  const permission_PERM_EDT_06 = permissionHandler.hasPermission(userLoggedIn, 'PERM_EDT_06', c_dataProject.attendees, config[config.domain].user.roles) && userLoggedIn.type !== 4;
  const permission_PERM_EDT_07 = permissionHandler.hasPermission(userLoggedIn, 'PERM_EDT_07', c_dataProject.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
  const permission_PERM_EDT_13 = permissionHandler.hasPermission(userLoggedIn, 'PERM_EDT_13', c_dataProject.attendees, config[config.domain].user.roles) && userLoggedIn.type !== 4;


  // Rect/Tool/Euro/Ok
  const tools = readOnly ? <></> : (
    <>
      {config[config.domain].toolbar.area.active === '1' && permission_PERM_EDT_01 && (<div className='editor_menu_toolbar'><Image title={t('corrector:EDITOR_MENU.Area marker')} className={_selectedColorActive} src='/media/icons/WE_correct_icon_editor-korrektur.svg' size='mini' name='plus' onClick={()=>dispatch(setAddMode('rect'))} /></div>)}
      {config[config.domain].toolbar.tool.active === '1' && permission_PERM_EDT_02 && (<div className='editor_menu_toolbar'><Image title={t('corrector:EDITOR_MENU.Text marker')} className={_selectedToolActive} src='/media/icons/WE_correct_icon_editor-text-groeßer.svg' size='mini' name='text height' onClick={()=>dispatch(setAddMode('tool'))} /></div>)}
      {config[config.domain].toolbar.euro.active === '1' && permission_PERM_EDT_03 && (<div className='editor_menu_toolbar'><Image title={t('corrector:EDITOR_MENU.Currency marker')} className={_selectedEuroActive} src='/media/icons/WE_correct_icon_editor-preis.svg' size='mini' onClick={()=>dispatch(setAddMode('euro'))} /></div>)}
      {config[config.domain].toolbar.ok.active === '1' && permission_PERM_EDT_04 && (<div className='editor_menu_toolbar'><Image title={t('corrector:EDITOR_MENU.OK marker')} className={_selectedOKActive} src='/media/icons/WE_correct_icon_editor-ok.svg' size='mini' onClick={()=>dispatch(setAddMode('ok'))} /></div>)}
    </>
  );


  //determine correctors role
  let ids_role_corrector = [];
  const roles = config[config.domain].user.roles;
  for (const key in roles) {
    if (Object.hasOwnProperty.call(roles, key)) {
      const role = roles[key];
      if (role.type === 'S_CORRECTOR') {
        ids_role_corrector.push(role.id)
      }  
    }
  }



  return (
    <Container className='editor_menu'>
      <div className='editor_menu_zoom'>
        <Input
            type='range'
            min={10}
            max={200}
            step={1}
            smooth={true}
            defaultValue = {scalePercent}
            value={scalePercent}
            onChange = {(e) => dispatch(setScale(e.target.value))}
          />
          <span style={{marginLeft: '10px'}}>{scalePercent}%</span>
      </div>
      {(displayMode === 'medium' || displayMode === 'large' ) && (
        <>
        {tools}
        {permission_PERM_EDT_13 && ( 
          <div className='editor_menu_toolbar'>
            {e_compareMode === 'normal' && (<Image src='/media/icons/WE_correct_icon_editor-vergleichsansicht.svg' size='mini' onClick={()=>dispatch(showDialog({domain: c_dataProject.domain}))} title={t('corrector:EDITOR_MENU.Vergleichsansicht')} />)}
            {e_compareMode === 'compare' && (<Image src='/media/icons/WE_correct_icon_editor-standardansicht.svg' size='mini' onClick={()=>{
              dispatch(setScale(100))
              dispatch(setCompareMode('normal')); 
            }} title={t('corrector:EDITOR_MENU.Standardansicht')}/>)}
          </div>
        )}
        <div className='editor_menu_toolbar'>
          <Image src='/media/icons/WE_correct_icon_benachrichtigen.svg' size='mini' onClick={()=>dispatch(showMessageMultiple({type: 'role', ids: ids_role_corrector}))} title={t('corrector:EDITOR_MENU.Kontrolleure benachrichtigen')} />
        </div>
        </>
      )}
      {(displayMode === 'small' ) && (
        <Popup on={['click']} trigger={<div className='editor_menu_toolbar'><Image src='/media/icons/WE_correct_icon_systemeinstellungen.svg' title={t('corrector:EDITOR_MENU.Werkzeuge u. Aktionen')} size='mini'/></div>} flowing hoverable>
          <div>
            {tools}
            {permission_PERM_EDT_13 && ( 
              <div className='editor_menu_toolbar'>
                {e_compareMode === 'normal' && (<Image src='/media/icons/WE_correct_icon_editor-vergleichsansicht.svg' size='mini' onClick={()=>dispatch(showDialog({domain: c_dataProject.domain}))} title={t('corrector:EDITOR_MENU.Vergleichsansicht')} />)}
                {e_compareMode === 'compare' && (<Image src='/media/icons/WE_correct_icon_editor-standardansicht.svg' size='mini' onClick={()=>{
                  dispatch(setScale(100))
                  dispatch(setCompareMode('normal')); 
                }} title={t('corrector:EDITOR_MENU.Standardansicht')}/>)}
              </div>
            )}
            <div className='editor_menu_toolbar'>
              <Image src='/media/icons/WE_correct_icon_benachrichtigen.svg' size='mini' onClick={()=>dispatch(showMessageMultiple({type: 'role', ids: ids_role_corrector}))} title={t('corrector:EDITOR_MENU.Kontrolleure benachrichtigen')} />
            </div>
          </div>
        </Popup>
        
      )}
      {displayMode === 'large' && (
        <div className='editor_menu_actions'>
          {permission_PERM_EDT_07 && ( 
            <Button icon size='mini' labelPosition='left' floated='right' onClick={()=>dispatch(changeCorrectionStage({key: 'visible', value: true})) }><Icon name='sync' />{t('corrector:EDITOR_MENU.Korrekturphase wechseln')}</Button>
          )}
          {(typeof c_dataPage.userstate === 'undefined' || c_dataPage.userstate.state === 'open' || c_dataPage.userstate === '') && (
            <>
            {permission_PERM_EDT_06 && ( 
              <Button 
                icon 
                primary 
                size = 'mini' 
                labelPosition = 'left' 
                floated = 'right' 
                onClick = {()=>{
                  dispatch(showConfirm({
                    cancelButton: t('corrector:EDITOR_MENU.Abbrechen'),
                    confirmButton: t('corrector:EDITOR_MENU.Seite abschließen'),
                    message: t('corrector:EDITOR_MENU.Seite wirklich abschließen'),
                    onConfirm: ()=>dispatch(setPageState(c_dataProject.domain, c_dataPage.id, 'locked'))}))
                }}
              ><Icon name='lock' />{t('corrector:EDITOR_MENU.Seite abschließen')}</Button>
            )}
            </>
          )}
          {typeof c_dataPage.userstate !== 'undefined' && c_dataPage.userstate.state === 'locked' && (
            <>
            {permission_PERM_EDT_06 && ( 
              <Button 
                icon 
                primary 
                size = 'mini' 
                labelPosition = 'left' 
                floated = 'right' 
                title = {t('corrector:EDITOR_MENU.Seite reaktivieren')}
                onClick = {()=>dispatch(setPageState(c_dataProject.domain, c_dataPage.id, 'open'))}
              ><Icon name='lock open' />{t('corrector:EDITOR_MENU.Seite reaktivieren')}</Button>
            )}
            </>
          )}
        </div>
      )}
      {(displayMode === 'medium' || displayMode === 'small' ) && (
        <div className='editor_menu_actions'>
          {permission_PERM_EDT_07 && ( 
            <Button icon floated='right' title={t('corrector:EDITOR_MENU.Korrekturphase wechseln')} onClick={()=>dispatch(changeCorrectionStage({key: 'visible', value: true})) }><Icon name='sync' /></Button>
          )}
          {typeof c_dataPage.userstate !== 'undefined' && c_dataPage.userstate.state === 'locked' && (
            <>
            {permission_PERM_EDT_06 && ( 
              <Button icon primary floated='right' title={t('corrector:EDITOR_MENU.Seite reaktivieren')} onClick={()=>dispatch(setPageState(c_dataProject.domain, c_dataPage.id, 'open'))}><Icon name='lock open' /></Button>
            )}
            </>
          )}
          {(typeof c_dataPage.userstate === 'undefined' || c_dataPage.userstate.state === 'open' || c_dataPage.userstate === '') && (
            <>
            {permission_PERM_EDT_06 && ( 
              <Button icon primary floated='right' title={t('corrector:EDITOR_MENU.Seite abschließen')} onClick={()=>dispatch(setPageState(c_dataProject.domain, c_dataPage.id, 'locked'))}><Icon name='lock' /></Button>
            )}
            </>
          )}
        </div>
      )}
    </Container>
  );
}

export default WECCorrectorEditorMenu;