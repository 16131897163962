// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// semantic ui
import { Modal, Header, Button, Table, Label, Icon, Grid, Input, Dropdown, Container, Checkbox } from 'semantic-ui-react';

// Redux
import { isVisible, hideTeam, getTeam, setTeam, getDirty, saveTeam } from './team.slice';
import { setTeams } from './teams.slice';
import { selectConfig } from '../../WECorrect.slice';


// App
import WECUserListContent from '../user/listContent';

// Styles
import './teams.css';




/**
 * 
 * @param {Object} team
 * @param {Array} types
 * @param {Function} onSetTeam
 * @param {Function} onRemoveUser 
 * @returns 
 */
const WECTeams_Edit = ({team, types, onSetTeam, onRemoveUser}) => {
    if (typeof team === 'undefined' || !team) {
        return <></>
    }

    //
    const error_username = team.name.length ? '' : 'error';
    const error_username_label = !team.name.length ? <Label basic color='red' pointing>Bezeichnung fehlt</Label> :  <></>;

    // Mitgliederliste
    let team_user_list = <></>;
    if (typeof team.user !== 'undefined') {
        team_user_list = team.user.map((user) => {
            return (
                <Table.Row>
                    <Table.Cell collapsing>{user.username}</Table.Cell>
                    <Table.Cell collapsing>{user.forename} {user.surname}</Table.Cell>
                    <Table.Cell collapsing><Icon link name='circle times' onClick={()=>onRemoveUser(user.id)} /></Table.Cell>
                </Table.Row>
            );
        })
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={4}><label>Bezeichnung</label></Grid.Column>
                    <Grid.Column width={12}>
                        <Input 
                            fluid
                            className = {error_username}
                            value = {team.name}
                            onChange = {(e, {value})=>{
                                let _team = Object.assign({}, team);
                                _team.name = value;
                                onSetTeam(_team);
                            }}
                        />
                        {error_username_label}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={4}><label>Typ</label></Grid.Column>
                    <Grid.Column width={12}>
                        <Dropdown 
                            fluid selection search 
                            placeholder = 'Typ wählen' 
                            options = {types} 
                            value = {team.type.id}
                            onChange = {(e, {value})=>{
                                let _team = Object.assign({}, team);
                                types.forEach(type => {
                                    if (type.id === value) {
                                        _team.type = type
                                    }
                                });
                                onSetTeam(_team);
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={4}><label>Automatisch hinzufügen?</label></Grid.Column>
                    <Grid.Column width={12}>
                        <Checkbox 
                            toggle 
                            value={1} 
                            checked = {team.defaultAdd}
                            onChange = {(e, {checked})=>{
                                let _team = Object.assign({}, team);
                                _team.defaultAdd = checked;
                                onSetTeam(_team);
                            }}
                            />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row width={16}>&nbsp;</Grid.Row>
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={4}><label>Mitglieder</label></Grid.Column>
                    <Grid.Column width={12}>
                        <Table basic='very' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell collapsing>Benutzername</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>Name</Table.HeaderCell>
                                    <Table.HeaderCell collapsing></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {team_user_list}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
}




/**
 *
 */
const WECTeam = () => {

    // global state
    const dispatch  = useDispatch();
    const visible = useSelector(isVisible);
    const team = useSelector(getTeam);
    const dirty = useSelector(getDirty);
    const config = useSelector(selectConfig);

    //
    const sys_types = Object.assign([], config[config.domain].user.types);

    // 
    if (!visible) {
        return <></>
    }

    // Save button
    const _saveData = Object.assign({domain: config.domain}, team);
    const save_button = dirty > 0 ? <Button primary icon labelPosition='left' onClick={()=>dispatch(saveTeam(_saveData))}><Icon name='save' />Speichern</Button> : <></>;

    return (
        <Modal
            dirty = {dirty}
            open = {true}
            className = 'wecteams'
            size = 'xlarge'
            closeIcon 
            closeOnEscape = {false} 
            closeOnDimmerClick = {false}
            onClose = {() => { dispatch(hideTeam()); dispatch(setTeams(null)); }}
        >
            <React.Fragment>
                <Modal.Content>
                    {team.id && (<Header>Team {team.name} bearbeiten</Header>)}
                    {!team.id && (<Header>Team anlegen</Header>)}
                    <Container className='leftColumn'>    
                        <WECTeams_Edit
                            dirty = {dirty}
                            team = {team}
                            types = {sys_types}
                            onSetTeam = {(team)=>dispatch(setTeam(team))}
                            onRemoveUser = {(userId)=>{
                                let _user = Object.assign([], team.user);
                                let _team = Object.assign({}, team);
                                _user.forEach((usr, index) => {
                                    if (usr.id === userId) {
                                        _user.splice(index, 1);
                                    }    
                                });
                                _team.user = _user
                                dispatch(setTeam(_team))
                            }}
                        />    
                    </Container>
                    <Container className='rightColumn'>
                        <WECUserListContent
                            team = {team}
                            dirty = {dirty}
                            small = {true}
                            noHeader = {true}
                            noQuickSearch = {true}
                            excludedUsers = {team.user}
                            columns = {['username', 'forename', 'surname', 'type']} 
                            onRowClick = {(user)=>{
                                let _user = Object.assign([], team.user);
                                let _team = Object.assign({user: []}, team);
                                _team.user = Object.assign([], _user);
                                if (!_user) {
                                    _user = []
                                }
                                _team.user.push(user);
                                dispatch(setTeam(_team))
                            }}
                        />
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button icon labelPosition='left' onClick={()=>{ dispatch(hideTeam()); dispatch(setTeams(null)); }}><Icon name='times' />Schließen</Button>
                    {save_button}
                </Modal.Actions>
            </React.Fragment>
        </Modal>
    );
}

export default WECTeam;