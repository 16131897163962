// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Redux
import { isVisible, stage, changeCorrectionStage, persistCorrectionLevelStage } from './correctionStage.slice';
import { dataProject, dataCorrectionLevel, selectPage } from './corrector.slice';
import { selectClStageTypes } from '../../WECorrect.slice';
import { initCorrections } from './editor/editor.slice';
import { changeCorrectionDate } from './correctionDate.slice';

// semantic ui
import { Button, Modal, Header, Icon, Step, Divider, Message, List } from 'semantic-ui-react';



/**
 * 
 * @returns 
 */
const TSCorrectorCorrectionStage = () => {
  const dispatch = useDispatch();
  const c_isVisible = useSelector(isVisible);
  const c_project = useSelector(dataProject);
  const c_correctionlevel = useSelector(dataCorrectionLevel);
  const c_stage = useSelector(stage);
  const wec_stage_types = useSelector(selectClStageTypes);

  // check if visible
  if (!c_isVisible) {
    return (<></>);
  }

  // check if readonly
  const readOnly = c_project.archived ? true : false;

  //
  let _stage = c_correctionlevel.stage;
  if (typeof c_stage !== 'undefined' && c_stage) {
    _stage = c_stage;
  }

  // check and count (without 'ok') corrections
  let errorList = {};
  let errorFound = false;
  let countCorrections = 0;
  c_correctionlevel.pages.forEach(page => {
    if (typeof page.corrections !== 'undefined') {
      page.corrections.forEach(correction => {
        if (correction.state !== 'A' && correction.state !== 'R') {
          if (typeof errorList[page.pageNr] === 'undefined') {
            errorList[page.pageNr] = {id: page.id, key: page.pageNr -1, nr: page.pageNr, cnt: 0, corrections: page.corrections};
          }
          errorList[page.pageNr].cnt++;
          errorFound = true;
        }
        if (correction.marker.type !== 'ok') {
          countCorrections++;
        }
      });
    }
  });
  const countSteps = countCorrections ? 4 : 5;

  // check if all corrections are qualified if stage is 3 (Qualify)
  const stage4Allowed = (_stage > 2 && !errorFound) ? true : false; 

  // disabled steps
  const disabled1 = _stage > 1 ? true : false;
  const disabled2 = _stage > 2 ? true : false;
  const disabled3 = _stage > 3 ? true : false;
  const disabled4 = _stage > 4 ? true : false;
  const disabled5 = _stage > 5 ? true : false;

  // active steps
  const active1 = _stage === 1 ? true : false;
  const active2 = _stage === 2 ? true : false;
  const active3 = _stage === 3 ? true : false;
  const active4 = _stage === 4 ? true : false;
  const active5 = _stage === 5 ? true : false;

  // onclick actions for steps
  const onclick2 = !disabled2 && !active2 && !readOnly ? () => { dispatch(changeCorrectionStage({key: 'stage', value: 2})); dispatch(changeCorrectionDate({key: 'visible', value: true})) } : null;
  const onclick3 = !disabled3 && !active3 && !readOnly ? () => dispatch(changeCorrectionStage({key: 'stage', value: 3})) : null;
  const onclick4 = !disabled4 && !active4 && !readOnly ? () => dispatch(changeCorrectionStage({key: 'stage', value: 4})) : null;
  const onclick5 = !disabled5 && !active5 && !readOnly ? () => dispatch(changeCorrectionStage({key: 'stage', value: 5})) : null;

  // Error Qualifizierung
  let errorMsg = [];
  for (const key in errorList) {
    if (Object.hasOwnProperty.call(errorList, key)) {
      const element = errorList[key];
      errorMsg.push(
        <List.Item>
          <List.Content floated='right'>
            <Button size='mini' onClick={()=> {
                dispatch(changeCorrectionStage({key: 'visible', value: false}))
                dispatch(selectPage({id: element.id, key: element.key}));
                dispatch(initCorrections(element.corrections));
              }
            }>Öffnen</Button>
          </List.Content>
          <List.Content>Seite {element.nr}: {element.cnt} Korrektur(en) noch nicht qualifiziert</List.Content>
        </List.Item>
      );
    }
  }
  const error = (
    <React.Fragment>
      <List divided className='errorList' verticalAlign='middle'>{errorMsg}</List>
  </React.Fragment>);


  return (
    <Modal open={true} className='wec_corrector_stage' size='large' closeOnEscape={false} closeOnDimmerClick={false}>
      <Modal.Content>
        <Header as='h1'>Korrekturphase wechseln</Header>
        <Header as='h3'>nächste Phase wählen</Header>
        <Divider />
        <Step.Group widths={countSteps}>
          <Step disabled={disabled1}  active={active1}>
            <Step.Content>
              <Step.Title>{wec_stage_types[1].title}</Step.Title>
              <Step.Description>{wec_stage_types[1].description}</Step.Description>
            </Step.Content>
          </Step>
          <Step disabled={disabled2} active={active2} onClick={onclick2}>
            <Step.Content>
              <Step.Title>{wec_stage_types[2].title}</Step.Title>
              <Step.Description>{wec_stage_types[2].description}</Step.Description>
            </Step.Content>
          </Step>
          <Step disabled={disabled3} active={active3} onClick={onclick3}>
            <Step.Content>
              <Step.Title>{wec_stage_types[3].title}</Step.Title>
              <Step.Description>{wec_stage_types[3].description}</Step.Description>
            </Step.Content>
          </Step>
          {stage4Allowed && (
            <Step disabled={disabled4} active={active4} onClick={onclick4}>
              <Step.Content>
                <Step.Title>{wec_stage_types[4].title}</Step.Title>
                <Step.Description>{wec_stage_types[4].description}</Step.Description>
              </Step.Content>
            </Step>
          )}
          {stage4Allowed && !countCorrections && (
            <Step disabled={disabled5} active={active5} onClick={onclick5}>
              <Step.Content>
                <Step.Title>{wec_stage_types[5].title}</Step.Title>
                <Step.Description>{wec_stage_types[5].description}</Step.Description>
              </Step.Content>
            </Step>
          )}
        </Step.Group>
        {errorFound && (<Message error icon='warning sign' header='Kein Phasenwechsel möglich!' content={error} />)}
      </Modal.Content>
      <Modal.Actions>
        <Button size='mini' icon labelPosition='left' onClick={() => {
          dispatch(changeCorrectionStage({key: 'stage', value: null}))
          dispatch(changeCorrectionStage({key: 'visible', value: false}))
        }}
        >Abbrechen<Icon name='close' /></Button>
        {!readOnly && c_correctionlevel.stage !== _stage && (<Button size='mini' icon labelPosition='left' onClick={() => dispatch(persistCorrectionLevelStage(c_project.domain))} primary>Speichern<Icon name='save' /></Button>)}
      </Modal.Actions>
    </Modal>
  );
}

export default TSCorrectorCorrectionStage;


