// react base
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Label, Header, Table, Form, Input, Icon, Popup, List } from 'semantic-ui-react';

// Redux
import { selectConfig, selectBaseProtocol, selectAuthUrl } from '../../WECorrect.slice';
import { showMessageSingle } from '../message/message.slice';
import { editUser, setReload } from './user.slice';
import { fetchGET } from "../toolbox/requestor.slice";

// Styles
import './user.css';





/**
 * 
 * @param {Array} users
 * @param {Array} sysTeams
 * @param {String} filter,
 * @param {Boolean} noQuickSearch
 * @param {Function} onSetFilter
 * @returns 
 */
const WECUserList_Filter = ({users, sysTeams, filter, noQuickSearch, onSetFilter}) => {
  // translations
  const { t } = useTranslation('user', 'common');

  // autocomplete suggestion
  const terms = filter.replace(',', '').split(' ');
  const last_term = terms[terms.length - 1];
  let filter_suggestion = filter;
  let found = false;
  sysTeams.forEach(team => {
    if (!found && last_term.length && team.name.toLowerCase().startsWith(last_term.toLowerCase())) {
      filter_suggestion = filter_suggestion.replace(last_term, team.name);
      found = true;
    }
  });

  // determine most used teams
  let used_teams = {};
  let used_teams_sorted = [];
  if (typeof users !== 'undefined' && users) {
    users.forEach(user => {
      if (typeof user.teams !== 'undefined') {
        user.teams.forEach(team => {
          if (team.type.name !== 'System') {
            if (typeof used_teams[team.name] === 'undefined') {
              used_teams[team.name] = {cnt: 0, team: team}
            }
            used_teams[team.name].cnt++;
          }
        });
      }
    });
    for (const key in used_teams) {
      const team = used_teams[key];
      used_teams_sorted.push(team);
    }
    used_teams_sorted.sort(function (a, b) {
      return b.cnt - a.cnt;
    });
  }

  return (
    <Form>
      <Form.Group inline>
        <Form.Field width={(typeof noQuickSearch === 'undefined' || !noQuickSearch) ? 8 : 15}>
          <Input 
            iconPosition = 'left' 
            style = {{background: 'white', borderRadius: '5px'}}
            placeholder = {t('user:LIST.LABELS.Search')}
            onChange = {(e, {value})=>{ onSetFilter(value) }}
            onKeyUp = {(e)=>{
              if (e.keyCode === 39) {
                onSetFilter(filter_suggestion)
              }
            }}>
            <Icon name='search' />
            <input type="text" value={filter_suggestion} style={{background: 'transparent', position: 'absolute', left: '0px', width: '100%', color: '#bbbbbb'}} readOnly/>
            <input placeholder={t('user:LIST.LABELS.Search')} type="text" value={filter} style={{background: 'transparent', zIndex: '2'}} />
          </Input>
        </Form.Field>
        <Form.Field width={1}>
          <Icon link name='times' onClick={()=>onSetFilter('')} />
        </Form.Field>
        {(typeof noQuickSearch === 'undefined' || !noQuickSearch) && (
          <Form.Field width={7}>
            <div class='usedTags'>
              {used_teams_sorted.map((team, i) => {
                if (i < 5) {
                  return (
                    <Label 
                      key = {i}
                      as = 'a' 
                      className = "hashtag"
                      size = 'mini'
                      icon = 'hashtag'
                      content = {team.team.name}
                      onClick = {()=>{ onSetFilter(team.team.name) }}
                    />
                  );
                }
                return <></>
              })}
            </div>
          </Form.Field>
        )}
      </Form.Group>
    </Form>
  );
}




/**
 * @param {Array} users
 * @param {String} domain
 * @param {Object} sorting
 * @param {Boolean} small
 * @param {columns} users
 * @param {Function} onSetSorting
 * @param {Array} onRowClick
 */
 const WECUserList_User = ({users, domain, sorting, small, columns, onSetSorting, onRowClick }) => {
   // global state
  const dispatch  = useDispatch();

  // translation
  const { t } = useTranslation('user', 'common');

  if (typeof users === 'undefined' || !users) {
    return <></>
  }
  
  // List items
  const users_list = users.map((user) => {
    const details_forename    = typeof user.details !== 'undefined' && typeof user.details.forename !== 'undefined' ? user.details.forename : '';
    const details_surname     = typeof user.details !== 'undefined' && typeof user.details.surname !== 'undefined' ? user.details.surname : '';
    const details_company     = typeof user.details !== 'undefined' && typeof user.details.company !== 'undefined' ? user.details.company : '';
    const details_department  = typeof user.details !== 'undefined' && typeof user.details.department !== 'undefined' ? user.details.department : '';
    const type = typeof user.typeObject !== 'undefined' && typeof user.typeObject.name != 'undefined' ? user.typeObject.name : '';

   let firstTeam = null;
   user.teams.forEach(team => {
    if (team.type.name !== 'System' && !firstTeam) {
      firstTeam = <Label className={team.type.name} icon='users' content={team.name} />;
    } 
   });
   const cntAdditionalTeams = (user.teams.length - 2) > 0 ? <Label className='Additional' content={'+' + (user.teams.length - 2)} /> : <></>;
   const addTeams = user.teams.map((team, index) => {
    if (team.type.name !== 'System' && index > 1) {
      return <List.Item><Label className={team.type.name} icon='users' content={team.name} /></List.Item>;
    }
    return <></>
   });
   const addPopup =  <Popup
      trigger={cntAdditionalTeams}
      content={<List><span>{t('user:LIST.LABELS.Additional teams:')}</span>{addTeams}</List>}
      basic
    />

    //
    if (typeof onRowClick !== 'undefined' && onRowClick !== null) {
      return (
        <Table.Row onClick={()=>onRowClick(user)}>
          {((small && columns.includes('username')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{user.username}&nbsp;&nbsp;<Icon link name='envelope outline' onClick={()=>dispatch(showMessageSingle(user))}/></Table.Cell>)}
          {((small && columns.includes('forename')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{details_forename}</Table.Cell>)}
          {((small && columns.includes('surname')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{details_surname}</Table.Cell>)}
          {((small && columns.includes('lastlogin')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing><span className='meta'>{user.lastLogin}</span></Table.Cell>)}
          {((small && columns.includes('email')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{user.email}</Table.Cell>)}
          {((small && columns.includes('company')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{details_company}</Table.Cell>)}
          {((small && columns.includes('departnemt')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{details_department}</Table.Cell>)}
          {((small && columns.includes('type')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{type}</Table.Cell>)}
          {((small && columns.includes('teams')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>
            {firstTeam}
            {addPopup}
          </Table.Cell>)}
        </Table.Row>
      );
    } else {
      return (
        <Table.Row onClick={()=>dispatch(editUser(user.id))}>
          {((small && columns.includes('username')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{user.username}&nbsp;&nbsp;<Icon link name='envelope outline' onClick={()=>dispatch(showMessageSingle(user))}/></Table.Cell>)}
          {((small && columns.includes('forename')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{details_forename}</Table.Cell>)}
          {((small && columns.includes('surname')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{details_surname}</Table.Cell>)}
          {((small && columns.includes('lastlogin')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing><span className='meta'>{user.lastLogin}</span></Table.Cell>)}
          {((small && columns.includes('email')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{user.email}</Table.Cell>)}
          {((small && columns.includes('company')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{details_company}</Table.Cell>)}
          {((small && columns.includes('departnemt')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{details_department}</Table.Cell>)}
          {((small && columns.includes('type')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>{type}</Table.Cell>)}
          {((small && columns.includes('teams')) || typeof small === 'undefined' || !small) && (<Table.Cell collapsing>
            {firstTeam}
            {addPopup}
          </Table.Cell>)}
        </Table.Row>
      );
    }
  });

  return (
    <Table basic='very' selectable>
      <Table.Header>
        <Table.Row>
          {((small && columns.includes('username')) || typeof small === 'undefined' || !small) && (
            <Table.HeaderCell style={{cursor: 'pointer'}} collapsing onClick={() => { onSetSorting({domain: domain, key: 'username'}) }}>
            {t('user:LIST.LABELS.Username')}
            {sorting !== null && sorting.key === 'username' && sorting.direction === 'DESC' && (<Icon name='arrow down' />)}
            {sorting !== null && sorting.key === 'username' && sorting.direction === 'ASC' && (<Icon name='arrow up' />)}
          </Table.HeaderCell>
          )}
          {((small && columns.includes('forename')) || typeof small === 'undefined' || !small) && (
            <Table.HeaderCell style={{cursor: 'pointer'}} collapsing onClick={() => { onSetSorting({domain: domain, key: 'forename'}) }}>
              {t('user:LIST.LABELS.forename')}
              {sorting !== null && sorting.key === 'forename' && sorting.direction === 'DESC' && (<Icon name='arrow down' />)}
              {sorting !== null && sorting.key === 'forename' && sorting.direction === 'ASC' && (<Icon name='arrow up' />)}
            </Table.HeaderCell>
          )}
          {((small && columns.includes('surname')) || typeof small === 'undefined' || !small) && (
            <Table.HeaderCell style={{cursor: 'pointer'}} collapsing onClick={() => { onSetSorting({domain: domain, key: 'surname'}) }}>
              {t('user:LIST.LABELS.surname')}
              {sorting !== null && sorting.key === 'surname' && sorting.direction === 'DESC' && (<Icon name='arrow down' />)}
              {sorting !== null && sorting.key === 'surname' && sorting.direction === 'ASC' && (<Icon name='arrow up' />)}
            </Table.HeaderCell>
          )}
          {((small && columns.includes('lastlogin')) || typeof small === 'undefined' || !small) && (
            <Table.HeaderCell style={{cursor: 'pointer'}} collapsing onClick={() => { onSetSorting({domain: domain, key: 'llogin'}) }}>
            {t('user:LIST.LABELS.Last login')}
            {sorting !== null && sorting.key === 'llogin' && sorting.direction === 'DESC' && (<Icon name='arrow down' />)}
            {sorting !== null && sorting.key === 'llogin' && sorting.direction === 'ASC' && (<Icon name='arrow up' />)}
          </Table.HeaderCell>
          )}
          {((small && columns.includes('email')) || typeof small === 'undefined' || !small) && (
            <Table.HeaderCell style={{cursor: 'pointer'}} collapsing onClick={() => { onSetSorting({domain: domain, key: 'email'}) }}>
              {t('user:LIST.LABELS.E-Mail')}
              {sorting !== null && sorting.key === 'email' && sorting.direction === 'DESC' && (<Icon name='arrow down' />)}
              {sorting !== null && sorting.key === 'email' && sorting.direction === 'ASC' && (<Icon name='arrow up' />)}
            </Table.HeaderCell>
          )}
          {((small && columns.includes('company')) || typeof small === 'undefined' || !small) && (
            <Table.HeaderCell style={{cursor: 'pointer'}} collapsing onClick={() => { onSetSorting({domain: domain, key: 'company'}) }}>
              {t('user:LIST.LABELS.Company')}
              {sorting !== null && sorting.key === 'company' && sorting.direction === 'DESC' && (<Icon name='arrow down' />)}
              {sorting !== null && sorting.key === 'company' && sorting.direction === 'ASC' && (<Icon name='arrow up' />)}
            </Table.HeaderCell>
          )}
          {((small && columns.includes('departnemt')) || typeof small === 'undefined' || !small) && (
            <Table.HeaderCell style={{cursor: 'pointer'}} collapsing onClick={() => { onSetSorting({domain: domain, key: 'department'}) }}>
              {t('user:LIST.LABELS.Department')}
              {sorting !== null && sorting.key === 'department' && sorting.direction === 'DESC' && (<Icon name='arrow down' />)}
              {sorting !== null && sorting.key === 'department' && sorting.direction === 'ASC' && (<Icon name='arrow up' />)}
            </Table.HeaderCell>
          )}
          {((small && columns.includes('type')) || typeof small === 'undefined' || !small) && (
            <Table.HeaderCell style={{cursor: 'pointer'}} collapsing onClick={() => { onSetSorting({domain: domain, key: 'type'}) }}>
              {t('user:LIST.LABELS.Type')}
              {sorting !== null && sorting.key === 'type' && sorting.direction === 'DESC' && (<Icon name='arrow down' />)}
              {sorting !== null && sorting.key === 'type' && sorting.direction === 'ASC' && (<Icon name='arrow up' />)}
            </Table.HeaderCell>
          )}
          {((small && columns.includes('teams')) || typeof small === 'undefined' || !small) && (
            <Table.HeaderCell collapsing>{t('user:LIST.LABELS.Teams')}</Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {users_list}
      </Table.Body>
    </Table>
  );
}



/**
 * 
 * @param {Boolean} small
 * @param {Boolean} noHeader 
 * @param {Boolean} noQuickSearch 
 * @param {Array} excludedUsers
 * @param {Array} columns 
 * @param {Function} onRowClick
 * @returns 
 */
const WECUserListContent = ({small, noHeader, noQuickSearch, excludedUsers, columns, onRowClick, reload}) => {
  // local state
  const [users, setUsers] = useState(null);
  const [filter, setFilter] = useState('');
  const [sorting, setSorting] = useState({ key : 'username', direction: 'ASC' });
  const [exUsers, setExUsers] = useState(excludedUsers);

  // global state
  const dispatch  = useDispatch();
  const config = useSelector(selectConfig);
  const baseProtocol  = useSelector(selectBaseProtocol);
  const authUrl = baseProtocol + '//' + useSelector(selectAuthUrl);
  
  //
  const sysTeams = Object.assign([], config[config.domain].user.teams);
  const domain = config.domain;
  
  // translation
  const { t } = useTranslation('user', 'common');

  // 
  if (typeof reload === 'undefined') {
    reload = false;
  }

  useEffect(() => {
    if (!users || reload || (typeof excludedUsers !== 'undefined' && excludedUsers.length !== exUsers.length)) {
      let options = { 
        filter: { 
          active : true, 
          domain: config.domain,
          term: filter
        }, 
        sorting : {}
      };
      options.sorting[sorting.key] = sorting.direction;
      dispatch(fetchGET(authUrl + "/api/users/?options=" + btoa(JSON.stringify(options)).replace(/=/g, '_'))).then(
        users => {
          let _users = []
          users.forEach((user) => {
            let found = false;
            if (typeof excludedUsers !== 'undefined' && excludedUsers.length) {
              excludedUsers.forEach(exuser => {
                if (exuser.id === user.id) {
                  found = true;
                }
              });
            }
            if (!found) {
              _users.push(user);
            }
          });
          setExUsers(excludedUsers)
          setUsers(_users)
          dispatch(setReload(false));
        }
      );
    }
  })
  

  return (
    <React.Fragment>
      {(typeof noHeader === 'undefined' || !noHeader) && (<Header>{t('user:LIST.LABELS.User list')}</Header>)}
      <WECUserList_Filter
        users = {users}
        domain = {domain}
        sysTeams = {sysTeams}
        filter = {filter}
        noQuickSearch = {noQuickSearch}
        onSetFilter = {(filterObj)=>{ setFilter(filterObj); setUsers(null); }}
      />
      <div style={{maxHeight: 'calc(100vh - 250px)', overflow: 'auto'}}>
        <WECUserList_User
          users = {users}
          domain = {domain}
          sorting = {sorting}
          small = {small}
          columns = {columns}
          onRowClick = {(user)=>{
            onRowClick(user);
            setUsers(null);
          }}
          onSetSorting = {(sortingObj)=>{ 
            if (sorting.key !== sortingObj.key) {
              sortingObj.direction = 'ASC';
            } else {
              sortingObj.direction = sorting.direction === 'ASC' ? sortingObj.direction = 'DESC' : 'ASC';
            }
            setSorting(sortingObj);
            setUsers(null);
          }}
        />
      </div>
    </React.Fragment>
  );
}

export default WECUserListContent;