// React base
import React from 'react';
import ReactDOM from 'react-dom';

// redux
import store from './app/store';
import { Provider } from 'react-redux';

// App
import 'semantic-ui-css/semantic.min.css'
import './index.css';
import WECorrect from './app/WECorrect';

// Additional
//import * as serviceWorker from './serviceWorkerHandlerReact';
//import * as serviceWorker from './serviceWorkerHandler';

//
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <WECorrect version="2.210127.011" />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
//serviceWorker.register();