// react base
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { isDialogVisible, hideDialog, createProject, projectTemplate } from './new.slice';
import { setAttendees } from './attendees.slice';

// semantic ui
import { Modal, Form, Input, Header, Dropdown, Checkbox, Button, Grid, Icon } from 'semantic-ui-react';

// App
import WECProjectAttendees from "./attendees";

// Styles
import './project.css';



/**
 * 
 * @param {*} props 
 * @returns 
 */
const WECProjectNew = ({config}) => {
  // local state
  const [name, setName] = useState('');
  const [tags, setTags] = useState([]);
  const [split, setSplit] = useState(false);
  const [defaultUser, setDefaultUser] = useState(null);
  const [template, setTemplate] = useState(null);

  // global state
  const dispatch = useDispatch();
  const _isDialogVisible = useSelector(isDialogVisible);
  const _projectTemplate = useSelector(projectTemplate);
  const localDomain  = config.domain
  const system_tags = config && typeof config[config.domain].user.tags !== 'undefined' ? config[config.domain].user.tags : [];

  // translation
  const { t } = useTranslation('corrector');

  // modal visible=?
   if (!_isDialogVisible) {
     return <></>
   }

  // copy?
  if (_projectTemplate && !template) {
    let __attendees = {};
    _projectTemplate.attendees.forEach(__defaultUser => {
      if (typeof __attendees[__defaultUser.role] === 'undefined') {
        __attendees[__defaultUser.role] = [];
      }
      __attendees[__defaultUser.role].push(__defaultUser);
    });
    dispatch(setAttendees(__attendees));

    setTemplate(_projectTemplate);
    setName(_projectTemplate.title + ' COPY');
    setTags(_projectTemplate.tags);
    setSplit(_projectTemplate.split);
  }

  // new?
  if (defaultUser === null && !_projectTemplate) {
    let _defaultUser = [];
    let _defaultUserIDs = [];

    // teams mit defaultAdd suchen
    let defaultAddTeams = [];

    for (const key in config[localDomain].user.teams) {
      if (Object.hasOwnProperty.call(config[localDomain].user.teams, key)) {
        const element = config[localDomain].user.teams[key];
        if (typeof element.type !== 'undefined' && typeof element.type.name !== 'undefined' && element.type.name !== 'System' && element.defaultAdd === true) {
          defaultAddTeams.push(element);
        }    
      }
    }

    //
    let defaultCnt = 0;
    defaultAddTeams.forEach(defaultAddTeam => {
      config[localDomain].user.users.forEach(_user => {
        if (typeof _user.teams !== 'undefined') {
          _user.teams.forEach(_userTeam => {
            if (_userTeam.id === defaultAddTeam.id && !_defaultUserIDs.includes(_user.id) && _user.role) {
              _defaultUserIDs.push(_user.id);
              defaultCnt++;
              _defaultUser.push({
                id: 'DEFAULT_'+defaultCnt,
                userid: _user.id,
                role: _user.role,
                domain: _user.domain,
                details: _user.details
              });
            }
          });
        }
      });
    });
    setDefaultUser(_defaultUser)

    //default user grouped
    let _attendees = {};
    if (typeof _defaultUser !== 'undefined' && _defaultUser !== null) {
      _defaultUser.forEach(__defaultUser => {
        if (typeof _attendees[__defaultUser.role] === 'undefined') {
          _attendees[__defaultUser.role] = [];
        }
        _attendees[__defaultUser.role].push(__defaultUser);
      });
      dispatch(setAttendees(_attendees));
    }
    
  }
  
  // Tag-IDs
  let c_tag_ids = [];
  if (typeof tags !== 'undefined') {
    tags.forEach(tag => {
      if (typeof tag.id !== 'undefined') {
        c_tag_ids.push(tag.id);
      } else {
        c_tag_ids.push(tag);
      }
    });
  }

  //
  const projectData = {
    domain: localDomain,
    title: name,
    tags: c_tag_ids,
    pagesplit: split
  };

  return (
    <Modal open={_isDialogVisible} className='wec_project_new' size='large' closeOnEscape={false} closeOnDimmerClick={false}>
      <Modal.Content>
        <Header as='h1'>{t('corrector.NEW:Projekt anlegen')}</Header>
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column width={8}>
              <Form>
                <Form.Field>
                  <label>{t('corrector.NEW:Projektname')}</label>
                  <Input size="small" value={name} placeholder={t('corrector.NEW:Projektname')} onChange={(e, {value})=>setName(value)} />
                </Form.Field>
                <Form.Field>
                  <label>{t('corrector.NEW:Schlagworte')}</label>
                  <Dropdown 
                    placeholder = {t('corrector.NEW:Schlagworte')}
                    fluid multiple selection search allowAdditions
                    additionLabel = {t('corrector.NEW:Schlagwort hinzufügen')} 
                    options = {system_tags}
                    value = {c_tag_ids}
                    onChange = {(e, {value})=>setTags(value)}
                  />
                </Form.Field>
                <Form.Group>
                  <Form.Field width={5}><label>{t('corrector.EDIT:Doppelseitensplit')}</label></Form.Field>
                  <Form.Field width={11}><Checkbox toggle checked={split} onChange={()=>setSplit(!split)} /></Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={8} className='WECProjectAttendees'>
              <WECProjectAttendees />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button size='mini' icon labelPosition='left' onClick={() => {
          setDefaultUser(null);
          dispatch(hideDialog());
          dispatch(setAttendees([]));
        }}>{t('corrector.NEW:Schließen')}<Icon name='close' /></Button>
        <Button size='mini' icon labelPosition='right' primary onClick={() => {
          dispatch(createProject(projectData));
        }}>{t('corrector.NEW:Anlegen')}<Icon name='save' /></Button>
      </Modal.Actions>
    </Modal>
  );
}

export default WECProjectNew;