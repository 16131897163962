import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
//import { createLogger } from 'redux-logger';

import rootReducer from './rootReducer';

//const loggerMiddleware = createLogger();
const store = configureStore();
export default store;



/**
 * 
 * @param {Object} preloadedState 
 * @returns 
 */
function configureStore(preloadedState) {
    const middlewares = [/*loggerMiddleware, */thunkMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)
  
    const enhancers = [middlewareEnhancer]
    const composedEnhancers = compose(...enhancers)
  
    const store = createStore(rootReducer, preloadedState, composedEnhancers)
  
    return store
}