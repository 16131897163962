import { createSlice } from '@reduxjs/toolkit';
import { setAttendees } from './attendees.slice';
import { fetchPOST } from "../../toolbox/requestor.slice";
import { resetProjects } from '../projects.slice';
import { addMessage } from '../../snackbar/snackbar.slice';



/**
 * 
 */
export const newProjectSlice = createSlice({
  name: 'newProject',
  initialState: {
    dialogVisible : false,
    projectDomain: null,
    projectTemplate: null
  },
  reducers: {
    hideDialog : (state) => { 
      state.dialogVisible = false;
      state.projectDomain = null;
    },
    showDialog : (state, action) => { 
      state.dialogVisible = true;
      if (typeof action.payload.domain !== 'undefined') {
        state.projectDomain = action.payload.domain;
      }
      if (typeof action.payload.project !== 'undefined') {
        state.projectTemplate = action.payload.project;
      }
    },
    showDialogNew : (state, action) => { 
      state.dialogVisible = true;
      if (typeof action.payload.domain !== 'undefined') {
        state.projectDomain = action.payload.domain;
      }
      if (typeof action.payload.project !== 'undefined') {
        state.projectTemplate = action.payload.project;
      }
    },
    toggleDialog : (state) => { state.dialogVisible = !state.dialogVisible; },
  },
});

export const { hideDialog, showDialog, showDialogNew, toggleDialog } = newProjectSlice.actions;

export const isDialogVisible = (state) => state.newProject.dialogVisible;
export const projectDomain = (state) => state.newProject.projectDomain;
export const projectTemplate = (state) => state.newProject.projectTemplate;

export default newProjectSlice.reducer;





/**
 *
 * @param {string} props.domain
 * @param {*} props 
 */
 export function createProject(props) {
  return async (dispatch, getState) => {
    const state = getState();
    const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
    if (typeof props.domain === 'undefined' || !props.domain) {
      props.domain = state.wec.config.domain;
    }
    props.attendees = state.attendees.attendees;

    try {
      // checks
      //dispatch(validate(props));
      
      // request
      dispatch(fetchPOST(backendUrl + '/api/latest/' + props.domain + '/project/create', props)).then(
        project => {
          dispatch(addMessage({type: 'NOTICE', text: 'Projekt ' + project.title + ' wurde angelegt'}));
          dispatch(setAttendees([]));
          dispatch(hideDialog());
          dispatch(resetProjects());
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}