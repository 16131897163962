// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import { format } from 'date-fns/esm'
import de from 'date-fns/locale/de';

// translation
import { useTranslation } from 'react-i18next';

// Styles
import 'react-datepicker/dist/react-datepicker.css';
import './correctionDate.css'

// Redux
import { isVisible, date, changeCorrectionDate, persistCorrectionLevelDeadline } from './correctionDate.slice';
import { dataCorrectionLevel } from './corrector.slice';

// semantic ui
import { Button, Modal, Header, Form, Icon } from 'semantic-ui-react';


registerLocale('de-DE', de)


/**
 * 
 * @param {*} props 
 * @returns 
 */
const TSCorrectorCorrectionDate = (props) => {
  const dispatch = useDispatch();
  const c_isVisible = useSelector(isVisible);
  const c_date = useSelector(date);
  const c_correctionLevel = useSelector(dataCorrectionLevel);

  // translation
  const { t } = useTranslation('corrector');

  // DatePicker
  let dateObj = null;
  if (c_date) {
    dateObj = new Date(c_date)
  } else {
    if (typeof c_correctionLevel !== 'undefined' && c_correctionLevel && typeof c_correctionLevel.deadline !== 'undefined' && c_correctionLevel.deadline) {
      dateObj = new Date(c_correctionLevel.deadline.date);
    } else {
      dateObj = new Date();
      dateObj.setDate(dateObj.getDate() + 7);
      dispatch(changeCorrectionDate({key:'date', value: format(dateObj, "yyyy-MM-dd HH:mm")}))
    }
  }

  // 
  let addDate = new Date(); addDate.setHours(23); addDate.setMinutes(59);
  let minDate = new Date();
  let minTime = new Date(); minTime.setHours(8); minTime.setMinutes(0);
  let maxTime = new Date(); maxTime.setHours(23); maxTime.setMinutes(59);
  
  // hide modal 
  if (!c_isVisible) {
    return (<></>);
  }

  // render modal
  return (
    <Modal
      open = {true}
      className = 'wec_correctiondate'
      size = 'xsmall'
      closeOnEscape = {false}
      closeOnDimmerClick = {false}
      closeIcon 
      onClose = {() => dispatch(changeCorrectionDate({key: 'visible', value: false}))}
    >
      <Modal.Content>
        <Header as='h1'>{t('corrector:DEADLINE.Termin für Korrekturen setzen')}</Header>
        <Form>
          <Form.Field>
            <DatePicker
              inline
              disabledKeyboardNavigation
              showWeekNumbers
              showTimeSelect
              locale = "de-DE"
              timeIntervals = {60}
              injectTimes = {[addDate,]}
              minDate = {minDate}
              minTime = {minTime}
              maxTime = {maxTime}
              dateFormat = "yyyy-MM-dd HH:mm:00.000z"
              selected = {dateObj}
              onChange = {(date)=>{
                dispatch(changeCorrectionDate({key:'date', value: format(date, "yyyy-MM-dd HH:mm")}))
              }}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button size='mini' icon labelPosition='left' onClick={() => dispatch(changeCorrectionDate({key: 'visible', value: false}))}>{t('corrector:DEADLINE.Abbrechen')}<Icon name='close' /></Button>
        <Button size='mini' icon labelPosition='right' onClick={() => dispatch(persistCorrectionLevelDeadline(c_correctionLevel.domain, t('corrector:DEADLINE.Termin für Korrekturen wurde geändert')))} primary>{t('corrector:DEADLINE.Übernehmen')}<Icon name='check' /></Button>
      </Modal.Actions>
    </Modal>
  );
}

export default TSCorrectorCorrectionDate;


