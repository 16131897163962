// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Redux
import { compareMode, togglePages, toggleComments, isPages, isComments } from './editor.slice';
import { dataPage, dataCorrectionLevel, dataProject } from '../corrector.slice';

// semantic ui
import { Icon } from 'semantic-ui-react';

// App
import WECCorrectorEditorPages from "./pages"
import WECCorrectorEditorCompareDialog from './dialogCompare'
import WECCorrectorEditorNewCorrection from "./dialogNewCorrection"
import WECCorrectorEditorCorrection from "./dialogCorrection"
import WECCorrectorEditorEditor from "./editorEditor"
import WECCorrectorEditorCompare from "./editorCompare"
import WECCorrectorEditorComments from "./comments"
import WECCorrectorEditorAddComment from "./dialogAddComment"

import WECContextMenu from '../contextMenu/contextMenu';


import TSCorrectorCorrectionStage from "../correctionStage"


// Styles
import './editor.css';



/**
 * 
 */
const WECCorrectorEditor = () => {
  const dispatch = useDispatch();
  const c_dataPage        = useSelector(dataPage);
  const c_correctionlevel = useSelector(dataCorrectionLevel);
  const c_project         = useSelector(dataProject);
  const e_compareMode     = useSelector(compareMode)
  const e_isPages         = useSelector(isPages)
  const e_isComments      = useSelector(isComments)

  // check if readonly
  let readOnly = false;
  readOnly = c_project.archived ? true : false;
  readOnly = typeof c_dataPage.userstate !== 'undefined' && typeof c_dataPage.userstate.state !== 'undefined' && c_dataPage.userstate.state === 'locked' ? true : readOnly;
  
  // check visibility of sidebars
  const hidePages = e_isPages ? {} : {display: 'none'};
  const hiderPages = e_isPages ? 'angle left' : 'angle right';
  const hideComments = e_isComments > 0 ? 'angle right' : null;
  const showComments = e_isComments < 2 ? 'angle left' : null;

  // editor size
  let editorSize = 'small'
  if ( e_isPages && e_isComments === 2) editorSize = 'small';
  if (!e_isPages && e_isComments === 0) editorSize = 'large';
  if (!e_isPages && e_isComments === 2) editorSize = 'medium1';
  if (!e_isPages && e_isComments === 1) editorSize = 'medium2';
  if ( e_isPages && e_isComments === 1) editorSize = 'medium3';
  if ( e_isPages && e_isComments === 0) editorSize = 'medium4';

  return (
      <div className={'WECProject wec_corrector_editor'}>
        
        <div id='Pages' className='Pages' style={hidePages}>
          <WECCorrectorEditorPages pagesData={c_correctionlevel.pages} />
        </div>
        <div class="HiderLeft">
          <Icon link name={hiderPages} onClick={()=>dispatch(togglePages())}/>
        </div>
        <div id='Editor' className={'Editor ' + editorSize}>
          {e_compareMode === 'normal' && (<WECCorrectorEditorEditor readOnly={readOnly} />)}
          {e_compareMode === 'compare' && (<WECCorrectorEditorCompare readOnly={readOnly} />)}
        </div>
        <div class="HiderRight">
          {hideComments && (<Icon link name={hideComments} onClick={()=>dispatch(toggleComments('-'))} />)}
          {showComments && (<Icon link name={showComments} onClick={()=>dispatch(toggleComments('+'))} />)}
        </div>
        <div id='Comments' className={'Comments Comments' + e_isComments}>
          <WECCorrectorEditorComments readOnly={readOnly} size={e_isComments} mode={e_compareMode} />
        </div>
        
        <WECCorrectorEditorCompareDialog />
        <WECCorrectorEditorNewCorrection />
        <WECCorrectorEditorCorrection />
        <WECCorrectorEditorAddComment />
        {false && (
          <React.Fragment>
            <TSCorrectorCorrectionStage />
          </React.Fragment>
        )}


          <WECContextMenu />
      </div>

      
  );
}

export default WECCorrectorEditor;