// react base
import React, { useState } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { format } from 'date-fns/esm'

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { loadCorrectionLevel, dataProject, getCorrectionLevelSource } from '../corrector.slice';
import { selectBaseProtocol, selectBackendUrl, selectConfig } from '../../../WECorrect.slice';
import { selectUser } from '../../login/login.slice';

// Permissions
import WECPermissionHandler from '../../toolbox/permission_handler';

// semantic ui
import { Header, Button, Popup, List, Table, Image } from 'semantic-ui-react'

// Styles
import './project.css';




/**
 * 
 * @param {String} imgURL 
 * @param {String} title 
 * @param {Object} corrections
 * @param {String} fdateChanged 
 * @param {Function} onClick 
 * @param {Function} onPDF
 * @param {Boolean} permissionOpen
 * @param {Boolean} permissionPDF
 * @returns 
 */
const WECProjectCorrectionLevelsItem = ({imgURL, title, corrections, current, fdateChanged, onClick, onPDF, permissionOpen, permissionPDF}) => {
  // local state
  const [details, showDetails] = useState(false);
  
  // translation
  const { t } = useTranslation('corrector');

  // tooltipp
  const detailsTooltipp = details ? 'Details ausbleden' : 'Details anzeigen';

  // icon src
  const iconSrc = details ? '/media/icons/WE_correct_icon_chevron-kreis-unten.svg' : '/media/icons/WE_correct_icon_chevron-kreis-oben.svg';
 
  return (
    <tr>
      <td style={{width: "70px", textAlign: 'center', verticalAlign: 'top'}}>
        <div className='stufe' title={title} style={{marginLeft: '20px'}}>
          <span className='stufe_head'>{t('corrector:CORRECTIONLEVELS.Stufe')}</span>
          <span className='stufe_value'>{current}</span>
        </div>
      </td>
      <td>
        <table>
          <tr>
            <td style={{width: "100px"}}>{t('corrector:CORRECTIONLEVELS.Korrekturen')}:</td>
            <td>
              <span className='date' style={{float: 'left'}}>{corrections.all}</span>
              <Popup trigger={<Image src='/media/icons/WE_correct_icon_suchen.svg' style={{width: '16px', marginTop: '-3px', cursor: 'pointer'}}/>}>
                <Header>{corrections.all} {t('corrector:CORRECTIONLEVELS.Korrekturen insgesammt')}: </Header>
                <List>
                  <List.Item>{corrections.states.none} {t('corrector:CORRECTIONLEVELS.Korrekturen warten')}</List.Item>
                  <List.Item>{corrections.states.accepted} {t('corrector:CORRECTIONLEVELS.Korrekturen noch offen')}</List.Item>
                  <List.Item>{corrections.states.rejected} {t('corrector:CORRECTIONLEVELS.Korrekturen abgewiesen')}</List.Item>
                  <List.Item>{corrections.states.done} {t('corrector:CORRECTIONLEVELS.Korrekturen abgeschlossen')}</List.Item>
                </List>
              </Popup>
            </td>
          </tr>
          <tr>
            <td>{t('corrector:CORRECTIONLEVELS.Abgeschlossen')}:</td>
            <td><span className='date'>{fdateChanged}</span></td>
          </tr>
        </table>
        {details && (
          <div style={{marginTop: '15px'}}>
            <div style={{maxWidth: '140px', maxHeight: '140px', overflow: 'hidden'}}>
              <Image src={imgURL} />
            </div>
            <div style={{marginTop: "-28px"}}>
              {permissionOpen && (<Button icon size='mini' floated='right' labelPosition='left' onClick={onClick} primary>{t('corrector:CORRECTIONLEVELS.Öffnen')}<Image className='buttonImage' src='/media/icons/WE_correct_icon_white_oeffnen.svg' /></Button>)}
              {permissionPDF && (<Button icon size='mini' floated='right' labelPosition='left' onClick={onPDF}>{t('corrector:CORRECTIONLEVELS.PDF herunterladen')}<Image className='buttonImage' src='/media/icons/WE_correct_icon_download.svg' /></Button>)}
            </div>
          </div>
        )}
      </td>
      <td style={{width: "30px", verticalAlign: 'top'}}>
        <Image style={{width: "20px", cursor: 'pointer'}} src={iconSrc} title={detailsTooltipp} onClick={()=>{if (details) { showDetails(false) } else { showDetails(true) }}} />
      </td>
    </tr>
  )
}



/**
 * 
 */
const WECProjectCorrectionLevels = () => {
  const dispatch = useDispatch();
  const _project            = useSelector(dataProject);
  const _selectBaseProtocol = useSelector(selectBaseProtocol);
  const _selectBackendUrl   = useSelector(selectBackendUrl);
  const userLoggedIn        = useSelector(selectUser);
  const config              = useSelector(selectConfig);

  // Permissions
  let permissionHandler = new WECPermissionHandler();
  const permission_PERM_PRJ_06 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_06', _project.attendees, config[config.domain].user.roles);
  const permission_PERM_PRJ_07 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_07', _project.attendees, config[config.domain].user.roles);

  // translation
  const { t } = useTranslation('corrector');
  
  //
  let correctionlevels = <></>;
  if (_project && typeof _project.correctionLevels !== 'undefined') {
    correctionlevels = _project.correctionLevels.map((correctionLevel, i) => {
      let imgURL = '/media/page_thumb.jpg';
      if (typeof _project.correctionLevel !== 'undefined' && typeof correctionLevel.id !== 'undefined') {
        imgURL = _selectBaseProtocol + '//' + _selectBackendUrl + '/media/projects/' + _project.domain + '/' + _project.id + '/' + correctionLevel.id + '/thumbs/1.jpg';
      }

      if (i) {
        return (
          <WECProjectCorrectionLevelsItem
            imgURL = {imgURL}
            title = {correctionLevel.title}
            corrections = {correctionLevel.corrections}
            current = {correctionLevel.counter}
            fdateChanged = {typeof _project.log !== 'undefined' && typeof _project.log.UPDATE !== 'undefined' && _project.log.UPDATE ? format(new Date(_project.log.UPDATE), "dd.MM.yyyy, HH:mm") : <></>}
            permissionOpen = {permission_PERM_PRJ_06}
            permissionPDF = {permission_PERM_PRJ_07}
            onClick = {()=>dispatch(loadCorrectionLevel(correctionLevel.domain, correctionLevel.id))}
            onPDF = {()=>dispatch(getCorrectionLevelSource(_project.domain, correctionLevel.id, correctionLevel.source))}
          />
        )
      } else {
        return (<></>)
      }
    });
  }



  if (typeof _project.correctionLevels !== 'undefined' && _project.correctionLevelsCount > 0) {
    return (
      <React.Fragment>
        <Header>{t('corrector:CORRECTIONLEVELS.vorherige Korrekturstufen')}</Header>
        <div style={{overflow: 'auto', height: 'calc(100vh - 310px)'}}>
          <Table basic='very'>
            {correctionlevels}
          </Table>
        </div>
      </React.Fragment>
    );
  } else {
    return (<></>);
  }
}

export default WECProjectCorrectionLevels;