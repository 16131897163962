// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Input, Grid } from 'semantic-ui-react';

// Redux
import { selectDetails } from './user.slice';
import { setDetail } from './user.slice';
import { selectConfig } from '../../WECorrect.slice';
import { selectMandant } from '../../WECorrect.slice';
import { selectUser } from '../login/login.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// Styles
import './user.css';





/**
 *
 */
const WECUser_Details = () => {
    // global state
    const dispatch  = useDispatch();

    // translations
    const { t } = useTranslation('user', 'common');
    
    // User
    const userDetails = useSelector(selectDetails);

    // Login
    const userLoggedIn  = useSelector(selectUser);

    // settings for types and teams 
    const config = useSelector(selectConfig);
    
    // Base
    const mandant = useSelector(selectMandant);

    // Permissions
    let permissionHandler = new WECPermissionHandler();
    const permission_PERM_SYS_03 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_03') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
    
    // details
    let details_labels = {};
    config[mandant].user.details.forEach(element => {
        const selector = element[0];
        details_labels[selector] = t(`user:DETAILS.LABELS.${selector}`);
    });
    const details_list = config[mandant].user.details.map((detail) => {
        const detail_name = detail[0];
        const detail_value = typeof userDetails !== 'undefined' && typeof userDetails[detail_name] !== 'undefined' ? userDetails[detail_name] : '';

        return (
            <Grid.Row style={{padding: '3px'}}>
                <Grid.Column width={4}><label>{details_labels[detail_name]}</label></Grid.Column>
                <Grid.Column width={12}>
                    {permission_PERM_SYS_03 && (
                        <Input 
                            fluid 
                            size = "mini"
                            iconPosition = 'left'
                            placeholder = {details_labels[detail_name]}
                            value = {detail_value}
                            onChange = {(event)=>dispatch(setDetail({ name : detail_name, value : event.target.value }))}
                        />
                    )}
                    {!permission_PERM_SYS_03 && (
                        <Input 
                            fluid 
                            size = "mini"
                            iconPosition = 'left'
                            placeholder = {details_labels[detail_name]}
                            value = {detail_value}
                            readonly
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
        );
    });


    return (
        <React.Fragment>
            <Grid>
                {details_list}
            </Grid>
        </React.Fragment>                    
    );
}

export default WECUser_Details;