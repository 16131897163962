// react base
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { isDialogVisibleCL, hideDialogCL, createCorrectionlevel, correctionlevelDomain, isLoaderCL, getCounter, getFileCL, setFileCL, getLoaderText } from './new.slice';
import { dataProject } from '../corrector.slice';

// semantic ui
import { Modal, Form, Input, Header, Button, Icon, Divider, Dimmer, Loader } from 'semantic-ui-react';

// Styles
import './correctionlevel.css';




/**
 * 
 * @returns 
 */
const WECCorrectionlevelNew = () => {
  // local state
  const [title, setTitle] = useState('');

  // global state
  const dispatch = useDispatch();
  const _isDialogVisible = useSelector(isDialogVisibleCL);
  const _correctionlevelDomain = useSelector(correctionlevelDomain);
  const _isLoader  = useSelector(isLoaderCL);
  const _counter = useSelector(getCounter);
  const _file = useSelector(getFileCL);
  const _loaderText = useSelector(getLoaderText)
  const c_project = useSelector(dataProject);

  // translation
  const { t } = useTranslation('corrector');
  const loaderText = _loaderText ? _loaderText : t('corrector.NEWCL:PDF wird verarbeitet...');

  //
  const fileInputRef = React.createRef();
  const _newCorrectionLevelFileName = _file && typeof _file.name !== 'undefined' ? _file.name : '';
  let loader = <></>;
  const placeholder = t('corrector.NEWCL:Korrekturstufe') + " " + _counter;
 
  //
  const correctionlevelData = {
    domain: c_project.domain,
    title: !title ? t('corrector.NEWCL:Korrekturstufe') + " " + _counter : title,
    file: _file,
    counter: _counter
  };

  //
  const params = {
    loaderText: loaderText,
    domain: c_project.domain,
  }

  // Loader
  if (_isLoader) {
    loader = (
      <Dimmer active inverted>
        <Loader inverted size='massive' content={loaderText} />
      </Dimmer>
    );
  }


  return (
    <Modal open={_isDialogVisible} className='wec_correctionlevel_new' size='small' closeOnEscape={false} closeOnDimmerClick={false}>
      <Modal.Content>
        {loader}
        <Header as='h1'>{t('corrector.NEWCL:Neue Korrekturstufe anlegen')}</Header>
        <Form>
          <Form.Field>
            <label>{t('corrector.NEWCL:Titel')}</label>
            <Input size="small" value={correctionlevelData.title} placeholder = {placeholder} onChange={(e, {value})=>setTitle(value)} />
          </Form.Field>
          <Form.Field>
            <label>{t('corrector.NEWCL:Datei vom Computer')}</label>
            <input 
              hidden 
              ref = {fileInputRef}
              type = "file" 
              onChange = {(e)=>dispatch(setFileCL(e.target.files[0]))} />
            <Input 
              readOnly 
              className = "fileInput"
              size ="small" 
              value = {_newCorrectionLevelFileName} 
              placeholder = {t('corrector.NEWCL:Datei vom Computer')} />
            <Button size='mini' content={t('corrector.NEWCL:Durchsuchen')} labelPosition="left" icon="file" onClick={() => fileInputRef.current.click()} />
          </Form.Field>
        </Form>
        <Divider />
        <Button size='mini' icon labelPosition='left' floated='right' primary onClick={()=>dispatch(createCorrectionlevel(correctionlevelData, params))}>{t('corrector.NEWCL:Anlegen')}<Icon name='save' /></Button>
        <Button size='mini' icon labelPosition='left' floated='right' onClick={()=>dispatch(hideDialogCL())}>{t('corrector.NEWCL:Schließen')}<Icon name='close' /></Button>
      </Modal.Content>
    </Modal>
  );
}

export default WECCorrectionlevelNew;