import { createSlice } from '@reduxjs/toolkit';



export const archiveSlice = createSlice({
  name: 'archive',
  initialState: {
    showDomains: false,
  },

  reducers: {
    toggleDomains: (state) => { state.showDomains = !state.showDomains; },
  },
});

export const { toggleDomains } = archiveSlice.actions;
export const isDomains = (state) => state.archive.showDomains;

export default archiveSlice.reducer;