import { createSlice } from '@reduxjs/toolkit';
import { fetchGET, fetchPOST, fetchPUT, fetchDELETE } from "../toolbox/requestor.slice";
import { addMessage } from '../snackbar/snackbar.slice'
import { setUserLoggedIn } from '../login/login.slice';




export const userSlice = createSlice({
  name: 'user',
  initialState: {
    users             : [],
    data: {
      username        : '',
      password        : '', 
      rpassword       : '',
      mandant         : '',  
      email           : '',
      active          : true,
      language        : 'de-DE',
      deleted         : false,
      type            : null,
      teams           : [],
      initial         : { username : true, email : true, password : true },
      details         : {},
      settings        : {},
      role            : null
    },
    dirty             : false,
    newUserVisible    : false,
    editUserVisible   : false,
    listUsersVisible  : false,
    editTeamsVisible  : false,
    passwordCheck     : { length : true, case : true, numbers : true, special : true, strength : 0, identically : true },
    emailCheck        : true,
    formCheck         : { password : true, username : true, email : true },
    usernameCheck     : { available : true, length : true, character : true },
    createUser        : true,
    reload            : false,
  },

  reducers: {
    setUsername   : (state, action) => { state.dirty = true; state.data.username   = action.payload.username; },
    setPassword   : (state, action) => { state.dirty = true; state.data.password   = action.payload.password; },
    setRPassword  : (state, action) => { state.dirty = true; state.data.rpassword  = action.payload.rpassword; },
    setEmail      : (state, action) => { state.dirty = true; state.data.email      = action.payload.email; },
    setLanguage   : (state, action) => { state.dirty = true; state.data.language   = action.payload.language; },
    setActive     : (state, action) => { state.dirty = true; state.data.active     = action.payload.active; },
    setDeleted    : (state, action) => { state.dirty = true; state.data.deleted    = action.payload.deleted; },
    setType       : (state, action) => { state.dirty = true; state.data.type       = action.payload.type; },
    setTeams      : (state, action) => { state.dirty = true; state.data.teams      = action.payload.teams; },
    setRole       : (state, action) => { state.dirty = true; state.data.role       = action.payload.role; },
    setDetail     : (state, action) => { 
      state.dirty = true; 
      if (typeof state.data.details === 'undefined') {
        state.data.details = {};
      }
      state.data.details[action.payload.name] = action.payload.value;
    },
    setSettings   : (state, action) => { 
      state.dirty = true; 
      if (typeof state.data.settings === 'undefined' || !state.data.settings) {
        state.data.settings = {};
      }
      if (typeof state.data.settings[action.payload.type] === 'undefined' || !state.data.settings[action.payload.type]) {
        state.data.settings[action.payload.type] = {};
      }
      state.data.settings[action.payload.type] = action.payload;
    },

    validateUsernameSuccess : (state, action) => {
      state.data.initial.username   = false;
      state.usernameCheck.available = false;
      state.usernameCheck.length    = false;
      state.usernameCheck.character = false;

      // length
      if (action.payload.username.length >= 3) {
        state.usernameCheck.length = true;
      } else {
        state.createUser = false;
      }

      // case
      if (action.payload.username.match(/^[a-zA-Z0-9_]*$/gm)) {
        state.usernameCheck.character = true;
      } else {
        state.createUser = false;
      }

      // available
      if (action.payload.check.available) {
        state.usernameCheck.available = true;
      } else {
        state.createUser = false;
      }
    },
    validateUsernameFailed : (state) => {
      state.data.initial.username   = false;
      state.usernameCheck.available = false;
      state.usernameCheck.length    = false;
      state.usernameCheck.character = false;
      state.createUser = false;
    },

    validatePassword : (state, action) => {
      state.data.initial.password     = false;
      state.passwordCheck.length      = false;
      state.passwordCheck.identically = false;
      //state.passwordCheck.special     = false;
      //state.passwordCheck.case        = false;
      //state.passwordCheck.numbers     = false;
      //state.passwordCheck.strength    = 0;

      // length
      if (action.payload.password.length >= 8) {
        state.passwordCheck.length = true;
        state.passwordCheck.strength++;
      } else {
        state.createUser = false;
      }

      /*/ case
      if (action.payload.password.match(/(.*[a-z].*)/) && action.payload.password.match(/(.*[A-Z].*)/)) {
        state.passwordCheck.case = true;
        state.passwordCheck.strength++;
      } else {
        state.createUser = false;
      }*/

      /*/ number
      if (action.payload.password.match(/(.*\d.*)/)) {
        state.passwordCheck.numbers = true;
        state.passwordCheck.strength++;
      } else {
        state.createUser = false;
      }*/

      // special 
      //if (action.payload.password.match(/.*\W.*/)) {
      //  state.passwordCheck.special = true;
      //  state.passwordCheck.strength++;
      //} else {
      //  state.createUser = false;
      //}

      // check if both pws are identically
      if (typeof action.payload.rpassword !== 'undefined' && action.payload.rpassword && action.payload.password === action.payload.rpassword) {
        state.passwordCheck.identically = true;
      }
    },

    validateEmail : (state, action) =>  {
      if (typeof state.data.initial === 'undefined') {
        state.data.initial = { username : true, email : true, password : true };
      }
      state.data.initial.email = false;
      state.emailCheck = false;
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(action.payload.email)) {
        state.emailCheck = true;
      }
    },

    createUserSuccess : (state, action) => {
      state.data.initial = false;
      state.dirty = false;
      state.reload = true;
      state.users.push(action.payload);
      state.data = action.payload;
      let _teams = [];
      action.payload.teams.forEach(team => {
        _teams.push(team.id);
      });
      state.data.teams = _teams;
      state.newUserVisible = false;
      state.editUserVisible = true;
    },
    createUserFailed : (state) => { state.data.initial = false; },
    saveUserSuccess : (state) => {
      state.data.initial = { username : true, email : true, password : true };
      state.dirty = false;
      state.reload = true;
    },
    saveUserFailed : (state) => { state.data.initial = false; },
    showNewUserModal: (state) => { state.newUserVisible = true; },
    showEditUserModal: (state, action) => {
      state.editUserVisible = true;
      state.data.id       = action.payload.id;
      state.data.username = action.payload.username;
      state.data.mandant  = action.payload.mandant;
      state.data.language = action.payload.language;
      state.data.email    = action.payload.email;
      state.data.active   = action.payload.active;
      state.data.details  = action.payload.details;
      state.data.settings = action.payload.settings;
      state.data.type     = action.payload.type;
      state.data.role     = action.payload.role;
      state.data.typeObject = action.payload.typeObject;
      state.data.teams = [];
      for (const key in action.payload.teams) {
        state.data.teams.push(action.payload.teams[key].id);
      }
      state.data.initial = { username : true, email : false, password : true };
    },
    hideModal: (state, action) => {
      switch (action.payload.modal) {
        case 'NewUser' : state.newUserVisible = false; break;
        case 'EditUser' : state.editUserVisible = false; break;
        case 'ListUser' : state.listUsersVisible = false; break;
        default:
      }
      state.data = {
        username  : '',
        password  : '', 
        rpassword : '',
        mandant   : '',  
        email     : '',
        type      : '',
        teams     : '',
        active    : true,
        language  : 'de-DE',
        deleted   : false,
        initial   : { username: true, email: true, password: true, type: true, teams: true },
        details   : {}
      }
      state.dirty = false;
    },
    setUsers: (state, action) => { state.users = action.payload; },
    showListUsers: (state) => { state.listUsersVisible = true; },

    setReload: (state, action) => { state.reload = action.payload; },
  }
});






export const { 
  setUsername, setPassword, setRPassword, setEmail, setLanguage, setActive, setDeleted, setUsers, setDetail, setType, setRole, setTeams, 
  validateUsernameSuccess, validateUsernameFailed, validatePassword, validateEmail, 
  showNewUserModal, showEditUserModal, hideModal, createUserSuccess, createUserFailed, saveUserSuccess, saveUserFailed,
  showListUsers, setSettings, setReload
} = userSlice.actions;
export const selectUserId         = (state) => state.user.data.id;
export const selectUsername       = (state) => state.user.data.username;
export const selectPassword       = (state) => state.user.data.password;
export const selectRPassword      = (state) => state.user.data.rpassword;
export const selectMandant        = (state) => state.user.data.mandant;
export const selectEmail          = (state) => state.user.data.email;
export const selectLanguage       = (state) => state.user.data.language;
export const selectDetails        = (state) => state.user.data.details;
export const selectType           = (state) => state.user.data.type;
export const selectTeams          = (state) => state.user.data.teams;
export const selectRole           = (state) => state.user.data.role;
export const selectData           = (state) => state.user.data;
export const isActive             = (state) => state.user.data.active;
export const isDeleted            = (state) => state.user.data.deleted;
export const isInitialMode        = (state) => state.user.data.initial;
export const selectSettings       = (state) => state.user.data.settings;

export const isNewUserVisible     = (state) => state.user.newUserVisible;
export const isEditUserVisible    = (state) => state.user.editUserVisible;
export const isListUsersVisible   = (state) => state.user.listUsersVisible;
export const isEditTeamsVisible   = (state) => state.user.editTeamsVisible;

export const selectUsernameCheck  = (state) => state.user.usernameCheck;
export const selectPasswordCheck  = (state) => state.user.passwordCheck;
export const selectEmailCheck     = (state) => state.user.emailCheck;
export const isDirty              = (state) => state.user.dirty;
export const selectUsers          = (state) => state.user.users;
export const selectReload         = (state) => state.user.reload;

export default userSlice.reducer;



/**
 * Asynchronous thunk action login
 * @param {string} username
 */
export function validateUsername(username) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const url = state.wec.baseProtocol + '//' + state.wec.authUrl;
      const domain = state.login.user.domain;
      const options = { domain:  domain, user: username };
      dispatch(fetchPOST(url + '/api/check', options)).then(
        check => dispatch(validateUsernameSuccess({ username : username, check : check }))
      );
    } catch (error) {
      console.error(error);
      dispatch(validateUsernameFailed(error))
    }
  }
}



/**
 * Asynchronous thunk action create user
 * @param {string} props.mandant
 * @param {string} props.authUrl
 * @param {string} props.username
 * @param {string} props.email
 * @param {string} props.password
 * @param {string} props.rpassword
 * @param {string} props.type
 * @param {string} props.teams
 * @param {string} props.role
 */
export function createUser(props) {
  return async dispatch => {
    try {
      // checks
      dispatch(validateUsername(props.username));
      dispatch(validateEmail(props));
      dispatch(validatePassword(props));

      // clean props object
      delete props.rpassword;
      
      // request
      dispatch(fetchPOST(props.authUrl + "/api/create", props)).then(
        user => {
          dispatch(createUserSuccess(user))
          dispatch(addMessage({type: 'NOTICE', text: 'Benutzer wurde angelegt'}));
        }
      );
    } catch (error) {
      console.error(error);
      dispatch(createUserFailed(error))
    }
  }
}



/**
 * Asynchronous thunk action edit user
 * @param {integer} user_id
 */
export function editUser(user_id) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const url = state.wec.baseProtocol + '//' + state.wec.authUrl;
      dispatch(fetchGET(url + '/api/user/' + user_id)).then(
        user => dispatch(showEditUserModal(user))
      );

    } catch (error) {
      //dispatch(saveUserFailed(error))
    }
  }
}



/**
 * Asynchronous thunk action save user
 * @param {string} props.mandant
 * @param {string} props.authUrl
 * @param {string} props.username
 * @param {string} props.email
 * @param {string} props.password
 * @param {string} props.rpassword
 */
export function saveUser(props) {
  return async dispatch => {
    try {
      const user_id = props.id;
      
      // checks
      dispatch(validateEmail(props));
      if (props.password) {
        dispatch(validatePassword(props));
      }

      // clean props object
      delete props.rpassword;

      // update user
      dispatch(fetchPUT(props.authUrl + "/api/user/"+user_id, props)).then(
        user => {
          dispatch(saveUserSuccess(user))
          dispatch(addMessage({type: 'NOTICE', text: 'Benutzerdaten wurden gespeichert'}));
        }
      );
    } catch (error) {
      console.error(error);
      dispatch(saveUserFailed(error))
    }
  }
}



/**
 * Asynchronous thunk action save settings
 * @param {integer} user_id
 * @param {array} settings
 */
 export function saveSetting(user_id, settings) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const authUrl = state.wec.baseProtocol + '//' + state.wec.authUrl;

      dispatch(fetchPUT(authUrl + "/api/user/"+user_id+"/settings", settings)).then(
        user => {
          dispatch(setUserLoggedIn(user));
          dispatch(addMessage({type: 'NOTICE', text: 'Benutzereinstellungen wurden gespeichert'}));
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}



/**
 * Asynchronous thunk action list user
 * @param {string} props.mandant
 */
export function listUsers(props) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const authUrl = state.wec.baseProtocol + '//' + state.wec.authUrl;
      const options = { filter: { active : true, domain: props.mandant }, sorting : { username : 'ASC' }};
      const url     = authUrl + "/api/users/?options=" + btoa(JSON.stringify(options)).replace(/=/g, '_');
      dispatch(fetchGET(url)).then(
        users => {
          dispatch(setUsers(users));
          dispatch(showListUsers());
        }
      );
    } catch (error) {
      //dispatch(saveUserFailed(error))
      console.error(error);
    }
  }
}



/**
 * 
 * @param {integer} props.user
 * @param {string} props.mandant
 */
export function deleteUser(props) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const authUrl = state.wec.baseProtocol + '//' + state.wec.authUrl;
      dispatch(fetchDELETE(authUrl + '/api/user/' + props.user)).then(
        user => dispatch(listUsers(props))
      );
    } catch (error) {
      //dispatch(saveUserFailed(error))
    }
  }
}



/**
 * 
 * @param {String} domain
 * @param {Array} projectId
 * @param {Integer} userId
 * @param {Integer} roleId
 * @param {Iunction} callbackFnc
 */
 export function addUserToProject(domain, projectId, userId, roleId, callbackFnc) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      const options = { 
        id: projectId,
        user: userId,
        role: roleId
      };
      dispatch(fetchPOST(backendUrl + '/api/latest/' + domain + '/project/' + projectId + '/adduser', options)).then(
        project => callbackFnc()
      );
    } catch (error) {
      console.error(error);
      dispatch(validateUsernameFailed(error))
    }
  }
}



/**
 * 
 * @param {integer} props.user
 * @param {string} props.mandant
 */
 export function removeUserFromProjects(props) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      const options = { 
        user: props.user,
        domain: props.mandant
      };
      dispatch(fetchPOST(backendUrl + '/api/latest/' + props.mandant + '/projects/removeuser', options)).then(
        response => {

        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}