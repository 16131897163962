// react base
import React from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { differenceInDays, differenceInHours, format } from 'date-fns/esm'

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { loadCorrectionLevel, dataProject, getCorrectionLevelSource, deleteCorrectionLevel } from '../corrector.slice';
import { showDialog } from './edit.slice';
import { showDialogNew } from './new.slice';
import { showDialogCL } from '../correctionlevel/new.slice';
import { selectBaseProtocol, selectBackendUrl, selectClStageTypes, setActiveModule, selectConfig } from '../../../WECorrect.slice';
import { reactivateProject, archiveProject } from '../projects.slice';
import { showConfirm } from '../../confirm/confirm.slice';
import { selectUser } from '../../login/login.slice';

// App
import WECPermissionHandler from '../../toolbox/permission_handler';
import WECProjectTags from "./tags";

// semantic ui
import { Header, List, Item, Button, Icon, Popup, Divider, Form, Checkbox, Image, Container } from 'semantic-ui-react'

// Styles
import './project.css';



/**
 * 
 * @param {Array} pages 
 * @param {Object} page 
 */
const WECProjectCorrectionLevelCurrent = () => {
  //global state
  const dispatch = useDispatch();
  const c_selectBaseProtocol  = useSelector(selectBaseProtocol);
  const s_selectBackendUrl    = useSelector(selectBackendUrl);
  const _project              = useSelector(dataProject);
  const stage_types           = useSelector(selectClStageTypes);
  const userLoggedIn          = useSelector(selectUser);
  const config                = useSelector(selectConfig);

  // Permissions
  let permissionHandler = new WECPermissionHandler();
  const permission_PERM_PRJ_01 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_01', _project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
  const permission_PERM_PRJ_02 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_02', _project.attendees, config[config.domain].user.roles);
  const permission_PERM_PRJ_03 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_03', _project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
  const permission_PERM_PRJ_04 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_05', _project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
  const permission_PERM_PRJ_05 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_05', _project.attendees, config[config.domain].user.roles);
  const permission_PERM_PRJ_08 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_08', _project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
  const permission_PERM_PRJ_09 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_09', _project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2);

  //
  const printApproval = typeof _project.correctionLevel.printApproval !== 'undefined' && _project.correctionLevel.printApproval && typeof _project.correctionLevel.printApproval.date !== 'undefined' ? _project.correctionLevel.printApproval.date : null;  
  const counter = typeof _project.correctionLevel !== 'undefined' && _project.correctionLevel.counter !== 'undefined' ? parseInt(_project.correctionLevel.counter) + 1 : 1;

  // translation
  const { t } = useTranslation('corrector');

  // build preview image url
  let imgURL = '/media/page_thumbL.jpg';
  if (typeof _project.correctionLevel !== 'undefined' && typeof _project.correctionLevel.id !== 'undefined') {
    imgURL = c_selectBaseProtocol + '//' + s_selectBackendUrl + '/media/projects/' + _project.domain + '/' + _project.id + '/' + _project.correctionLevel.id + '/thumbsL/1.jpg';
  }

  // check if readonly
  const readOnly = _project.archived ? true : false;

  // primary actions
  const button_newclevel  = (readOnly ||  _project.correctionLevel.stage < 4) ? <></> :<Button icon size='mini' floated='right' labelPosition='left' secondary onClick={()=>dispatch(showDialogCL({domain: _project.domain, projectId: _project.id, counter: counter}))}>{t('corrector:CORRECTIONLEVEL.Neue Korrekturstufe')}<Icon name='add' /></Button>;
  const button_settings   = <Button icon size='mini' labelPosition='left' floated='left' onClick={()=>dispatch(showDialog({domain: _project.domain, id: _project.id}))}>{t('corrector:CORRECTIONLEVEL.Projekteinstellungen')}<Image className='buttonImage' src='/media/icons/WE_correct_icon_projekteinstellungen.svg' /></Button>;
  const button_opencl     = <Button icon size='mini' labelPosition='left' floated='right' primary onClick={()=>dispatch(loadCorrectionLevel(_project.domain, _project.correctionLevel.id))}>{t('corrector:CORRECTIONLEVEL:Öffnen')}<Image className='buttonImage' src='/media/icons/WE_correct_icon_white_oeffnen.svg' /></Button>
  const image_opencl      = permission_PERM_PRJ_02 ? <Item.Image className='preview' src={imgURL} onClick={()=>dispatch(loadCorrectionLevel(_project.domain, _project.correctionLevel.id))} style={{cursor: 'pointer'}} /> : <Item.Image className='preview' src={imgURL} />
  const button_dlpdf      = <Button icon size='mini' labelPosition='left' floated='right' onClick={()=>dispatch(getCorrectionLevelSource(_project.domain, _project.correctionLevel.id, _project.correctionLevel.source))}>{t('corrector:CORRECTIONLEVEL.PDF herunterladen')}<Image className='buttonImage' src='/media/icons/WE_correct_icon_download.svg' /></Button>
  
  // secondary actions
  const archiveOnClick = _project.archived ? () => { dispatch(reactivateProject(_project.domain, _project.id)); dispatch(setActiveModule('archive')); } : () => {
    dispatch(showConfirm({
      cancelButton: t('corrector:CORRECTIONLEVEL.Abbrechen'),
      confirmButton: t('corrector:CORRECTIONLEVEL.Archivieren'),
      message: <>{t('corrector:CORRECTIONLEVEL.Projekt archivieren', _project.title)}</>,
      addContent: <>
        <Form>
          <Form.Field>
            <Checkbox id='archive_private' toggle value={1} label={t('corrector:CORRECTIONLEVEL.Projekt privat archivieren')} />
          </Form.Field>
          <Form.Field>
            {t('corrector:CORRECTIONLEVEL.Projekt privat archivieren info')}
          </Form.Field>
        </Form>
      </>,
      onConfirm: ()=>{
        dispatch(archiveProject(_project.domain, _project.id, document.getElementById("archive_private").checked));
        dispatch(setActiveModule('dashboard'));
      }
    }))
  };
  const deleteOnCLick   = () => {
    dispatch(showConfirm({
      cancelButton: t('corrector:CORRECTIONLEVEL.Abbrechen'),
      confirmButton: t('corrector:CORRECTIONLEVEL.Löschen'),
      message: <>{t('corrector:CORRECTIONLEVEL.Korrekturstufe löschen', _project.correctionLevel.title)}</>,
      onConfirm: ()=>{
        dispatch(deleteCorrectionLevel(_project.domain, _project.correctionLevel.id));
      }
    }))
  }
  const archiveText     = _project.archived ? t('corrector:CORRECTIONLEVEL.Reaktivieren') : t('corrector:CORRECTIONLEVEL.Archivieren');
  const button_copy     = <Button basic id='btn_action_copy' color='black' floated='right' onClick={()=>dispatch(showDialogNew({domain: _project.domain, project: _project}))}>{t('corrector:CORRECTIONLEVEL:Neues Projekt')}</Button>
  const button_archive  = <Button basic id='btn_action_arch' color='black' floated='right' onClick={()=>{archiveOnClick()}}>{archiveText}</Button>
  const button_delete   = (readOnly || _project.correctionLevel.stage > 1 || _project.archived) ? <></> : <Button basic id='btn_action_del' color='black' floated='right' onClick={()=>deleteOnCLick()}>{t('corrector:CORRECTIONLEVEL:Löschen')}</Button>

  // let stage type adjustment
  let projectStage = _project.correctionLevel.stage;
  if (!projectStage) {
    projectStage = 1;
  }

  // stage
  const stage_txt = _project.archived ? t('corrector:CORRECTIONLEVEL.archiviert') : _project.correctionLevel && _project.correctionLevel.stage ? stage_types[_project.correctionLevel.stage].title : stage_types[1].title;

  // deadline
  let diffWarnIcon = null;
  let diffWarnTxt  = '';
  let diffWarnCol  = '#000'
  let dateCLDf = null;
  if (typeof _project.correctionLevel !== 'undefined' && typeof _project.correctionLevel.deadline !== 'undefined' && _project.correctionLevel.deadline && typeof _project.correctionLevel.deadline.date !== 'undefined') {
    const dateCLD    = new Date(_project.correctionLevel.deadline.date);
    const dateNow    = new Date();
    const diffDays   = differenceInDays(new Date(dateCLD), new Date(dateNow))
    const diffHours  = differenceInHours(new Date(dateCLD), new Date(dateNow))
    const diffTxt2   = t('corrector:CORRECTIONLEVEL.Alle Korrekturen');
    const diffTxt1   = t('corrector:CORRECTIONLEVEL.Bitte schließen');
    
    dateCLDf   = format(dateCLD, "dd.MM.yyyy HH:mm");

    if (diffDays <= 7) {
      diffWarnTxt = diffTxt1.replace('[X]', diffDays);
      diffWarnIcon = '/media/icons/WE_correct_icon_achtung.svg';
      diffWarnCol  = '#FFCC0F';
    }
    if (diffDays <= 3) {
      diffWarnTxt = diffHours <= 36 ? diffTxt2.replace('[X]', diffHours) : diffWarnTxt = diffTxt1.replace('[X]', diffDays);
      diffWarnIcon = '/media/icons/WE_correct_icon_achtung-orange.svg';
      diffWarnCol  = '#FF7F15';
    }
    if (diffDays <= 1) {
      diffWarnTxt = diffTxt2.replace('[X]', diffHours);
      diffWarnIcon = '/media/icons/WE_correct_icon_achtung-rot.svg';
      diffWarnCol  = '#FF3333';
    }
  }
  
  // format date
  const fdateCreated  = typeof _project.log !== 'undefined' && typeof _project.log.CREATE !== 'undefined' && _project.log.CREATE ? format(new Date(_project.log.CREATE), "dd.MM.yyyy") : <></>
  const fdateChanged  = typeof _project.log !== 'undefined' && typeof _project.log.UPDATE !== 'undefined' && _project.log.UPDATE ? format(new Date(_project.log.UPDATE), "dd.MM.yyyy, HH:mm") : <></>
  const fdateArchived = typeof _project.log !== 'undefined' && typeof _project.log.ARCHIVED !== 'undefined' && _project.log.ARCHIVED ? format(new Date(_project.log.ARCHIVED), "dd.MM.yyyy") : <></>
  const fprintApproval= typeof printApproval !== 'undefined' && printApproval ? format(new Date(printApproval), "dd.MM.yyyy, HH:mm") : <></>
  
  return (
    <>
    <Item.Group>
      <Item>
        {image_opencl}
        <Item.Content>
          <Item.Header className='title'>{t('corrector:CORRECTIONLEVEL.Aktuelle Korrekturstufe')}</Item.Header>
          <Item.Meta>
            <table>
              <tr>
                <td rowSpan='4' style={{width: "70px", paddingRight: "10px"}}>
                  <div className='stufe' title={_project.correctionLevel.title}>
                    <span className='stufe_head'>{t('corrector:CORRECTIONLEVEL.Stufe')}</span>
                    <span className='stufe_value'>{_project.correctionLevels.length}</span>
                  </div>
                </td>
                <td style={{width: "130px"}}>{t('corrector:CORRECTIONLEVEL.Phase')}:</td>
                <td style={{width: "260px"}}><span className='date'>{stage_txt}</span></td>
                <td rowSpan='4' style={{textAlign: "right"}}>{permission_PERM_PRJ_01 && (<Item.Extra>{button_newclevel}</Item.Extra>)}</td>
              </tr>
              <tr>
                <td>{t('corrector:CORRECTIONLEVEL.Erstellt')}:</td>
                <td><span className='date'>{fdateCreated}</span></td>
              </tr>
              {!_project.archived && (
                <tr>
                  <td>{t('corrector:CORRECTIONLEVEL.Geändert')}:</td>
                  <td><span className='date'>{fdateChanged} Uhr</span></td>
                </tr>
              )}
              {_project.archived && (
                <tr>
                  <td>{t('corrector:CORRECTIONLEVEL.Archiviert')}:</td>
                  <td><span className='date'>{fdateArchived}</span></td>
                </tr>
              )}
              {printApproval && (
                <tr>
                  <td>{t('corrector:CORRECTIONLEVEL.Druckfreigabe')}:</td>
                  <td><span className='date'>{fprintApproval} Uhr</span></td>
                </tr>
              )}
              <tr>
                {printApproval && (<td></td>)}
                <td>{t('corrector:CORRECTIONLEVEL.Korrekturen')}:</td>
                <td>
                  <span className='date' style={{float: 'left'}}>{_project.correctionLevel.corrections.all}</span>
                  <Popup trigger={<Image src='/media/icons/WE_correct_icon_suchen.svg' style={{width: '16px', marginTop: '-3px', cursor: 'pointer'}}/>}>
                    <Header>{_project.correctionLevel.corrections.all} {t('corrector:CORRECTIONLEVEL.Korrekturen insgesammt')}: </Header>
                    <List>
                      <List.Item>{_project.correctionLevel.corrections.states.none} {t('corrector:CORRECTIONLEVEL.Korrekturen warten')}</List.Item>
                      <List.Item>{_project.correctionLevel.corrections.states.accepted} {t('corrector:CORRECTIONLEVEL.Korrekturen noch offen')}</List.Item>
                      <List.Item>{_project.correctionLevel.corrections.states.rejected} {t('corrector:CORRECTIONLEVEL.Korrekturen abgewiesen')}</List.Item>
                      <List.Item>{_project.correctionLevel.corrections.states.done} {t('corrector:CORRECTIONLEVEL.Korrekturen abgeschlossen')}</List.Item>
                    </List>
                  </Popup>
                </td>
            </tr>
            {!printApproval && (
              <tr>
                <td></td>
                <td>{t('corrector:CORRECTIONLEVEL.Termin für Korrekturen')}:</td>
                <td><span className='date'>{dateCLDf} Uhr</span></td>
              </tr>
            )}
            {diffWarnIcon && (
              <tr>
                <td><Image src={diffWarnIcon} className='deadlineImage' style={{width: "32px !important", height: "32px !important", border: "none", marginLeft: "14px"}}/></td>
                <td colSpan="2"><span style={{color: diffWarnCol}}>{diffWarnTxt}</span></td>
              </tr>
            )}
            </table>
          </Item.Meta>
          <Divider />
          <Item.Extra className='tags'>
            <WECProjectTags />
          </Item.Extra>
          <Divider />
          <Item.Extra>
            {permission_PERM_PRJ_04 && (<>{button_settings}</>)}
            {permission_PERM_PRJ_02 && (<>{button_opencl}</>)}
            {permission_PERM_PRJ_05 && (<>{button_dlpdf}</>)}
          </Item.Extra>
        </Item.Content>
      </Item>
    </Item.Group>
    
    <Container>
      {permission_PERM_PRJ_09 && (<>{button_delete}</>)}
      {permission_PERM_PRJ_03 && (<>{button_archive}</>)}
      {permission_PERM_PRJ_08 && (<>{button_copy}</>)}
    </Container>
    </>
  )
}







/**
 * 
 * @param {Array} pages 
 * @param {Object} page 
 */
const WECProjectCorrectionLevelNone = () => {
  //global state
  const dispatch = useDispatch();
  const _project              = useSelector(dataProject);
  const userLoggedIn          = useSelector(selectUser);
  const config                = useSelector(selectConfig);

  // Permissions
  let permissionHandler = new WECPermissionHandler();
  const permission_PERM_PRJ_01 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_01', _project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
  const permission_PERM_PRJ_03 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_03', _project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
  const permission_PERM_PRJ_04 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_05', _project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
  const permission_PERM_PRJ_08 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_08', _project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2);

  //
  const counter = typeof _project.correctionLevel !== 'undefined' && _project.correctionLevel.counter !== 'undefined' ? parseInt(_project.correctionLevel.counter) + 1 : 1;

  // translation
  const { t } = useTranslation('corrector');

  // build preview image url
  let imgURL = '/media/icons/WE_correct_icon_schliessen.svg';
  
  // check if readonly
  const readOnly = _project.archived ? true : false;

  // primary actions
  const button_newclevel  = readOnly ? <></> :<Button icon size='mini' floated='right' labelPosition='left' secondary onClick={()=>dispatch(showDialogCL({domain: _project.domain, projectId: _project.id, counter: counter}))}>{t('corrector:CORRECTIONLEVEL.Neue Korrekturstufe')}<Icon name='add' /></Button>;
  const button_settings   = <Button icon size='mini' labelPosition='left' floated='left' onClick={()=>dispatch(showDialog({domain: _project.domain, id: _project.id}))}>{t('corrector:CORRECTIONLEVEL.Projekteinstellungen')}<Image className='buttonImage' src='/media/icons/WE_correct_icon_projekteinstellungen.svg' /></Button>;
  
  // secondary actions
  const archiveOnClick = _project.archived ? () => { dispatch(reactivateProject(_project.domain, _project.id)); dispatch(setActiveModule('archive')); } : () => {
    dispatch(showConfirm({
      cancelButton: t('corrector:CORRECTIONLEVEL.Abbrechen'),
      confirmButton: t('corrector:CORRECTIONLEVEL.Archivieren'),
      message: <>{t('corrector:CORRECTIONLEVEL.Projekt archivieren', _project.title)}</>,
      addContent: <>
        <Form>
          <Form.Field>
            <Checkbox id='archive_private' toggle value={1} label={t('corrector:CORRECTIONLEVEL.Projekt privat archivieren')} />
          </Form.Field>
          <Form.Field>
            {t('corrector:CORRECTIONLEVEL.Projekt privat archivieren info')}
          </Form.Field>
        </Form>
      </>,
      onConfirm: ()=>{
        dispatch(archiveProject(_project.domain, _project.id, document.getElementById("archive_private").checked));
        dispatch(setActiveModule('dashboard'));
      }
    }))
  };
  const archiveText     = _project.archived ? t('corrector:CORRECTIONLEVEL.Reaktivieren') : t('corrector:CORRECTIONLEVEL.Archivieren');
  const button_copy     = <Button basic id='btn_action_copy' color='black' floated='right' onClick={()=>dispatch(showDialogNew({domain: _project.domain, project: _project}))}>{t('corrector:CORRECTIONLEVEL:Neues Projekt')}</Button>
  const button_archive  = <Button basic id='btn_action_arch' color='black' floated='right' onClick={()=>{archiveOnClick()}}>{archiveText}</Button>
  
  // format date
  const fdateArchived = typeof _project.log !== 'undefined' && typeof _project.log.ARCHIVED !== 'undefined' && _project.log.ARCHIVED ? format(new Date(_project.log.ARCHIVED), "dd.MM.yyyy") : <></>

  //  
  return (
    <>
    <Item.Group>
      <Item>
        <Item.Image className='preview' src={imgURL} style={{backgroundColor: '#909090', opacity: '0.2'}} />
        <Item.Content>
          <Item.Header className='title'>{t('corrector:CORRECTIONLEVEL.Keine Korrekturstufe')}</Item.Header>
          <Item.Meta>
            <table>
              <tr>
                <td rowSpan='2' style={{width: "70px", paddingRight: "10px"}}></td>
                <td style={{width: "130px"}}></td>
                <td style={{width: "260px"}}></td>
                <td rowSpan='2' style={{textAlign: "right"}}>{permission_PERM_PRJ_01 && (<Item.Extra>{button_newclevel}</Item.Extra>)}</td>
              </tr>
              {!_project.archived && (
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              )}
              {_project.archived && (
                <tr>
                  <td>{t('corrector:CORRECTIONLEVEL.Archiviert')}:</td>
                  <td><span className='date'>{fdateArchived}</span></td>
                </tr>
              )}
            </table>
          </Item.Meta>
          <Divider />
          <Item.Extra className='tags'>
            <WECProjectTags />
          </Item.Extra>
          <Divider />
          <Item.Extra>
            {permission_PERM_PRJ_04 && (<>{button_settings}</>)}
          </Item.Extra>
        </Item.Content>
      </Item>
    </Item.Group>
    
    <Container>
      {permission_PERM_PRJ_03 && (<>{button_archive}</>)}
      {permission_PERM_PRJ_08 && (<>{button_copy}</>)}
    </Container>
    </>
  )
}



/**
 * 
 */
const WECProjectCorrectionLevel = () => {
  const _project = useSelector(dataProject);
  //const c_newCorrectionLevel = useSelector(newCorrectionLevel);

  // show current CorrectionLevel if present
  if (_project.correctionLevelsCount) {
    return (<WECProjectCorrectionLevelCurrent />);
  }

  // if no current correctionlevel is present
  return (<WECProjectCorrectionLevelNone />);
}

export default WECProjectCorrectionLevel;