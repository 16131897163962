import { createSlice } from '@reduxjs/toolkit';
import { fetchPUT } from "../toolbox/requestor.slice";
import { changeData } from './corrector.slice';
import { addMessage } from '../snackbar/snackbar.slice'


/**
 * 
 */
export const correctionStageSlice = createSlice({
  name: 'correctionStage',
  initialState: {
    stage: null,
    visible: false
  },

  reducers: {
    changeCorrectionStage: (state, action) => { state[action.payload.key] = action.payload.value; },
  },
});

export const { changeCorrectionStage } = correctionStageSlice.actions;

export const isVisible = (state) => state.correctionStage.visible;
export const stage = (state) => state.correctionStage.stage;

export default correctionStageSlice.reducer;



/**
 * 
 * @returns 
 */
export function persistCorrectionLevelStage(domain) {
  return async (dispatch, getState) => { 
    try {
      const state = getState();
      const backendUrl = state.wec.baseProtocol + '//' + state.wec.backendUrl;

      // get CorrctionLevel data and push it into store
      dispatch(fetchPUT(backendUrl + '/api/latest/' + domain + '/correctionlevel/' + state.corrector.data.correctionlevel.id + '/stage', state.correctionStage.stage)).then(
        correctionlevel => {
          dispatch(changeData({ key: 'correctionlevel', value: correctionlevel}));
          dispatch(changeCorrectionStage({ key: 'visible', value: false}));
          dispatch(addMessage({type: 'NOTICE', text: 'Korrekturphase wurde geändert'}));
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}