// React base
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { useTranslation } from 'react-i18next'
import { de_common } from './config/language/de';
import { en_common } from './config/language/en';
import { de_user } from './config/language/de';
import { en_user } from './config/language/en';
import { de_login } from './config/language/de';
import { en_login } from './config/language/en';
import { de_corrector } from './config/language/de';
import { en_corrector } from './config/language/en';
import { de_footer } from './config/language/de';
import { en_footer } from './config/language/en';
import { de_archive } from './config/language/de';
import { en_archive } from './config/language/en';
import { de_dashboard } from './config/language/de';
import { en_dashboard } from './config/language/en';


// App
import './WECorrect.css';

// App components
import WECLogin from './components/login/login';
import WECMenu from "./components/menu/menu";
import WECSnackbar from './components/snackbar/snackbar'
import WECConfirm from './components/confirm/confirm'
import WECMessageSingle from './components/message/single';
import WECMessageMultiple from './components/message/multiple';
import WECDashboard  from './components/dashboard/dashboard';
import WECArchive from "./components/archive/archive";
import WECSystem from './components/system/system';
import WECCorrector from "./components/corrector/corrector";
import WECProjectNew  from "./components/corrector/project/new";
import WECProjectEdit  from "./components/corrector/project/edit";
import WECCorrectionlevelNew from "./components/corrector/correctionlevel/new";
import WECUserEdit from "./components/user/edit";
import WECUserList from "./components/user/list";
import WECUserNew from "./components/user/new";
import WECTeams from "./components/teams/teams";
import WECTeam from "./components/teams/team";
import WECRoles from "./components/roles/roles";
import WECRole from "./components/roles/role";
import WECTags from './components/tags/tags';
import WECLostPW from './components/lostpw/lostpw';
import WECResetPW from './components/lostpw/resetpw';


// Redux
import { checkTokenUser, isLoggedIn, selectDomain, selectUser } from './components/login/login.slice';
import { selectActiveModule, selectMandant, selectBaseProtocol, selectAuthUrl, getConfig, selectConfig, setActiveModule, setRedirected, selectRedirected } from './WECorrect.slice';
import { hideContextMenu } from './components/corrector/contextMenu/contextMenu.slice';
import { loadProject } from './components/corrector/corrector.slice';
//import { fetchGETRequest } from "./components/toolbox/request_handler";
//import { fetchGET } from "./components/toolbox/requestor.slice";



/*
 * Initialize translation system
 */
i18n
  .use(initReactI18next)
  .init({
    resources: {},
    lng: 'de',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      format: function(value, format, lng) {
        if (format === 'uppercase') {
          return value.toUpperCase();
        }
        return value;
      }
    }
  }
);
i18n.addResourceBundle('de', 'common', de_common);
i18n.addResourceBundle('en', 'common', en_common);
i18n.addResourceBundle('de', 'login', de_login);
i18n.addResourceBundle('en', 'login', en_login);
i18n.addResourceBundle('de', 'footer', de_footer);
i18n.addResourceBundle('en', 'footer', en_footer);
i18n.addResourceBundle('de', 'corrector', de_corrector);
i18n.addResourceBundle('en', 'corrector', en_corrector);
i18n.addResourceBundle('de', 'archive', de_archive);
i18n.addResourceBundle('en', 'archive', en_archive);
i18n.addResourceBundle('de', 'dashboard', de_dashboard);
i18n.addResourceBundle('en', 'dashboard', en_dashboard);
i18n.addResourceBundle('de', 'user', de_user);
i18n.addResourceBundle('en', 'user', en_user);



/**
 * 
 * @param {Object} config 
 */
const MainContent = ({config}) => {
  const dispatch     = useDispatch();
  const activeModule = useSelector(selectActiveModule);
  const redirected   = useSelector(selectRedirected);

  // router
  useEffect(() => {
    // deeplink project
    if (!redirected && window.location.pathname) {
      const regex1 = /\/(\S+)\/projects\/(\d+)/gm;
      let match = regex1.exec(window.location.pathname);
      if (match !== null && match.length) {
        const domain = match[1];
        const pid    = match[2];
        dispatch(loadProject(domain, pid, false));
        dispatch(setActiveModule('corrector'));
        dispatch(setRedirected(true));
      }
    }

    // deeplink resdetpw
    if (!redirected && window.location.pathname) {
      const regex1 = /\/resetpw/gm;
      let match = regex1.exec(window.location.pathname);
      if (match !== null && match.length) {
        const token = match[1];
        dispatch(setActiveModule('resetpw'));
        dispatch(setRedirected(true));
      }
    }
  });
    

  switch (activeModule) {
    case 'dashboard': return <WECDashboard config={config} />;
    case 'corrector': return <WECCorrector config={config} />;
    case 'archive': return <WECArchive config={config} />;
    case 'resetpw': return <WECResetPW config={config} />;
    default: return <>{activeModule}</>;
  }
}





/**
 * 
 * @returns 
 */
const WECBetaBatch = () => {
  return (
      <div className='wec-beta'>BETA</div>
  );
}




/**
 * 
 * @param {String} text 
 * @returns 
 */
 const WECTestBatch = ({text}) => {
  return (
      <div className='wec-test'>{text}</div>
  );
}





/**
 * @description JWT (JSON Web Token) https://jwt.io/introduction/
 */
const WECorrect = () => {
  // local state
  const [visibleLostPW, setVisibleLostPW] = useState(false);

  // global state
  const dispatch      = useDispatch();
  const mandant       = useSelector(selectMandant);
  const baseProtocol  = useSelector(selectBaseProtocol);
  const authUrl       = baseProtocol + '//' + useSelector(selectAuthUrl);
  const loggedIn      = useSelector(isLoggedIn);
  const config        = useSelector(selectConfig);
  const loginDomain   = useSelector(selectDomain);
  const loggedInUser  = useSelector(selectUser); 

  // initially check if token in URL param and load config if logged in
  useEffect(() => {
    if (!loggedIn) {
      // check url param for given token
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('token')

      // check given token with server (server returns user data just like the login does)
      if (typeof token !== 'undefined' && token) {
        dispatch(checkTokenUser({ mandant : mandant, authUrl : authUrl, token : token }))
      }

      // check if token in localStorage
      const localStorage_loginToken = localStorage.getItem('login.token');
      const localStorage_loginUser = localStorage.getItem('login.user');
      const localStorage_loginDomain = localStorage.getItem('login.domain');
      let localStorage_loginUser_parsed = null;
      try {
        localStorage_loginUser_parsed = JSON.parse(localStorage_loginUser)
      } catch (error) {
        localStorage.removeItem('login.user');
        localStorage.removeItem('login.token');
        localStorage.removeItem('login.domain');
      }
      
      // check localStorage token with server (server returns user data just like the login does)
      if (localStorage_loginToken) {
        console.log('check localStorage token')
        dispatch(checkTokenUser({ mandant : localStorage_loginDomain, authUrl : authUrl, token : localStorage_loginToken }))
        return;
      }

    }

    
    // load config
    if (loggedIn && !config) {
      dispatch(getConfig(loginDomain));
    }
  });

  // change language
  const { i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng
  if (loggedInUser && typeof loggedInUser.language !== 'undefined' && loggedInUser.language && getLanguage() !== loggedInUser.language) {
    i18n.changeLanguage(loggedInUser.language);
  }

  // Show only login screen if not logged oin
  if (!loggedIn) {
    return (
      <React.Fragment> 
        <WECLogin onLostPW={()=>setVisibleLostPW(true)} />
        {visibleLostPW && (<WECLostPW onClose={()=>setVisibleLostPW(false)} />)}
        <WECSnackbar />
        <WECBetaBatch />
      </React.Fragment>
    );
  }


  /*
  const checkToken = async () => {
    let promise = new $.Deferred();
    try {
      let result = fetchGETRequest("GET_TOKEN");
      let token  = await result;
      if (typeof token.token !== 'undefined'){
        return promise.resolve(token);
      }
      return promise.reject();
    } catch (error) {
      return promise.reject();
    }
  };


  navigator.serviceWorker.ready.then( (registration) => {
    console.log('[SW] ready');
    if (registration.active) {
      console.log('[SW] active');
      checkToken().then(
        (token) => {
          if (typeof token !== 'undefined' && typeof token.token !== 'undefined' && token.token !== '') {
            console.log('[DKS] Token ', token);
            dispatch(loginSuccess(token));
            dispatch(fetchGET(authUrl + '/api/config/' + mandant + '/all')).then(
              config => {
                dispatch(getConfigSuccess({ config : config }));
              }
            );
          }
        }
      );
    }
  });
  */

  if (!config) {
    return (
      <React.Fragment> 
        <header className="wec-header"><WECMenu /></header>
        <main className="wec-main"></main>
      </React.Fragment>
    );
  }
  
  return (
    <div className="wec"  onClick={()=>dispatch(hideContextMenu())}>
      <div className="wec-header">
        <WECMenu />
      </div>
      <div className="wec-main">
        <MainContent config={config} />

        <WECUserEdit />
        <WECUserList />
        <WECUserNew />

        <WECTeams />
        <WECTeam />

        <WECRoles />
        <WECRole />

        <WECTags />

        <WECProjectNew config={config} />
        <WECProjectEdit config={config} />
        <WECCorrectionlevelNew />

        <WECSnackbar />
        <WECConfirm />
        <WECSystem />
        <WECMessageSingle />
        <WECMessageMultiple />
        
        {typeof window.wecorrect !== 'undefined' && typeof window.wecorrect.environment !== 'undefined' && (<WECTestBatch text={window.wecorrect.environment} />)}
        <WECBetaBatch />
      </div>
    </div>    
  );
}

export default WECorrect;