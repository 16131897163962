// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Container, Form, Header, Button, Input, Icon, Image } from 'semantic-ui-react';

// Redux
import { changeUsername, changePassword, loginUser } from './login.slice';
import { selectUsername, selectPassword } from './login.slice';
import { selectMandant, selectBaseProtocol, selectAuthUrl } from '../../WECorrect.slice';

// Styles
import './login.css';



/**
 * 
 */
const WECLogin = ({onLostPW}) =>
{
    const { t } = useTranslation('login');

    const dispatch  = useDispatch();

    const username      = useSelector(selectUsername);
    const password      = useSelector(selectPassword);
    const mandant       = useSelector(selectMandant);
    const baseProtocol  = useSelector(selectBaseProtocol);
    const authUrl       = baseProtocol + '//' + useSelector(selectAuthUrl);

    return (
        <Container className='wec_login'>
            <Image className='logo' src='/media/logos/wsepic_logo_rund_inverse.png' />
            <Container className='login'>
                <Header>
                    {t('login:Einloggen')}
                </Header>
                <Form>
                    <Form.Field>
                        <Input placeholder={t('login:User')} value={username} onChange={(event)=>dispatch(changeUsername({ username : event.target.value }))} />
                    </Form.Field>
                    <Form.Field>
                        <Input type='password' placeholder={t('login:Password')} value={password} onChange={(event)=>dispatch(changePassword({ password : event.target.value }))} />
                    </Form.Field>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Button 
                                icon
                                color = 'white' 
                                labelPosition='left'
                                onClick = {()=>dispatch(loginUser({ mandant : mandant, authUrl : authUrl, username : username, password : password }))}
                            >
                                <Icon name='sign-in' />
                                {t('Login: Anmelden')}
                            </Button>
                        </Form.Field>
                        <Form.Field>
                            <a href='#' onClick={()=>onLostPW()}>{t('Login: Zugangsdaten vergessen?')}</a>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Container>
        </Container>
    );
}

export default WECLogin;