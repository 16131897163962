// react base
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { isDialogVisible, hideDialog, loadComparedProjects, loadComparedCLs, loadComparedCL } from './dialogCompare.slice';
import { compare, setCompareMode, setComparedPage, setScale } from './editor.slice';
import { dataProject } from '../corrector.slice';
import { selectBaseProtocol, selectBackendUrl } from '../../../WECorrect.slice';

// semantic ui
import { Modal, Form, Header, Dropdown, Button, Icon, Table, Image, Input } from 'semantic-ui-react';

// Styles
import './editor.css';



/**
 * 
 * @returns 
 */
const WECCorrectorEditorCompareDialog = () => {
  // local state
  const [pnvalue, setPnvalue] = useState(null);
  const [lCompare, setLCompare] = useState(null);

  // global state
  const dispatch = useDispatch();
  const c_selectBaseProtocol = useSelector(selectBaseProtocol);
  const s_selectBackendUrl = useSelector(selectBackendUrl);
  const _isDialogVisible = useSelector(isDialogVisible);
  const e_compare = useSelector(compare);
  const c_dataProject = useSelector(dataProject);

  // translation
  const { t } = useTranslation('corrector');

  //
  useEffect(() => {
    if (_isDialogVisible) {
      if (!e_compare || typeof e_compare.projects === 'undefined' || !e_compare.projects) {
        dispatch(loadComparedProjects(c_dataProject.domain));
      } else {
        if (!typeof e_compare.projectId === 'undefined' || !e_compare.projectId) {
          dispatch(loadComparedCLs(c_dataProject.domain, c_dataProject.id));
        }
      }
    }
  });

  
  // build project optionslist
  let pOptions = [];
  if (e_compare && typeof e_compare.projects !== 'undefined' && e_compare.projects) {
    e_compare.projects.forEach(p => {
      pOptions.push({key: p.id, text: p.title, value: p.id});
    });
  }
  
  // build correctionlevels optionslist
  let clOptions = [];
  if (e_compare && typeof e_compare.correctionLevels !== 'undefined' && e_compare.correctionLevels) {
    e_compare.correctionLevels.forEach(cl => {
      clOptions.push({key: cl.id, text: cl.title, value: cl.id});
    });
  }
  
  // build pages optionslist
  let pgOptions = [];
  if (e_compare && e_compare.correctionLevel) {
    e_compare.correctionLevel.pages.forEach(page => {
      pgOptions.push({key: page.number-1, text: 'Seite ' + page.number, value: page.id});
    });
  }

  // nav
  let pageNavInputVal = ''; 
  let pageNavInputValFirst = 0;
  let pageNavInputValPrev = 0;
  let pageNavInputValNext = 0;
  let pageNavInputValLast = 0;
  if (e_compare && e_compare.pageKey !== null) {
    pageNavInputVal = (e_compare.pageKey + 1) + ' von ' + e_compare.correctionLevel.pages.length;
    pageNavInputValFirst = e_compare.correctionLevel.pages[0].id;
    pageNavInputValPrev = e_compare.pageKey > 0 ? e_compare.correctionLevel.pages[e_compare.pageKey - 1].id : e_compare.correctionLevel.pages[0].id;
    pageNavInputValNext = e_compare.pageKey < (e_compare.correctionLevel.pages.length-1) ? e_compare.correctionLevel.pages[e_compare.pageKey + 1].id : e_compare.correctionLevel.pages[e_compare.correctionLevel.pages.length-1].id;
    pageNavInputValLast = e_compare.correctionLevel.pages[e_compare.correctionLevel.pages.length-1].id;

    if (!pnvalue) {
      setPnvalue(pageNavInputVal);
      setLCompare(e_compare);
    }
  }

  //
  const handleFocus = (event) => {
    event.target.select();
  }
  const handleChance = (event, {value}, ) => {
    if (!isNaN(value)) {
      if (value >= 0 && value <= (lCompare.correctionLevel.pages.length - 1)) {
        setPnvalue(value);
      }
    }
  }
  const handleBlur = (event) => {
    dispatch(setComparedPage(lCompare.correctionLevel.pages[pnvalue-1].id))
    setPnvalue(null);
  }

  //
  let page_src = '';
  if (e_compare.project && e_compare.correctionLevel && e_compare.pageKey !== null) {
    page_src = c_selectBaseProtocol + '//' + s_selectBackendUrl + '/media/projects/' + e_compare.project.domain + '/' + e_compare.project.id + '/' + e_compare.correctionLevel.id + '/thumbsL/' + (e_compare.pageKey + 1) + '.jpg';
  }
  
  return (
    <Modal open={_isDialogVisible} className='wec_editor_compare' size='small' closeOnEscape={false} closeOnDimmerClick={false}>
      <Modal.Content>
        <Header as='h1'>{t('corrector:EDITOR_COMPARE.Vergleichsansicht')}</Header>
        <Form>
          <Form.Field>
            <label>{t('corrector:EDITOR_COMPARE.Projekt zum Vergleichen auswählen')}</label>
            <Dropdown
              placeholder={t('corrector:EDITOR_COMPARE.Projekt')}
              fluid
              selection
              options={pOptions}
              value = {e_compare.projectId}
              onChange={(e, {value})=>{ dispatch(loadComparedCLs(c_dataProject.domain, value))}}
            />
          </Form.Field>
          {e_compare.projectId !== null && (
            <Form.Field>
              <Dropdown
                placeholder={t('corrector:EDITOR_COMPARE.Korrekturstufe')}
                fluid
                selection
                options={clOptions}
                value = {e_compare.correctionLevelId}
                onChange={(e, {value})=>{ dispatch(loadComparedCL(c_dataProject.domain, value))}}
              />
            </Form.Field>
          )}
          {e_compare.projectId !== null && e_compare.correctionLevelId !== null && (
            <Form.Field  className='pageprev'>
              <Image src={page_src} />
            </Form.Field>
          )}

          {e_compare.projectId !== null && e_compare.correctionLevelId !== null && e_compare.pageKey !== null && (
            <Form.Field className='pagenav'>
              <Table basic='very' collapsing className='pagenav'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell textAlign='center'>
                      <Image src='/media/icons/WE_correct_icon_last_page.svg' className='icon_mini' title={t('corrector:EDITOR_COMPARE.erste Seite')} style={{transform: 'rotate(180deg)'}} onClick={()=>{setPnvalue(null); dispatch(setComparedPage(pageNavInputValFirst))}}/>
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      <Image src='/media/icons/WE_correct_icon_next_page.svg' className='icon_mini' title={t('corrector:EDITOR_COMPARE.vorherige Seite')} style={{transform: 'rotate(180deg)'}} onClick={()=>{setPnvalue(null); dispatch(setComparedPage(pageNavInputValPrev))}}/>
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      <Input size='mini' value={pnvalue} style={{width:'75px'}} onFocus={handleFocus} onBlur={handleBlur} onChange={handleChance}/>
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      <Image src='/media/icons/WE_correct_icon_next_page.svg' className='icon_mini' title={t('corrector:EDITOR_COMPARE.nächste Seite')} onClick={()=>{setPnvalue(null); dispatch(setComparedPage(pageNavInputValNext))}}/>
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      <Image src='/media/icons/WE_correct_icon_last_page.svg' className='icon_mini' title={t('corrector:EDITOR_COMPARE.letzte Seite')} onClick={()=>{setPnvalue(null); dispatch(setComparedPage(pageNavInputValLast))}}/>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button size='mini' icon labelPosition='left' onClick={() => {
          dispatch(hideDialog());
          dispatch(setScale(100))
        }}>{t('corrector:EDITOR_COMPARE.Schließen')}<Icon name='close' /></Button>
        <Button size='mini' icon labelPosition='left' primary onClick={() => {
          dispatch(setScale(50));
          dispatch(setCompareMode('compare')); 
          dispatch(hideDialog());
        }}>{t('corrector:EDITOR_COMPARE.Vergleichen')}<Icon name='exchange' /></Button>
      </Modal.Actions>
    </Modal>
  );
}

export default WECCorrectorEditorCompareDialog;