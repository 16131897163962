// react base
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { isDialogVisible, hideDialog, loadProject, getProject, saveProject } from './edit.slice';
import { selectUser } from '../../login/login.slice';
import { dataProject, loadProjectMirror } from '../corrector.slice';

// Permissions
import WECPermissionHandler from '../../toolbox/permission_handler';

// semantic ui
import { Modal, Form, Input, Header, Dropdown, Checkbox, Button, Grid, Icon, Image } from 'semantic-ui-react';

// App
import WECProjectAttendees from "./attendees";

// Styles
import './project.css';



/**
 * 
 * @param {Object} config 
 * @returns 
 */
const WECProjectEdit = ({config}) => {
  // local state
  const [dirty, setDirty] = useState(false);
  const [name, setName] = useState('');
  const [tags, setTags] = useState([]);
  const [split, setSplit] = useState(null);

  // global state
  const dispatch = useDispatch();
  const _isDialogVisible  = useSelector(isDialogVisible);
  const _project          = useSelector(getProject);
  const userLoggedIn      = useSelector(selectUser);
  const c_project         = useSelector(dataProject);

  // Permissions
  let permissionHandler = new WECPermissionHandler();
  const permission_PERM_PRJ_10 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_10', c_project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2 || userLoggedIn.type === 3);
  const permission_PERM_PRJ_11 = permissionHandler.hasPermission(userLoggedIn, 'PERM_PRJ_11', c_project.attendees, config[config.domain].user.roles) && (userLoggedIn.type === 1 || userLoggedIn.type === 2);

  // Syste tags
  const system_tags = config && typeof config[config.domain].user.tags !== 'undefined' ? config[config.domain].user.tags : [];

  // translation
  const { t } = useTranslation('corrector');

  // autostart load projects
  useEffect(() => {
    if (!_project && _isDialogVisible) {
      dispatch(loadProject())
    }
  });

  // no dialog if invisible
  if (!_isDialogVisible) {
    return <></>
  }
  
  // Tag-IDs
  let c_tag_ids = [];
  if (typeof tags === 'undefined' || !tags.length) {
    if (_project && typeof _project.tags !== 'undefined' && _project.tags.length) {
      _project.tags.forEach(tag => {
        if (typeof tag.id !== 'undefined') {
          c_tag_ids.push(tag.id);
        } else {
          c_tag_ids.push(tag);
        }
      });
    }
  }
  if (typeof tags !== 'undefined' && tags.length) {
    tags.forEach(tag => {
      if (typeof tag.id !== 'undefined') {
        c_tag_ids.push(tag.id);
      } else {
        c_tag_ids.push(tag);
      }
    });
  }

  //
  const projectData = {
    domain: _project !== null ? _project.domain : null,
    id: _project !== null ? _project.id : null,
    title: name !== '' ? name : (_project !== null ? _project.title : ''),
    tags: c_tag_ids,
    pagesplit: split !== null ? split : (_project !== null ? _project.pagesplit : false),
    active: _project !== null ? _project.active : null,
  };

  // check if readonly
  const readOnly = _project === null || _project.archived ? true : false;
  
  return (
    <Modal 
      open = {_isDialogVisible} 
      className = 'wec_project_edit' 
      size = 'large'
      closeOnEscape = {false}
      closeOnDimmerClick = {false}
      closeIcon 
      onClose = {() => dispatch(hideDialog())}
    >
      <Modal.Content>
        <Header as='h1'>{t('corrector.EDIT:Projekt bearbeiten')}</Header>
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column width={8}>
              <Form>
                <Form.Field>
                  <label>{t('corrector.EDIT:Projektname')}</label>
                  {permission_PERM_PRJ_11 && (<Input size="small" value={projectData.title} placeholder={t('corrector.EDIT:Projektname')} onChange={(e, {value})=>{setName(value); setDirty(true); }} />)}
                  {!permission_PERM_PRJ_11 && (<Input size="small" value={projectData.title} placeholder={t('corrector.EDIT:Projektname')} readOnly />)}
                </Form.Field>
                <Form.Field>
                  <label>{t('corrector.EDIT:Schlagworte')}</label>
                  {permission_PERM_PRJ_11 && (
                    <Dropdown 
                      placeholder = {t('corrector.EDIT:Schlagworte')}
                      fluid multiple selection search allowAdditions
                      additionLabel = {t('corrector.EDIT:Schlagwort hinzufügen')} 
                      options = {system_tags}
                      value = {projectData.tags}
                      onChange = {(e, {value})=>{setTags(value); setDirty(true); }}
                    />
                  )}
                  {!permission_PERM_PRJ_11 && (
                    <Dropdown 
                      placeholder = {t('corrector.EDIT:Schlagworte')}
                      fluid multiple selection search allowAdditions
                      additionLabel = {t('corrector.EDIT:Schlagwort hinzufügen')} 
                      options = {system_tags}
                      value = {projectData.tags}
                      readonly
                    />
                  )}
                </Form.Field>
                <Form.Group>
                  <Form.Field width={5}><label>{t('corrector.EDIT:Doppelseitensplit')}</label></Form.Field>
                  <Form.Field width={11}>
                    {_project !== null && permission_PERM_PRJ_11 && !_project.correctionLevelsCount && (
                      <>
                        <Checkbox toggle checked={projectData.pagesplit} onChange={()=>{setSplit(!projectData.pagesplit); setDirty(true); }} />
                      </>
                    )}
                    {((_project !== null && _project.correctionLevelsCount > 0) || permission_PERM_PRJ_11) && (
                      <>
                        {projectData.pagesplit && (<Image src='media/icons/WE_correct_icon_bestaetigen.svg' size='mini' />)}
                        {!projectData.pagesplit && (<Image src='media/icons/WE_correct_icon_schliessen.svg'  size='mini' />)}
                      </>
                    )}
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={8} className='WECProjectAttendees'>
              {permission_PERM_PRJ_10 && (<WECProjectAttendees readOnly={readOnly} setDirty={()=>setDirty(true)} />)}
              {!permission_PERM_PRJ_10 && (<WECProjectAttendees readOnly={true} setDirty={()=>setDirty(true)} />)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button size='mini' icon labelPosition='left' onClick={() => dispatch(hideDialog())}>{t('corrector.EDIT:Schließen')}<Icon name='close' /></Button>
        {!readOnly && permission_PERM_PRJ_11 && dirty && (
          <Button 
            icon
            primary 
            size = 'mini' 
            labelPosition = 'right' 
            onClick = {() => { 
              dispatch(saveProject(projectData)); 
              setDirty(false);
              window.setTimeout(()=>{
                dispatch(loadProjectMirror(projectData.domain, projectData.id, _project.archived))
              }, 3000);
            }}>{t('corrector.EDIT:Speichern')}<Icon name='save' /></Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}

export default WECProjectEdit;